import React from 'react'
import { useTranslation } from 'react-i18next'
import { TimeFormatEnum } from 'common/components/entities/Calendar/constants'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { FontProps } from 'common/utils/styleUtilsNew'
import { EventInterface, EventLocationInterface } from '../calendar-interface'
import {
  CommonCalendarButtonUi,
  CommonCalendarButtonWrapper,
} from '../calendar-step/ui/common-calendar-button-ui'
import { BookingSummary } from './booking-summary'
import { ThankYouTitle } from './thank-you-title'
import { CommonThankYouStepUi } from './ui/common-thank-you-step-ui'

export interface ThankYouProps {
  entity: BookingCalendarInterface
  selectedDate?: Date
  selectedTimeSlot?: number
  selectedTimeZone: string
  selectedLocationId?: EventLocationInterface['id']
  hasThankYouStepButton: boolean
  thankYouStepRedirectToUrl?: (e: React.SyntheticEvent) => void
  event?: EventInterface
  timeFormat?: TimeFormatEnum
  isMobile?: boolean
}

export const ThankYouStep = ({
  entity,
  selectedDate,
  selectedTimeSlot,
  selectedTimeZone,
  selectedLocationId,
  event,
  hasThankYouStepButton,
  thankYouStepRedirectToUrl,
  timeFormat,
  isMobile,
}: ThankYouProps & FontProps) => {
  const { t } = useTranslation()

  return (
    <CommonThankYouStepUi
      titlesColor={isMobile ? entity.mobileTitlesColor : entity.titlesColor}
      color={isMobile ? entity.mobileColor : entity.color}
    >
      <ThankYouTitle entity={entity} />
      <BookingSummary
        selectedTimeZone={selectedTimeZone}
        event={event}
        entity={entity}
        selectedDate={selectedDate}
        selectedLocationId={selectedLocationId}
        selectedTimeSlot={selectedTimeSlot}
        timeFormat={timeFormat}
      />
      {hasThankYouStepButton && (
        <CommonCalendarButtonWrapper>
          <CommonCalendarButtonUi
            backgroundColor={entity.selectedSlotBackgroundColor}
            onClick={thankYouStepRedirectToUrl}
          >
            {t('entity_settings.booking_calendar.thank_you_step_button_text')}
          </CommonCalendarButtonUi>
        </CommonCalendarButtonWrapper>
      )}
    </CommonThankYouStepUi>
  )
}
