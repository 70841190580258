import styled, { css } from 'styled-components'
import {
  backgroundColorCss,
  mobileBackgroundColorCss,
} from 'common/utils/styleUtilsNew'

interface SectionContainerProps {
  width: string
  backgroundColor?: string
  mobileBackgroundColor?: string
}

const SectionContainerUi = styled.div<SectionContainerProps>`
  max-width: ${p => p.width};
  width: 100%;
  margin: auto;
  ${backgroundColorCss}
  ${p => p.theme.phone} {
    max-width: initial;
    ${mobileBackgroundColorCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      max-width: initial;
      ${mobileBackgroundColorCss}
    `}
`

export default SectionContainerUi
