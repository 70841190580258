import { css } from 'styled-components'
import {
  CommonBodyBackgroundMobileStyles,
  CommonBodyBackgroundUiStylesInterface,
} from 'common/components/entities/body/ui/body-background-ui'
import { backgroundImageFixForIOS } from 'common/utils/styleUtilsNew'

export const BackgroundUi = () => css<CommonBodyBackgroundUiStylesInterface>`
  ${p => p.theme.phone} {
    ${CommonBodyBackgroundMobileStyles}
  }
  ${p =>
    backgroundImageFixForIOS(
      !!(p.mobileBackgroundImage || p.backgroundImage),
      (p.mobileBackground?.backgroundAttachment ||
        p.background?.backgroundAttachment) ??
        '',
    )}
`
