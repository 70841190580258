import React from 'react'

export const IconBlogs = () => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.5312 6.5625H7.65625C7.07609 6.5625 6.51969 6.79297 6.10945 7.2032C5.69922 7.61344 5.46875 8.16984 5.46875 8.75V25.1562C5.46875 25.4463 5.35352 25.7245 5.1484 25.9296C4.94328 26.1348 4.66508 26.25 4.375 26.25C4.08492 26.25 3.80672 26.1348 3.6016 25.9296C3.39648 25.7245 3.28125 25.4463 3.28125 25.1562V12.0312C3.28125 11.7412 3.16602 11.463 2.9609 11.2579C2.75578 11.0527 2.47758 10.9375 2.1875 10.9375C1.89742 10.9375 1.61922 11.0527 1.4141 11.2579C1.20898 11.463 1.09375 11.7412 1.09375 12.0312V25.1713C1.09773 26.0389 1.44518 26.8697 2.06011 27.4818C2.67503 28.0939 3.50736 28.4375 4.375 28.4375H28.4375C29.3077 28.4375 30.1423 28.0918 30.7577 27.4764C31.373 26.8611 31.7188 26.0265 31.7188 25.1562V8.75C31.7188 8.16984 31.4883 7.61344 31.078 7.2032C30.6678 6.79297 30.1114 6.5625 29.5312 6.5625ZM24.0625 20.7812H13.125C12.8349 20.7812 12.5567 20.666 12.3516 20.4609C12.1465 20.2558 12.0312 19.9776 12.0312 19.6875C12.0312 19.3974 12.1465 19.1192 12.3516 18.9141C12.5567 18.709 12.8349 18.5938 13.125 18.5938H24.0625C24.3526 18.5938 24.6308 18.709 24.8359 18.9141C25.041 19.1192 25.1562 19.3974 25.1562 19.6875C25.1562 19.9776 25.041 20.2558 24.8359 20.4609C24.6308 20.666 24.3526 20.7812 24.0625 20.7812ZM24.0625 16.4062H13.125C12.8349 16.4062 12.5567 16.291 12.3516 16.0859C12.1465 15.8808 12.0312 15.6026 12.0312 15.3125C12.0312 15.0224 12.1465 14.7442 12.3516 14.5391C12.5567 14.334 12.8349 14.2188 13.125 14.2188H24.0625C24.3526 14.2188 24.6308 14.334 24.8359 14.5391C25.041 14.7442 25.1562 15.0224 25.1562 15.3125C25.1562 15.6026 25.041 15.8808 24.8359 16.0859C24.6308 16.291 24.3526 16.4062 24.0625 16.4062Z"
      fill="#142D63"
    />
  </svg>
)
