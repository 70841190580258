import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { getMediaQueryByPageType } from 'common/constants/mediaQueries'
import GlobalStyles from 'common/utils/GlobalStyles'
import AffiliateBadge from 'publisher/components/AffiliateBadge'
import { useCreateEntityElement } from 'publisher/context/create-entity-element-context'
import pageSelectors from 'publisher/store/page/pageSelectors'
import useManagement, {
  selectors as managementSelectors,
} from '../hooks/useManagement'
import usePage from '../hooks/usePage'
import Notification from './core/Notification'
import PreviewBanner from './core/PreviewBanner'

function Page({ createPage }) {
  const rootEntity = usePage(pageSelectors.getRootEntity)
  const pageType = usePage(pageSelectors.getPageType)
  const seo = usePage(pageSelectors.getSeo)
  const isFreemiumUser = useManagement(managementSelectors.isFreemiumUser)
  const isMobile = useManagement(managementSelectors.isMobile)
  const currentUrl = useManagement(managementSelectors.getUrl)
  const createEntityElement = useCreateEntityElement()
  const PageType = createPage(pageType)
  const showAffiliateBadge = !!(
    rootEntity?.options?.affiliateBadge ||
    rootEntity?.affiliateBadge ||
    rootEntity?.isAffiliateBadgeVisible ||
    isFreemiumUser
  )
  const globalScrollPaddingTop = usePage(
    pageSelectors.getGlobalScrollPaddingTop,
  )

  return (
    <React.Fragment>
      <GlobalStyles scrollPaddingTop={globalScrollPaddingTop} />
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {!seo.index && <meta name="robots" content="noindex" />}
      </Helmet>
      <PreviewBanner />
      <Notification />
      <ThemeProvider
        theme={{
          phone: getMediaQueryByPageType(pageType).phone,
          desktop: getMediaQueryByPageType(pageType).desktop,
          isMobile,
        }}
      >
        <PageType>{createEntityElement(rootEntity, {}, pageType)}</PageType>
      </ThemeProvider>
      {showAffiliateBadge && <AffiliateBadge />}
    </React.Fragment>
  )
}

Page.propTypes = {
  createPage: PropTypes.func.isRequired,
}

export default Page
