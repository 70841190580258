import React from 'react'

export const PhoneIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.39 7.55988C12.0319 3.4405 7.73439 1.41925 7.55252 1.33675C7.38249 1.25683 7.19196 1.23129 7.00689 1.26363C2.04564 2.08675 1.29939 4.97425 1.26939 5.09425C1.22857 5.26148 1.23507 5.43676 1.28814 5.6005C7.20564 23.9605 19.5038 27.3636 23.5463 28.483C23.8575 28.5693 24.1144 28.6386 24.3094 28.7024C24.5319 28.7751 24.7737 28.761 24.9863 28.663C25.11 28.6068 28.0313 27.2305 28.7456 22.7418C28.7773 22.5458 28.7458 22.3448 28.6556 22.168C28.5919 22.0443 27.0638 19.1361 22.8263 18.1086C22.6825 18.0719 22.532 18.0706 22.3876 18.1049C22.2432 18.1392 22.1094 18.208 21.9975 18.3055C20.6606 19.4474 18.8138 20.6643 18.0169 20.7899C12.675 18.178 9.69189 13.1661 9.57939 12.2155C9.51377 11.6811 10.7381 9.80425 12.1463 8.278C12.2348 8.18195 12.3022 8.06842 12.3442 7.94474C12.3861 7.82106 12.4018 7.68996 12.39 7.55988Z"
        fill="#64748B"
      />
    </svg>
  )
}
