import { useDispatch } from 'react-redux'
import { redirectionTypes } from 'common/constants/buttonTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { optInSuccess } from 'publisher/actions/optInActions'
import useManagement, {
  selectors as managementSel,
} from 'publisher/hooks/useManagement'
import useOptIn, { selectors as optInSelectors } from 'publisher/hooks/useOptIn'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import { getBookingRedirectUrl } from 'publisher/reducers/optInReducer'
import { typedPageSelectors, usePage as useTypedPage } from 'publisher/store'
import { PageState } from 'publisher/store/page/PageStateInterface'
import { redirectTo } from 'publisher/utils/redirect'

export const useBookingCalendarConfirmed = (
  entity: BookingCalendarInterface,
) => {
  const redirectUrl = useOptIn(getBookingRedirectUrl)
  const dispatch = useDispatch()
  const hasThankYouStepButton = !!(
    entity?.thankYouStepButtonType && entity?.thankYouStepButtonType !== 'none'
  )

  const isTemplate = usePage(pageSelectors.isTemplate)
  const isPreview = useManagement(managementSel.isPreviewMode)

  const redirectToUrl = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (isTemplate || isPreview) {
      return
    }
    dispatch(optInSuccess())
    if (entity?.thankYouStepButtonType === redirectionTypes.nextStep) {
      redirectTo(redirectUrl)
    } else if (entity?.thankYouStepButtonType === redirectionTypes.customUrl) {
      redirectTo(entity.thankYouStepButtonCustomUrl)
    }
  }

  return { hasThankYouStepButton, redirectToUrl }
}
