import PropTypes from 'prop-types'
import React from 'react'
import { borderTypeValues, borderTypeKeys } from 'common/constants/settings'
import Button from './ui/Button'
import SubTextUi from './ui/SubTextUi'
import ButtonContentUi from './ui/button-content-ui'
import ButtonIconUi from './ui/button-icon-ui'
import { overwriteBorderType } from './utils'

export const wrapperStyles = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
}

const CommonButton = ({
  asLink = false,
  href,
  attrId,
  styles,
  mobileStyles,
  click = () => {},
  borderType = borderTypeKeys.fullBorder,
  mobileBorderType,
  subText,
  subTextStyles = {},
  subTextMobileStyles,
  children,
  iconClassNameBefore,
  iconClassNameAfter,
  hoverStyles,
  hoverMobileStyles,
  ...restProps
}) => {
  return (
    <Button
      id={attrId}
      as={asLink ? 'a' : 'button'}
      onClick={click}
      styles={{ ...styles, ...borderTypeValues[borderType] }}
      fontFamily={styles.fontFamily}
      mobileFontFamily={mobileStyles.fontFamily}
      mobileStyles={{
        ...mobileStyles,
        ...overwriteBorderType({
          styles: { ...styles, ...mobileStyles },
          targetBorderType: mobileBorderType,
          overwriteBorderType: borderType,
        }),
      }}
      hoverStyles={hoverStyles}
      hoverMobileStyles={hoverMobileStyles}
      subText={subText}
      href={href}
      {...restProps}
    >
      <ButtonContentUi>
        {iconClassNameBefore && (
          <ButtonIconUi className={iconClassNameBefore} placed={'left'} />
        )}
        {children}
        {iconClassNameAfter && (
          <ButtonIconUi className={iconClassNameAfter} placed={'right'} />
        )}
      </ButtonContentUi>
      {subText && (
        <SubTextUi
          styles={subTextStyles}
          fontFamily={subTextStyles.fontFamily}
          mobileFontFamily={subTextMobileStyles.fontFamily}
          mobileStyles={subTextMobileStyles}
        >
          {subText}
        </SubTextUi>
      )}
    </Button>
  )
}

CommonButton.propTypes = {
  attrId: PropTypes.string,
  subText: PropTypes.string,
  hoverStyles: PropTypes.shape({
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    subTextColor: PropTypes.string,
    transform: PropTypes.string,
    offset: PropTypes.number,
  }),
  hoverMobileStyles: PropTypes.shape({
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    subTextColor: PropTypes.string,
    transform: PropTypes.string,
    offset: PropTypes.number,
  }),
  subTextStyles: PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
  }),
  subTextMobileStyles: PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
  }),
  styles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  mobileStyles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  borderType: PropTypes.string,
  mobileBorderType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  click: PropTypes.func,
  asLink: PropTypes.bool,
  href: PropTypes.string,
  iconClassNameBefore: PropTypes.string,
  iconClassNameAfter: PropTypes.string,
}

export default CommonButton
