import React from 'react'

export const CameraIcon = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_192011_12332)">
        <path
          d="M5.20833 21.3327H13.5417C16.4135 21.3327 18.75 18.9962 18.75 16.1243V9.87435C18.75 7.00247 16.4135 4.66602 13.5417 4.66602H5.20833C2.33646 4.66602 0 7.00247 0 9.87435V16.1243C0 18.9962 2.33646 21.3327 5.20833 21.3327ZM25 9.15872V16.7827C25 17.5712 24.5625 18.2796 23.8573 18.6316C23.5625 18.7785 23.2458 18.8514 22.9312 18.8514C22.4927 18.8514 22.0594 18.7108 21.6927 18.4358C21.6542 18.4066 20.8333 17.5921 20.8333 17.5921V8.35768C20.8333 8.35768 21.6521 7.53581 21.6927 7.5056C22.3219 7.03268 23.15 6.95664 23.8573 7.30977C24.5646 7.66289 25 8.37018 25 9.15872Z"
          fill="#64748B"
        />
      </g>
      <defs>
        <clipPath id="clip0_192011_12332">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
