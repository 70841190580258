import PropTypes from 'prop-types'
import { getFile } from 'publisher/reducers/filesReducer'
import { isScreenshotMode } from 'publisher/reducers/managementReducer'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonAudio from 'common/components/entities/Audio'
import audioTypes from 'common/constants/audioTypes'
import { entity } from 'common/types'

class Audio extends PureComponent {
  render() {
    const {
      entity: { options, styles },
      file = null,
    } = this.props
    const {
      appearance: { desktop, mobile },
    } = options
    let src = null

    switch (options.type) {
      case audioTypes.FILE:
        src = (file && file.path) || ''
        break
      case audioTypes.URL:
        src = options.url
        break
      default:
        src = null
    }

    if (this.props.isScreenshotMode) {
      return <img src={`${process.env.CLOUDFRONT}/common/audio.png`} />
    }

    return (
      <StyleWrapper desktop={desktop} mobile={mobile}>
        <CommonAudio htmlAttrId={options.attrId} style={styles} src={src} />
      </StyleWrapper>
    )
  }
}

Audio.propTypes = {
  entity: entity.isRequired,
  file: PropTypes.objectOf(PropTypes.any),
  isScreenshotMode: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ files, management }, { entity: { options } }) => ({
  file: getFile(files, options.srcFileId),
  isScreenshotMode: isScreenshotMode(management),
})

export default connect(mapStateToProps)(Audio)
