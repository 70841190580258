import React, { RefObject } from 'react'
import RawHtmlUi from './ui/RawHtmlUi'
import { AlignType } from '../../../types/styleTypes'

interface CommonRawHtmlInterfaceProps {
  textAlign: AlignType
  mobileTextAlign?: AlignType
  html?: string
  htmlAttrId: string
  publisherRef?: RefObject<HTMLDivElement>
}

const RawHtml = ({
  html,
  htmlAttrId,
  textAlign,
  mobileTextAlign,
  publisherRef,
}: CommonRawHtmlInterfaceProps) => {
  return (
    <RawHtmlUi
      textAlign={textAlign}
      mobileTextAlign={mobileTextAlign}
      attrId={htmlAttrId}
      dangerouslySetInnerHTML={{ __html: html ?? '' }}
      ref={publisherRef}
    />
  )
}

export default RawHtml
