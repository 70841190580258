import PropTypes from 'prop-types'
import React from 'react'
import { borderTypeValues } from 'common/constants/settings'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import ContentBoxHeaderOldUi from './ui/ContentBoxHeaderOldUi'
import ContentBoxInnerOldUi from './ui/ContentBoxInnerOldUi'
import ContentBoxWrapperOldUi from './ui/ContentBoxWrapperOldUi'

const ContentBoxOld = props => {
  const { styles, mobileStyles, options, mobileOptions, children } = props
  const { borderType, ...restOptions } = options
  // padding left and padding right from emulation
  // thus we will not override padding styles
  const { padding, paddingLeft, paddingRight, marginLeft, ...restStyles } =
    styles
  const {
    padding: mobilePadding,
    paddingLeft: mobilePaddingLeft,
    paddingRight: mobilePaddingRight,
    ...restMobileStyles
  } = mobileStyles
  return (
    <ContentBoxWrapperOldUi
      attrId={options.attrId}
      styles={{
        ...restStyles,
        ...borderTypeValues[borderType],
      }}
      mobileStyles={{
        ...restMobileStyles,
        ...borderTypeValues[mobileOptions.borderType],
      }}
      id={options.attrId}
    >
      {options.headerVisibility === '1' && (
        <ContentBoxHeaderOldUi
          styles={restOptions.headerStyles}
          mobileStyles={mobileOptions.headerStyles}
        >
          {restOptions.headerTitle}
        </ContentBoxHeaderOldUi>
      )}
      <ContentBoxInnerOldUi
        styles={{
          padding,
          paddingLeft,
          paddingRight,
        }}
        mobileStyles={{
          padding: mobilePadding,
          paddingLeft: mobilePaddingLeft,
          paddingRight: mobilePaddingRight,
        }}
      >
        {children}
      </ContentBoxInnerOldUi>
    </ContentBoxWrapperOldUi>
  )
}

ContentBoxOld.propTypes = {
  mobileStyles: PropTypes.objectOf(PropTypes.string).isRequired,
  styles: PropTypes.objectOf(PropTypes.string).isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileOptions: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default provideMobileStyles(ContentBoxOld)
