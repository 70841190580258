import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import usePage from '../hooks/usePage'
import { getGlobalSettings } from '../reducers/pageReducer'

function PopupForm({ children }) {
  const globalSettings = usePage(getGlobalSettings)
  // inline-block need to send dimensions for iframe from iframe.js.twig
  return (
    <React.Fragment>
      <Helmet>
        {/* inline style elements */}
        <style type="text/css">
          {`
            html {
             width: 100%;
             text-align: center;
            }
            #app {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
            }
          `}
        </style>
      </Helmet>
      {React.cloneElement(children, { maxHeight: '100%', globalSettings })}
    </React.Fragment>
  )
}

PopupForm.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PopupForm
