import React, { useEffect } from 'react'
import { FacebookCommentsInterface } from 'common/types/entities/FacebookCommentsInterface'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import FacebookCommentsPlaceholderUi from 'publisher/components/entities/FacebookComments/ui/FacebookCommentsPlaceholderUi'
import { useDelay } from 'publisher/hooks/useDelay'
import useManagement from 'publisher/hooks/useManagement'
import { selectors as managementSelectors } from 'publisher/reducers/managementReducer'
import loadScript from 'publisher/utils/loadScript'

interface FacebookCommentsProps {
  entity: FacebookCommentsInterface
}

declare global {
  interface Window {
    // https://developers.facebook.com/docs/reference/javascript/FB.XFBML.parse/
    FB?: { XFBML: { parse: () => void } }
  }
}

function FacebookComments({ entity }: FacebookCommentsProps) {
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const url = useManagement(managementSelectors.getUrl)

  const isVisible = useDelay(entity.delay)

  useEffect(() => {
    if (!isVisible) return
    loadScript(
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0',
      'facebook-jssdk',
      () => {
        if (window.FB) {
          window.FB.XFBML.parse()
        }
      },
    )
  }, [url, isVisible])

  if (isPreview) {
    return (
      <FacebookCommentsPlaceholderUi
        src={`${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/fb_comments.png`}
      />
    )
  }

  return (
    isVisible && (
      <StyleWrapper
        mobileMargin={entity.mobileMargin}
        margin={entity.margin}
        appearance={entity.appearance}
      >
        <div
          className="fb-comments"
          data-href={entity.href || url}
          data-width="100%"
          data-numposts={entity.numPosts}
        />
      </StyleWrapper>
    )
  )
}

export default FacebookComments
