import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import ReactPlayer from 'react-player/lazy'
import { connect } from 'react-redux'
import styled, { keyframes, css } from 'styled-components'
import StyleWrapper from 'common/components/StyleWrapper'
import videoTypes from 'common/constants/videoTypes'
import * as types from 'common/types'
import { splitMarginsAndRestStyles } from '../../common/utils/styleUtils'
import { getFile } from '../reducers/filesReducer'
import { getDateTime } from '../reducers/webinarSession'

const VideoUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  'video::-internal-media-controls-download-button' {
    display: none;
  }
  'video::-webkit-media-controls-enclosure' {
    overflow: hidden;
  }
  'video::-webkit-media-controls-panel' {
    width: calc(100% + 30px);
  }
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: ${p => p.styles.borderTopLeftRadius};
    border-top-right-radius: ${p => p.styles.borderTopRightRadius};
    border-bottom-left-radius: ${p => p.styles.borderBottomRightRadius};
    border-bottom-right-radius: ${p => p.styles.borderBottomLeftRadius};
    overflow: hidden;
  }
  ${props =>
    props.mobile === false &&
    css`
      ${p => p.theme.phone} {
        display: none;
      }
    `}
  ${props =>
    props.desktop === false &&
    css`
      ${p => p.theme.desktop} {
        display: none;
      }
    `}
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`
const bounceKeyframes = keyframes`
  0% { transform: scale(1.2) }
  100% { transform: scale(0.99) }
`

const UnMute = styled.div`
  position: absolute;
  z-index: 2;
  cursor: pointer;
  height: 50px;
  width: 50px;
  top: 50px;
  left: 50px;
  background-size: cover;
  background-image: url(${process.env
    .CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/webinar/unmute.png);
  animation: ${bounceKeyframes} 0.2s infinite ease-in-out alternate;
`

class WebinarSessionVideo extends PureComponent {
  constructor(props) {
    super(props)
    const replay =
      typeof document === 'undefined'
        ? false
        : Boolean(new URLSearchParams(document.location.search).get('replay'))
    this.state = {
      showUnMute: false,
      replay,
    }
  }

  onReady = seconds => () => {
    const video = this.player.getInternalPlayer()
    const isPlaying =
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    if (!this.state.replay && !isPlaying) {
      this.player.seekTo(seconds)
    }
  }

  onError = async e => {
    if (e.name === 'NotAllowedError') {
      // try muted auto play
      const video = this.player.getInternalPlayer()
      video.muted = true
      video.volume = 0
      await this.play()
      this.setState({ showUnMute: true })
    }
  }

  unMute = () => {
    const video = this.player.getInternalPlayer()
    video.muted = false
    video.volume = 1

    this.setState({ showUnMute: false })
  }

  play = async () => {
    const video = this.player.getInternalPlayer()
    try {
      await video.play()
    } catch (e) {}
  }

  ref = player => {
    this.player = player
  }

  render() {
    const {
      entity: { options, styles, mobileStyles },
      file = null,
      webinarStartDate,
    } = this.props
    const {
      appearance: { desktop, mobile },
      attrId,
    } = options
    const startSeconds = Math.round(
      (Date.now() - webinarStartDate.getTime()) / 1000,
    )
    let url = null
    switch (options.type) {
      case videoTypes.FILE:
        url = (file && file.path) || ''
        break
      case videoTypes.URL:
        url = options.url || ''
        break
      default:
        return null
    }
    const [margins, restStyles] = splitMarginsAndRestStyles(styles)
    const [mobileMargins, mobileRestStyles] =
      splitMarginsAndRestStyles(mobileStyles)

    return (
      <StyleWrapper styles={margins} mobileStyles={mobileMargins}>
        <VideoUi
          attrId={attrId}
          styles={restStyles}
          mobileStyles={mobileRestStyles}
          desktop={desktop}
          mobile={mobile}
        >
          {this.state.showUnMute && <UnMute onClick={this.unMute} />}
          <ReactPlayer
            url={url}
            style={{
              // https://github.com/CookPete/react-player#responsive-player
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
            controls={this.state.replay}
            width="100%"
            height="100%"
            ref={this.ref}
            onReady={this.onReady(startSeconds)}
            onError={this.onError}
            className="react-player"
            playing
          />
        </VideoUi>
      </StyleWrapper>
    )
  }
}

WebinarSessionVideo.propTypes = {
  file: PropTypes.objectOf(PropTypes.any),
  webinarStartDate: PropTypes.instanceOf(Date).isRequired,
  entity: types.entity.isRequired,
}

const mapStateToProps = (
  { files, webinarSession },
  { entity: { options } },
) => ({
  file: getFile(files, options.srcFileId),
  webinarStartDate: getDateTime(webinarSession),
})

export default connect(mapStateToProps)(WebinarSessionVideo)
