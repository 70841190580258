import { useEffect, useState } from 'react'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import { isPopupActive } from 'publisher/reducers/managementReducer'
import { getAscendantPopupId } from 'publisher/reducers/pageReducer'
import { useManagement, usePage } from 'publisher/store'
import useCalculateAutoPlay from './useCalculateAutoPlay'

export const useVideoPlaying = (entity: VideoInterface) => {
  const autoplay = useCalculateAutoPlay(entity)

  const popupId = usePage(page => getAscendantPopupId(page, entity))
  const isActive = useManagement(page => isPopupActive(page, popupId))

  const [playing, setPlaying] = useState(
    popupId ? isActive && autoplay : autoplay,
  )

  useEffect(() => {
    setPlaying(popupId ? isActive && autoplay : autoplay)
  }, [popupId, isActive, autoplay])

  return {
    playing,
    setPlaying,
  }
}
