import { OptInState } from 'publisher/store/optIn/optInStateInterface'

export function getFieldValueBySlug(state: OptInState, slug?: string) {
  return slug && state.fields[slug]
}

export function getFieldErrorsBySlug(state: OptInState, slug?: string) {
  return (slug && state.errors.fields[slug]) || []
}

export const getAvailableCountries = (state: OptInState) => {
  try {
    return JSON.parse(state.availableCountries)
  } catch (e) {
    return {}
  }
}
