import React from 'react'
import { useSelector } from 'react-redux'
import CommonBody from 'common/components/entities/BodyOld'
import * as types from 'common/types'
import useFiles, { selectors as filesSelectors } from '../../hooks/useFiles'
import { getFile } from '../../reducers/filesReducer'
import BaseNodeChildren from '../ChildrenEntities'

function Body({ entity }) {
  let { styles, mobileStyles, options, childIds, mobileOptions } = entity
  const backgroundFile = useFiles(files =>
    filesSelectors.getFile(files, options.backgroundFileId),
  )
  const mobileBackgroundFile = useFiles(files =>
    filesSelectors.getFile(files, mobileOptions.backgroundFileId),
  )
  const videoFile = useSelector(state =>
    getFile(state.files, options.videoFileId),
  )
  const calculatedMobileBackgroundFile =
    typeof mobileOptions.backgroundFileId === 'undefined'
      ? backgroundFile
      : mobileBackgroundFile
  return (
    <CommonBody
      styles={{
        ...styles,
        width: '100%',
      }}
      mobileStyles={mobileStyles}
      options={{ ...options, videoFile }}
      mobileOptions={mobileOptions}
      backgroundFile={backgroundFile}
      mobileBackgroundFile={calculatedMobileBackgroundFile}
      backgroundStyles={{
        zIndex: -1,
      }}
      videoPlay
      videoLoop
    >
      <BaseNodeChildren childIds={childIds} />
    </CommonBody>
  )
}

Body.propTypes = {
  entity: types.entity.isRequired,
}

export default Body
