import styled, { css } from 'styled-components'
import { changeStringColorOpacity } from 'common/utils/colorHelpers'


const TwoStepPaymentFormHeaderItemUi = styled.div`
  position: relative;
  ${p =>
    p.active &&
    css`
      &::before {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 49%;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${p => p.headerUnderlineColor};
      }
    `};
  display: flex;
  flex-basis: 50%;
  ${p => p.theme.phone} {
    background-color: ${p => p.mobileStyles.backgroundColor};
    color: ${p =>
      p.active
        ? p.mobileStyles.color
        : changeStringColorOpacity(p.mobileStyles.color, 0.7)};
  }
  background-color: ${p => p.styles.backgroundColor};
  color: ${p =>
    p.active ? p.styles.color : changeStringColorOpacity(p.styles.color, 0.7)};
  padding: 15px;
  border-bottom: 5px solid ${p => p.headerUnderlineColor};
`

export default TwoStepPaymentFormHeaderItemUi
