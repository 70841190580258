import React from 'react'
import { CommonBlogPostLayoutBody } from 'common/components/entities/blog-post-layout-body'
import { BlogPostLayoutInterface } from 'common/types/entities/blog-post-layout-body-interface'
import BaseNodeChildren from '../ChildrenEntities'

interface BlogPostLayoutBodyProps {
  entity: BlogPostLayoutInterface
}

export function BlogPostLayoutBody({ entity }: BlogPostLayoutBodyProps) {
  const { childIds } = entity

  return (
    <CommonBlogPostLayoutBody>
      <BaseNodeChildren childIds={childIds} />
    </CommonBlogPostLayoutBody>
  )
}
