import React from 'react'
import { CommonBlogPageBody } from 'common/components/entities/blog-page-body'
import { BlogPageBodyInterface } from 'common/types/entities/blog-page-body-interface'
import { useBackgroundImage } from 'publisher/hooks/useBackgroundImage'
import BaseNodeChildren from '../ChildrenEntities'

interface BlogPageBodyProps {
  entity: BlogPageBodyInterface
}

export function BlogPageBody({ entity }: BlogPageBodyProps) {
  const { childIds } = entity

  const {
    background,
    backgroundColor,
    mobileBackgroundColor,
    blur,
    mobileBackground,
  } = entity
  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })
  return (
    <CommonBlogPageBody
      background={background}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      mobileBackground={mobileBackground}
      blur={blur}
    >
      <BaseNodeChildren childIds={childIds} />
    </CommonBlogPageBody>
  )
}

export default BlogPageBody
