import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import TimerItemDelimiterOldUi from './ui/TimerItemDelimiterOldUi'
import TimerItemOldUi from './ui/TimerItemOldUi'
import TimerOldUi from './ui/TimerOldUi'

function Timer(props) {
  const {
    htmlAttrId = '',
    hideDays = false,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
    wrapperStyles,
    labelStyles,
    mobileLabelStyles = {},
    timeStyles,
    mobileTimeStyles = {},
    locale,
  } = props
  const { t, i18n } = useTranslation('common')
  const translate = locale ? i18n.getFixedT(locale, 'common') : t
  return (
    <TimerOldUi
      styles={wrapperStyles}
      fontFamily={wrapperStyles.fontFamily}
      htmlAttrId={htmlAttrId}
    >
      {!hideDays && (
        <React.Fragment>
          <TimerItemOldUi
            label={translate('components.timer.days')}
            time={days}
            mobileTimeStyles={mobileTimeStyles}
            mobileLabelStyles={mobileLabelStyles}
            labelStyles={labelStyles}
            timeStyles={timeStyles}
          />
          <TimerItemDelimiterOldUi
            styles={timeStyles}
            mobileStyles={mobileTimeStyles}
          >
            {' '}
            :{' '}
          </TimerItemDelimiterOldUi>
        </React.Fragment>
      )}
      <TimerItemOldUi
        label={translate('components.timer.hours')}
        time={hours}
        mobileTimeStyles={mobileTimeStyles}
        mobileLabelStyles={mobileLabelStyles}
        labelStyles={labelStyles}
        timeStyles={timeStyles}
      />
      <TimerItemDelimiterOldUi
        styles={timeStyles}
        mobileStyles={mobileTimeStyles}
      >
        {' '}
        :{' '}
      </TimerItemDelimiterOldUi>
      <TimerItemOldUi
        label={translate('components.timer.minutes')}
        time={minutes}
        mobileTimeStyles={mobileTimeStyles}
        mobileLabelStyles={mobileLabelStyles}
        labelStyles={labelStyles}
        timeStyles={timeStyles}
      />
      <TimerItemDelimiterOldUi
        styles={timeStyles}
        mobileStyles={mobileTimeStyles}
      >
        {' '}
        :{' '}
      </TimerItemDelimiterOldUi>
      <TimerItemOldUi
        label={translate('components.timer.seconds')}
        time={seconds}
        mobileTimeStyles={mobileTimeStyles}
        mobileLabelStyles={mobileLabelStyles}
        labelStyles={labelStyles}
        timeStyles={timeStyles}
      />
    </TimerOldUi>
  )
}

Timer.propTypes = {
  htmlAttrId: PropTypes.string,
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  wrapperStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  labelStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  timeStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileLabelStyles: PropTypes.objectOf(PropTypes.any),
  mobileTimeStyles: PropTypes.objectOf(PropTypes.any),
  hideDays: PropTypes.bool,
  locale: PropTypes.string,
}

export default provideMobileStyles(Timer)
