import React from 'react'

export const WorldIcon2 = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_192011_12328)">
        <path
          d="M12.4997 0.5C5.59238 0.5 -0.0263672 6.10729 -0.0263672 13C-0.0263672 19.8927 5.58092 25.5 12.4736 25.5C19.3663 25.5 24.9736 19.8927 24.9736 13C24.9736 6.10729 19.3778 0.5 12.4997 0.5ZM13.5413 20.2917H11.458V16.125L9.37467 14.0417V11.9583L8.33301 10.9167H5.20801L3.02467 8.62187C4.68613 5.05937 8.30801 2.58333 12.4997 2.58333C12.7153 2.58333 12.9288 2.59062 13.1413 2.60417C12.2268 3.99271 11.458 5.1875 11.458 5.1875L13.0205 7.79167L11.9788 8.83333L9.89551 7.79167L8.33301 9.35417L9.89551 10.9167H12.4997L16.6663 13.5208L13.5413 20.2917ZM19.7913 13L18.7497 8.83333L21.2507 7.38958C22.2882 9.00937 22.8893 10.9354 22.8893 13C22.8893 13.5042 22.8528 14 22.783 14.4854L19.7913 13Z"
          fill="#64748B"
        />
      </g>
      <defs>
        <clipPath id="clip0_192011_12328">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
