import React, { useEffect, useState } from 'react'
import CommonAffiliateBadge from 'common/components/entities/AffiliateBadge'
import useManagement, { selectors } from '../hooks/useManagement'

function AffiliateBadge() {
  const [isClient, setIsClient] = useState(false)
  const affiliateLink = useManagement(selectors.getAffiliateLink)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient && <CommonAffiliateBadge affiliateLink={affiliateLink} />
}

export default AffiliateBadge
