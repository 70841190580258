import React from 'react'
import PropTypes from 'prop-types'
import StepButtonSubTextUi from './ui/StepButtonSubTextUi'
import StepButtonTextUi from './ui/StepButtonTextUi'
import StepButtonUi from './ui/StepButtonUi'

function Button({
  text,
  subText,
  styles,
  mobileStyles,
  onClick,
  disabled = false,
}) {
  const calculatedMobileStyles = {
    backgroundColor: mobileStyles.backgroundColor || styles.backgroundColor,
    color: mobileStyles.color || styles.color,
  }
  return (
    <StepButtonUi
      styles={styles}
      mobileStyles={calculatedMobileStyles}
      onClick={onClick}
      disabled={disabled}
    >
      <StepButtonTextUi>{text}</StepButtonTextUi>
      {subText && <StepButtonSubTextUi>{subText}</StepButtonSubTextUi>}
    </StepButtonUi>
  )
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  mobileStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
  }),
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  subText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default Button
