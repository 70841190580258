import React from 'react'
import PropTypes from 'prop-types'
import OverlayUi from './ui/Overlay'
import PointlessDiv from './ui/PointlessDiv'

const Overlay = ({ dark = false, zIndex = 1, hide = null }) => (
  <OverlayUi onClick={hide} dark={dark} zIndex={zIndex}>
    <PointlessDiv />
  </OverlayUi>
)

Overlay.propTypes = {
  hide: PropTypes.func,
  dark: PropTypes.bool,
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Overlay
