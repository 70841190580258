import React from 'react'
import { useTranslation } from 'react-i18next'
import { PriceElementPeriodInterface } from '../price-element-interface'
import { CommonPriceElementUnknownUi } from '../ui'
import {
  PriceElementPlansUi,
  PriceElementPlanTitleUi,
  PriceElementPlanUi,
  PriceElementPlanPriceWrapperUi,
  PriceElementPlanPriceUi,
  PriceElementPlanButtonUi,
  PriceElementPlansWrapperUi,
  PriceElementPlansPaginationUi,
  PriceElementPlansPaginationButtonUi,
} from './ui'

interface CommonPriceElementPlansProps {
  plans: PriceElementPeriodInterface[]
  activeIndex: number
  onClick: (id: number) => void
  syncScroll: (id: number) => void
  registerContainer: (ref: HTMLDivElement | null) => void
}

export const CommonPriceElementPlans = ({
  plans,
  onClick,
  registerContainer,
  syncScroll,
  activeIndex,
}: CommonPriceElementPlansProps) => {
  const { t } = useTranslation()

  return (
    <PriceElementPlansWrapperUi>
      <PriceElementPlansPaginationUi>
        <PriceElementPlansPaginationButtonUi
          type="button"
          onClick={() => syncScroll(Math.max(activeIndex - 1, 0))}
          disabled={activeIndex === 0}
        >
          <i className="fal fa-chevron-circle-left" id="left-arrow"></i>
        </PriceElementPlansPaginationButtonUi>
        <PriceElementPlansPaginationButtonUi
          type="button"
          onClick={() => syncScroll(activeIndex + 1)}
          disabled={activeIndex === 3}
        >
          <i className="fal fa-chevron-circle-right" id="right-arrow"></i>
        </PriceElementPlansPaginationButtonUi>
      </PriceElementPlansPaginationUi>
      <PriceElementPlansUi ref={registerContainer}>
        {plans.map((plan, idx) => (
          <PriceElementPlanUi
            className="price-element-scroll-item"
            key={plan.title ?? '' + idx}
          >
            <PriceElementPlanTitleUi>
              {plan.title ?? <CommonPriceElementUnknownUi />}
            </PriceElementPlanTitleUi>
            <PriceElementPlanPriceWrapperUi>
              <PriceElementPlanPriceUi>
                {plan.cost ?? <CommonPriceElementUnknownUi width="30px" />}
              </PriceElementPlanPriceUi>
              /{plan.period ? t(plan.period) : <CommonPriceElementUnknownUi />}
            </PriceElementPlanPriceWrapperUi>
            <PriceElementPlanButtonUi
              type="button"
              onClick={() => onClick(idx)}
            >
              {t('components.price_element.popup_button')}
            </PriceElementPlanButtonUi>
          </PriceElementPlanUi>
        ))}
      </PriceElementPlansUi>
    </PriceElementPlansWrapperUi>
  )
}
