import React from 'react'

export const IconAvailable = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.167 11C20.167 16.546 15.671 21.042 10.125 21.042C4.57895 21.042 0.0830078 16.546 0.0830078 11C0.0830078 5.45395 4.57895 0.958008 10.125 0.958008C15.671 0.958008 20.167 5.45395 20.167 11ZM8.96345 16.3172L16.414 8.86665C16.6669 8.61366 16.6669 8.20343 16.414 7.95044L15.4977 7.03423C15.2448 6.7812 14.8345 6.7812 14.5815 7.03423L8.50532 13.1104L5.6685 10.2735C5.41551 10.0205 5.00528 10.0205 4.75225 10.2735L3.83604 11.1897C3.58305 11.4427 3.58305 11.853 3.83604 12.106L8.0472 16.3171C8.30023 16.5701 8.71042 16.5701 8.96345 16.3172Z"
      fill="#00A0FF"
    />
  </svg>
)
