import React, { memo } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonText from 'common/components/entities/TextNew/ui/TextUi'
import { TextInterface } from 'common/types/entities/TextInterface'
import { useDelay } from 'publisher/hooks/useDelay'
import { usePopupOpenerRef } from 'publisher/hooks/usePopupOpenerRef'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'

export interface TextProps {
  entity: TextInterface
}

const Text = ({ entity }: TextProps) => {
  const ref = usePopupOpenerRef()
  const isVisible = useDelay(entity.delay)
  const globalFontFamily = usePage(pageSelectors.getGlobalTextFontFamily)
  const linkColor = usePage(pageSelectors.getGlobalLinkColor)
  const textColor = usePage(pageSelectors.getGlobalTextColor)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const mobileGlobalFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalTextLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileTextLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const globalTextAlign = usePage(pageSelectors.getGlobalTextAlign)
  const globalMobileTextAlign = usePage(pageSelectors.getGlobalMobileTextAlign)

  return isVisible ? (
    <StyleWrapper
      mobile={entity.appearance.mobile}
      desktop={entity.appearance.desktop}
      styles={{ ...entity.margin }}
      mobileStyles={{ ...entity.mobileMargin }}
    >
      <CommonText
        id={entity.htmlAttrId}
        linkColor={linkColor}
        fontSize={entity.fontSize || globalFontSize}
        fontFamily={entity.fontFamily || globalFontFamily}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontSize={entity.mobileFontSize || mobileGlobalFontSize}
        lineHeight={entity.lineHeight || globalTextLineHeight}
        mobileLineHeight={entity.mobileLineHeight || globalMobileTextLineHeight}
        color={textColor}
        padding={entity.padding}
        fontStyle={entity.fontStyle}
        mobileFontStyle={entity.mobileFontStyle}
        fontWeight={entity.fontWeight}
        mobileFontWeight={entity.mobileFontWeight}
        mobilePadding={entity.mobilePadding}
        textAlign={entity.textAlign || globalTextAlign}
        mobileTextAlign={entity.mobileTextAlign || globalMobileTextAlign}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        letterSpacing={entity.letterSpacing}
        mobileLetterSpacing={entity.mobileLetterSpacing}
      >
        <div
          dangerouslySetInnerHTML={{ __html: entity.html || '' }}
          ref={ref}
        />
      </CommonText>
    </StyleWrapper>
  ) : (
    <div />
  )
}

export default memo(Text)
