import styled, { css } from 'styled-components'

export const CommonPriceElementWrapperUi = styled.div`
  font-family: inherit;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const CommonPriceElementContainerUi = styled.div`
  position: relative;
  max-width: 1120px;
  width: 100%;

  display: flex;
  flex-direction: column;

  background-color: transparent;
`

export const CommonPriceElementPlansWrapperUi = styled.div`
  width: 100%;
  display: flex;
  padding: 0 3px;
  margin-bottom: 65px;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 30px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      flex-direction: column;
      margin-bottom: 30px;
    `}
`

export const CommonPriceElementTableContainerUi = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 0 10px;
  border: solid 3px #cbf4ff;
  border-radius: 10px;

  background-color: white;
`

export const CommonPriceElementTitleUi = styled.h2`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #142d63;
`

interface CommonPriceElementUnknownUiStylesInterface {
  width?: string
}

export const CommonPriceElementUnknownUi = styled.span<CommonPriceElementUnknownUiStylesInterface>`
  width: ${p => p.width ?? '100%'};
  max-width: 100%;
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  display: inline-block;
`
