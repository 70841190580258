import { PageTypeEnum } from 'common/enums/PageTypeEnum'

export enum ButtonActionTypeEnum {
  SendForm = 'sendForm',
  ShowPopup = 'showPopup',
  OpenUrl = 'openUrl',
  NextStepRedirection = 'nextStepRedirection',
  LectureCompleteAndRedirect = 'lectureCompleteAndRedirect',
  Login = 'login',
  ResetPassword = 'resetPassword',
  ResetRequest = 'resetRequest',
  ConfirmRegistration = 'confirmRegistration',
  DownloadFile = 'downloadFile',
  BlogLink = 'blogLink',
}

export const buttonActionsNames: Partial<Record<ButtonActionTypeEnum, string>> =
  {
    [ButtonActionTypeEnum.SendForm]: 'components.button.actions.send_form',
    [ButtonActionTypeEnum.ShowPopup]: 'components.button.actions.show_popup',
    [ButtonActionTypeEnum.OpenUrl]: 'components.button.actions.open_url',
    [ButtonActionTypeEnum.NextStepRedirection]:
      'components.button.actions.next_step_redirection',
    [ButtonActionTypeEnum.LectureCompleteAndRedirect]:
      'components.button.actions.lecture_complete_and_redirect',
    [ButtonActionTypeEnum.BlogLink]: 'components.button.actions.blog_link',
    [ButtonActionTypeEnum.Login]:
      'entity_settings.member_access.modes.choices.login',
    [ButtonActionTypeEnum.ResetPassword]:
      'entities.member_access.request.default_button_text',
    [ButtonActionTypeEnum.ConfirmRegistration]:
      'entities.member_access.default_password_confirm_label',
    [ButtonActionTypeEnum.DownloadFile]:
      'components.button.actions.download_file',
  }

export const getButtonActions = (pageType: PageTypeEnum) => {
  switch (pageType) {
    case PageTypeEnum.Lecture:
      return [ButtonActionTypeEnum.OpenUrl, ButtonActionTypeEnum.DownloadFile]
    case PageTypeEnum.NotFound:
      return [ButtonActionTypeEnum.OpenUrl, ButtonActionTypeEnum.DownloadFile]
    case PageTypeEnum.CustomLogin:
      // custom login button options depend on access form type and cannot be changed manually
      return []
    case PageTypeEnum.InfoPage:
      return [
        ButtonActionTypeEnum.OpenUrl,
        ButtonActionTypeEnum.ShowPopup,
        ButtonActionTypeEnum.DownloadFile,
      ]
    case PageTypeEnum.Inline:
    case PageTypeEnum.Popup:
      return [
        ButtonActionTypeEnum.SendForm,
        ButtonActionTypeEnum.OpenUrl,
        ButtonActionTypeEnum.NextStepRedirection,
        ButtonActionTypeEnum.DownloadFile,
      ]
    case PageTypeEnum.InactiveFunnelPage:
      return [
        ButtonActionTypeEnum.SendForm,
        ButtonActionTypeEnum.ShowPopup,
        ButtonActionTypeEnum.OpenUrl,
        ButtonActionTypeEnum.DownloadFile,
      ]
    case PageTypeEnum.BlogHome:
    case PageTypeEnum.BlogLayout:
    case PageTypeEnum.BlogPostBody:
    case PageTypeEnum.BlogPostLayout:
    case PageTypeEnum.BlogPostListing:
    case PageTypeEnum.BlogStatic:
      return [
        ButtonActionTypeEnum.SendForm,
        ButtonActionTypeEnum.ShowPopup,
        ButtonActionTypeEnum.OpenUrl,
        ButtonActionTypeEnum.BlogLink,
        ButtonActionTypeEnum.DownloadFile,
      ]
    default:
      return [
        ButtonActionTypeEnum.SendForm,
        ButtonActionTypeEnum.ShowPopup,
        ButtonActionTypeEnum.OpenUrl,
        ButtonActionTypeEnum.NextStepRedirection,
        ButtonActionTypeEnum.DownloadFile,
      ]
  }
}
