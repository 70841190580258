import React, { useEffect, useState } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CaptchaContentUi from 'common/components/entities/Recaptcha/ui/CaptchaContentUi'
import CaptchaPlaceHolderUi from 'common/components/entities/Recaptcha/ui/CaptchaPlaceholderUi'
import CaptchaUi from 'common/components/entities/Recaptcha/ui/CaptchaUi'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import FieldErrors from 'publisher/components/FieldErrors'
import useManagement from 'publisher/hooks/useManagement'
import { selectors as managementSelectors } from 'publisher/reducers/managementReducer'
import { usePage, typedPageSelectors } from 'publisher/store/'
import loadScript from 'publisher/utils/loadScript'

type RecaptchaProps = {
  entity: RecaptchaInterface
  errors: string[]
  onSubmit: (token: string) => void
}

function CommonRecaptcha({ entity, errors, onSubmit }: RecaptchaProps) {
  const [isClient, setIsClient] = useState(false)
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const captchaOpenKey = useManagement(managementSelectors.getCaptcha)
  const locale = usePage(page => typedPageSelectors.getLocale(page))

  const originalSubmitTitle = `onSubmit${entity.id}`

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    loadScript(
      `https://www.google.com/recaptcha/api.js?hl=${locale}`,
      'g-recaptcha-jssdk',
      () => null,
    )
    //@ts-ignore
    window[originalSubmitTitle] = onSubmit
    //@ts-ignore
  }, [locale])

  const whiteCaptcha = (
    <CaptchaPlaceHolderUi
      alignSelf={entity.alignSelf}
      mobileAlignSelf={entity.mobileAlignSelf}
      src="https://developers.google.com/recaptcha/images/light.png"
    />
  )
  const darkCaptcha = (
    <CaptchaPlaceHolderUi
      alignSelf={entity.alignSelf}
      mobileAlignSelf={entity.mobileAlignSelf}
      src="https://developers.google.com/recaptcha/images/dark.png"
    />
  )
  return (
    <>
      {isClient && (
        <StyleWrapper
          styles={entity.margin}
          mobileStyles={entity.mobileMargin}
          desktop={entity.appearance.desktop}
          mobile={entity.appearance.mobile}
        >
          {captchaOpenKey ? (
            <CaptchaContentUi>
              {isPreview || typeof window === 'undefined' ? (
                entity.theme === 'light' ? (
                  whiteCaptcha
                ) : (
                  darkCaptcha
                )
              ) : (
                <CaptchaUi
                  alignSelf={entity.alignSelf}
                  mobileAlignSelf={entity.mobileAlignSelf}
                  id="recaptcha"
                  className="g-recaptcha"
                  data-sitekey={captchaOpenKey}
                  data-callback={originalSubmitTitle}
                  data-theme={entity.theme}
                />
              )}
              {typeof window !== 'undefined' && (
                <FieldErrors errors={errors} align={entity.alignSelf} />
              )}
            </CaptchaContentUi>
          ) : (
            <CaptchaContentUi />
          )}
        </StyleWrapper>
      )}
    </>
  )
}

export default CommonRecaptcha
