import styled, { css } from 'styled-components'

const thumbStyle = css`
   {
    -webkit-appearance: none;
    -moz-appearance: none;
    //position: absolute;
    border: 5px solid #fff;
    box-shadow: 0px 0px 1px 1px #e7e9f1;
    margin-top: -5px;
    height: 21px;
    width: 21px;
    border-radius: 100%;
    background: #149efc;
    cursor: pointer;
  }
`

const trackStyle = (progress: number) => css`
   {
    animation: 0.2s;
    width: 100%;
    height: 10px;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      to right,
      #1ba0ff ${progress}px,
      #ebedf0 ${progress}px
    );
    border-radius: 5px;
    border: 0 solid #ccc;
  }
`

export const PricePlanSliderUi = styled.div`
  margin-top: 50px;
  margin-bottom: -40px;
  ${p => p.theme.phone} {
    display: none;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      display: none;
    `}
`

type SioPricePlanRangeUiProps = {
  progress: number
}

const PricePlanRangeUi = styled.input<SioPricePlanRangeUiProps>`
  position: relative;
  appearance: none;
  outline: none;
  margin: 15px 0;
  &::-webkit-slider-thumb
    ${thumbStyle},
    &::-webkit-slider-runnable-track
    ${p => trackStyle(p.progress)},
    &::-moz-slider-thumb
    ${thumbStyle},
    &::-moz-range-thumb {
    ${thumbStyle};
    margin-top: 12px !important;
  }
  ,
  &::-moz-range-track
    ${p => trackStyle(p.progress)},
    &::-ms-thumb
    ${thumbStyle},
    &::-ms-track
    ${p => trackStyle(p.progress)},
    &::-moz-focus-outer {
    border: none;
  }
`

type TooltipProps = {
  left: number
}

// @ts-ignore
export const Tooltip = styled.div.attrs(() => ({
  for: 'test-range',
}))<TooltipProps>`
  display: inline;
  background: #fff;
  box-shadow: 0 3px 10px 0 #1ba0ff7d;
  padding: 7px 12px;
  position: relative;
  top: -77px;
  border-radius: 4px;
  left: ${p => p.left}px;
  transform: translateX(-50%);
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    transform: rotate(45deg);
    bottom: -5px;
    left: calc(50% - 2.5px);
    border: 5px solid #000000;
    border-color: transparent #fff #fff transparent;
    z-index: 10;
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background: #ffffff;
    transform: rotate(45deg);
    border: 5px solid #ffffff;
    border-color: transparent transparent #fff #fff;
    box-shadow: 0 3px 10px 0 #1ba0ff7d;
    bottom: -5px;
    left: calc(50% - 2.5px);
    z-index: -10;
  }
  span {
    font-size: 17px;
    font-weight: bold;
    color: #1ba0ff;
  }
`

export default PricePlanRangeUi
