import styled from 'styled-components'

export const BookingSummaryItemUi = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-weight: 700;
  font-size: 17px;
  line-height: 34px;
  align-items: center;
`
