import styled, { css } from 'styled-components'
import {
  RowBaseInterface,
  RowInterface,
} from 'common/components/entities/Row/types/RowInterface'
import { getColumnDirectionCss } from 'common/components/entities/Row/utils/get-column-direction-css'
import { backgroundImageFixForIOS, containerCss } from 'common/utils/styleUtilsNew'
import { Background } from 'common/types/BackgroundType'

interface RowUiProps
  extends Pick<
    RowInterface,
    | 'htmlAttrId'
    | 'border'
    | 'mobileBorder'
    | 'boxShadow'
    | 'mobileBoxShadow'
    | 'padding'
    | 'mobilePadding'
    | 'backgroundColor'
    | 'mobileBackgroundColor'
  >,
  RowBaseInterface {
  disableColumnGap: boolean
  backgroundImage: string
  mobileBackgroundImage: string
  background: Background
}

const RowUi = styled.div.attrs<RowUiProps>(p => ({
  id: p.htmlAttrId,
})) <RowUiProps>`
  display: grid;
  position: relative;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
  ${containerCss}
  background-image:${p => p.backgroundImage};
  ${p => p.theme.phone} {
    ${p => !p.disableStacking && getColumnDirectionCss(p.reverseColumns)}
    background-image: ${p => p.mobileBackgroundImage}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${!p.disableStacking && getColumnDirectionCss(p.reverseColumns)}
      background-image: ${p.mobileBackgroundImage};
    `}
  ${p =>
    p.disableColumnGap &&
    css`
      column-gap: unset !important;
    `}

      ${p =>
    backgroundImageFixForIOS(
      !!(p.mobileBackgroundImage || p.backgroundImage),
      p.background?.backgroundAttachment ?? '',
    )}
`

export default RowUi
