import styled, { CSSObject } from 'styled-components'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'

export const PriceElementPeriodUi = styled.div`
  width: 340px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const PriceElementPeriodDescriptionWrapperUi = styled.div`
  width: 270px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-end;
`

export const PriceElementPeriodSavingUi = styled.div`
  width: 54px;
  position: relative;
  color: #00a0ff;
  font-family: 'Caveat', cursive !important;
  font-weight: 700;
  font-size: 34px;
  line-height: 31px;
  flex-shrink: 0;
`

export const PriceElementPeriodSavingIconUi = styled.div`
  position: absolute;
  right: -18px;
  top: 10px;
`

interface PriceElementPeriodSavingTextUiStylesInterface {
  locale?: PageLocaleEnum
}

const getStylesForLocale = (locale: PageLocaleEnum): CSSObject => {
  switch (locale) {
    case 'ru':
      return {
        left: '-30px',
      }
    case 'fr':
      return {
        left: '-34px',
      }
    case 'ua':
      return {
        left: '-34px',
      }
    case 'de':
      return {
        left: '-47px',
      }
    case 'el':
      return {
        left: '-63px',
      }

    default:
      return {}
  }
}

export const PriceElementPeriodSavingTextUi = styled.span<PriceElementPeriodSavingTextUiStylesInterface>`
  position: absolute;
  top: -31px;
  left: 0;

  ${p => p.locale && getStylesForLocale(p.locale)}
`

export const PriceElementPeriodDescriptionUi = styled.div`
  width: 100%;
  padding-right: 10px;
  color: #142d63;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
`

export const PriceElementPeriodSwitcherUi = styled.div`
  width: 270px;
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  border-radius: 5px;
  overflow: hidden;
`

interface PriceElementPeriodBtnUiStylesInterface {
  isActive: boolean
}

export const PriceElementPeriodBtnUi = styled.button<PriceElementPeriodBtnUiStylesInterface>`
  width: 100%;
  line-height: inherit;
  font-weight: inherit;
  font-size: inherit;
  padding: 6px 0;
  background-color: ${p => (p.isActive ? '#00a0ff' : 'white')};
  color: ${p => (p.isActive ? 'white' : '#00a0ff')};
  cursor: ${p => (p.isActive ? 'default' : 'pointer')};
  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  border: ${p => (p.isActive ? '0.5px solid #00a0ff' : '0.5px solid #e4e8f0')};
  &:first-child {
    border-radius: 5px 0 0 5px;
    border-right: none;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
    border-left: none;
  }
`
