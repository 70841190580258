// import { useIntlDisplayNamesPolyfill } from 'publisher/hooks/use-intl-display-names-polyfill'
import Rollbar, { Level } from 'rollbar'
import { Provider as RollbarProvider, LEVEL_ERROR } from '@rollbar/react'
import React from 'react'
import { useSSR } from 'react-i18next'
import { Store } from 'redux'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import {
  BaseEntityInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import App from 'publisher/app'
import i18n from 'publisher/i18n-client'
import { RootState } from 'publisher/store'

export const rollbarConfig = (
  pageId: number,
  reportLevel?: Level,
): Rollbar.Configuration => ({
  enabled: process.env.NODE_ENV !== 'development',
  environment: process.env.APP_ENV,
  accessToken: process.env.ROLLBAR_PUBLISHER_KEY,
  captureUncaught: false,
  captureUnhandledRejections: false,
  autoInstrument: {
    network: false,
    log: false,
  },
  payload: {
    host: process.env.SYSTEME_IO_HOST,
    context: {
      pageId,
    },
  },
  reportLevel,
})

interface BaseAppProps {
  store: Store<RootState>
  createPage: (pageType: PageTypeEnum) => React.ReactNode
  createEntityElement: (
    pageType: PageTypeEnum,
  ) => (
    entity: BaseEntityInterface | OldEntityInterface | EntityInnerItemInterface,
  ) => React.ReactNode
  rollbarReportLevel?: Level
}

function BaseApp({
  store,
  createPage,
  createEntityElement,
  rollbarReportLevel = LEVEL_ERROR,
}: BaseAppProps) {
  // useIntlDisplayNamesPolyfill(store.getState().page.locale)
  // @ts-expect-error: todo
  useSSR(window.initialI18nStore, window.initialLanguage, { i18n })
  return (
    <RollbarProvider
      config={rollbarConfig(store.getState().page.id, rollbarReportLevel)}
    >
      <App
        store={store}
        createPage={createPage}
        createEntityElement={createEntityElement(store.getState().page.type)}
      />
    </RollbarProvider>
  )
}

export default BaseApp
