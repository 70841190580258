import styled, { css } from 'styled-components'
import { getBorderType } from 'common/utils/styleUtilsNew'
import { leftToRightDisplay } from '../../../utils/mixins'

export const fieldStyles = () => css`
  width: 100%;
  font-size: 16px;
  padding: 12px 0;
  outline: none;
  text-indent: 10px;
  appearance: none;
  text-overflow: ellipsis;
  ${p =>
    p.hasIcon &&
    css`
      padding-right: ${parseInt(p.styles.fontSize) + 10}px;
    `};
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
    ${({ mobileStyles = {} }) => leftToRightDisplay(mobileStyles.textAlign)}
    ${p => p.mobileBorderType && getBorderType(p.mobileBorderType)}
    ${p =>
      p.mobileStyles
        ? p.mobileStyles.borderStyle
        : css`
            border-style: none;
          `}
    ${p =>
      p.hasIcon &&
      p.mobileStyles &&
      p.mobileStyles.fontSize &&
      css`
        padding-right: ${parseInt(p.mobileStyles.fontSize) + 10}px;
      `};
  }
  ${({ styles = {} }) => styles}
  ${({ styles = {} }) => leftToRightDisplay(styles.textAlign)}
  ${p => p.borderType && getBorderType(p.borderType)}
  ${p =>
    p.hasErrors &&
    css`
      border-color: red;
    `}
    ${p =>
    p.placeholderColor &&
    css`
      &::placeholder {
        color: ${p.placeholderColor};
      }
    `}
      :disabled {
    opacity: 0.4;
  }
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
    ${p => p.mobileBorderType && getBorderType(p.mobileBorderType)}
            ${p => p.borderType && getBorderType(p.borderType)}
            ${p =>
      p.mobilePlaceholderColor &&
      css`
        &::placeholder {
          color: ${p.mobilePlaceholderColor};
        }
      `}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    p.mobilePlaceholderColor &&
    css`
      &::placeholder {
        color: ${p.mobilePlaceholderColor};
      }
    `}
`

const Field = styled.input.attrs(props => {
  const additionalAttrs = {}
  if (process.env.NODE_ENV === 'test') {
    additionalAttrs['data-testid'] = props.dataTestId
  }
  return {
    id: props.attrId,
    ...additionalAttrs,
  }
})`
  ${fieldStyles};
`

export default Field
