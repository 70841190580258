import styled, { css } from 'styled-components'
import { AlignType } from 'common/types/styleTypes'
import {
  fontCssTransient,
  mobileFontCssTransient,
  lineHeightCssTransient,
  mobileLineHeightCssTransient,
  FontPropsTransient,
} from 'common/utils/styleUtilsNew'

type BlogPostTitleUiNewProps = {
  $lineHeight?: number
  $mobileLineHeight?: number
  $alignSelf: AlignType
  $mobileAlignSelf?: AlignType
} & FontPropsTransient

const BlogPostTitleUiNew = styled.h1<BlogPostTitleUiNewProps>`
  text-align: ${p => p.$alignSelf || 'center'};
  ${fontCssTransient}
  ${p => p.$fontFamily && `font-family: ${p.$fontFamily} !important;`}
  ${lineHeightCssTransient}

${p => p.theme.phone} {
    text-align: ${p => p.$mobileAlignSelf || 'center'};
    ${mobileFontCssTransient}
    ${p =>
      p.$mobileFontFamily && `font-family: ${p.$mobileFontFamily} !important;`}
    ${mobileLineHeightCssTransient}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCssTransient}
      ${p.$mobileFontFamily &&
      `font-family: ${p.$mobileFontFamily} !important;`}
      ${mobileLineHeightCssTransient}
      text-align: ${p.$mobileAlignSelf || 'center'};
    `}
`

export default BlogPostTitleUiNew
