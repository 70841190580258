import React from 'react'

export const IconBookingCalendar = () => (
  <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 32.359C0.504712 33.1906 0.837153 33.9868 1.42519 34.5748C2.01323 35.1628 2.80943 35.4953 3.64103 35.5H32.359C33.1906 35.4953 33.9868 35.1628 34.5748 34.5748C35.1628 33.9868 35.4953 33.1906 35.5 32.359V15.7564H0.5V32.359ZM11.6641 23.3846C11.9165 23.1325 12.2587 22.9909 12.6154 22.9909C12.9721 22.9909 13.3143 23.1325 13.5667 23.3846L16.2051 25.9692L22.4333 19.7949C22.6863 19.6308 22.9871 19.5565 23.2874 19.5838C23.5877 19.611 23.8701 19.7383 24.0894 19.9452C24.3088 20.1521 24.4523 20.4267 24.497 20.7249C24.5416 21.0231 24.4849 21.3276 24.3359 21.5897L17.1564 28.7692C16.904 29.0213 16.5619 29.1629 16.2051 29.1629C15.8484 29.1629 15.5063 29.0213 15.2538 28.7692L11.6641 25.1795C11.4434 24.9327 11.3213 24.6132 11.3213 24.2821C11.3213 23.9509 11.4434 23.6314 11.6641 23.3846ZM35.5 5.4359V13.0641H0.5V5.4359C0.504712 4.6043 0.837153 3.8081 1.42519 3.22006C2.01323 2.63203 2.80943 2.29958 3.64103 2.29487H5.88462V1.84615C5.88462 1.48913 6.02644 1.14673 6.27889 0.894279C6.53135 0.641826 6.87375 0.5 7.23077 0.5C7.58779 0.5 7.93019 0.641826 8.18264 0.894279C8.4351 1.14673 8.57692 1.48913 8.57692 1.84615V2.29487H16.6538V1.84615C16.6538 1.48913 16.7957 1.14673 17.0481 0.894279C17.3006 0.641826 17.643 0.5 18 0.5C18.357 0.5 18.6994 0.641826 18.9519 0.894279C19.2043 1.14673 19.3462 1.48913 19.3462 1.84615V2.29487H27.4231V1.84615C27.4231 1.48913 27.5649 1.14673 27.8174 0.894279C28.0698 0.641826 28.4122 0.5 28.7692 0.5C29.1263 0.5 29.4687 0.641826 29.7211 0.894279C29.9736 1.14673 30.1154 1.48913 30.1154 1.84615V2.29487H32.359C33.1906 2.29958 33.9868 2.63203 34.5748 3.22006C35.1628 3.8081 35.4953 4.6043 35.5 5.4359Z"
      fill="#142D63"
    />
  </svg>
)
