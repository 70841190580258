import couponTypes from 'common/components/entities/Coupon/couponTypes'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { Coupon } from 'common/types/CouponType'
import { CurrencyEnum } from 'common/types/CurrencyType'
import {
  BaseEntityInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import {
  ProductVariantInterface,
  ProductInterface,
  ProductInfoInterface,
  ProductBumpInfoInterface,
} from 'common/types/entities/ProductInterface'
import { CalculatedPrice, OfferPricing } from 'common/types/pricingTypes'
import { centsToEuro, getReadablePrice } from './priceCalculator'

export function getProductInfo(
  productElements: (
    | BaseEntityInterface
    | OldEntityInterface
    | EntityInnerItemInterface
  )[],
  product: ProductInterface | null,
  activeProductVariant: ProductVariantInterface | null,
  productQuantity: number,
  isVatNotChargeable: boolean,
  productVat: number,
  locale: PageLocaleEnum,
  coupon?: Coupon,
) {
  const calculatedProductInfo: ProductInfoInterface = {
    name: '',
    priceWithTaxAndCurrency: '',
    variantDescription: '',
    currency: CurrencyEnum.EURO,
    netPrice: 0,
    priceWithoutTaxAndCurrency: 0,
    taxPercent: 0,
    taxAmount: 0,
  }
  if (!productElements.length || product === null) {
    return calculatedProductInfo
  }
  calculatedProductInfo.name = product.name
  calculatedProductInfo.taxPercent = product.tax

  const calculatedProductTax = isVatNotChargeable ? 0 : productVat

  const productPriceWithTax = parseFloat(
    Number(
      (product.price / 100) * productQuantity +
        calculatedProductTax * (product.price / 10000) * productQuantity,
    ).toFixed(2),
  )

  calculatedProductInfo.priceWithTaxAndCurrency = getReadablePrice(
    productPriceWithTax,
    product.currency,
    locale,
  )

  let priceAmountWithoutTax = product.price * productQuantity
  calculatedProductInfo.netPrice = getProductNetPrice(
    product.price,
    productQuantity,
    calculatedProductTax,
    coupon,
  )
  if (activeProductVariant) {
    if (activeProductVariant.price) {
      const activeProductVariantPrice = parseFloat(
        Number(
          (activeProductVariant.price / 100) * productQuantity +
            calculatedProductTax *
              (activeProductVariant.price / 10000) *
              productQuantity,
        ).toFixed(2),
      )

      calculatedProductInfo.priceWithTaxAndCurrency = getReadablePrice(
        activeProductVariantPrice,
        product.currency,
        locale,
      )

      priceAmountWithoutTax = activeProductVariant.price * productQuantity

      calculatedProductInfo.netPrice = getProductNetPrice(
        activeProductVariant.price,
        productQuantity,
        calculatedProductTax,
        coupon,
      )
    }
    calculatedProductInfo.variantDescription = `${activeProductVariant.productOptionValues
      .map(e => e.value)
      .join(' / ')}`
  }
  calculatedProductInfo.priceWithoutTaxAndCurrency = parseFloat(
    Number(priceAmountWithoutTax / 100).toFixed(2),
  )

  if (coupon) {
    if (coupon.discountType === couponTypes.fixed) {
      const netAmountAfterDiscount =
        priceAmountWithoutTax - coupon.discountAmount
      priceAmountWithoutTax =
        netAmountAfterDiscount > 0 ? netAmountAfterDiscount : 0
    } else if (coupon.discountType === couponTypes.percent) {
      priceAmountWithoutTax -=
        Math.round(coupon.discountAmount * priceAmountWithoutTax) / 100
    }
  }
  calculatedProductInfo.taxAmount = parseFloat(
    Number(calculatedProductTax * (priceAmountWithoutTax / 10000)).toFixed(2),
  )

  calculatedProductInfo.currency = product.currency

  return calculatedProductInfo
}

export function getProductBumpInfo(
  product: ProductInterface | null,
  isVatNotChargeable: boolean,
  productBumpVat: number,
  locale: PageLocaleEnum,
) {
  const calculatedProductBumpInfo: ProductBumpInfoInterface = {
    name: '',
    price: '',
    priceAmount: 0,
    priceWithoutTaxAndCurrency: 0,
    taxPercent: 0,
    taxAmount: 0,
    currency: CurrencyEnum.EURO,
  }

  if (!product) {
    return calculatedProductBumpInfo
  }
  const calculatedProductTax = isVatNotChargeable ? 0 : productBumpVat
  calculatedProductBumpInfo.taxPercent = calculatedProductTax

  calculatedProductBumpInfo.name = product.name

  calculatedProductBumpInfo.price = getReadablePrice(
    parseFloat(
      Number(
        product.price / 100 + calculatedProductTax * (product.price / 10000),
      ).toFixed(2),
    ),
    product.currency,
    locale,
  )
  calculatedProductBumpInfo.priceWithoutTaxAndCurrency = parseFloat(
    Number(product.price / 100).toFixed(2),
  )
  calculatedProductBumpInfo.taxAmount = parseFloat(
    Number(calculatedProductTax * (product.price / 10000)).toFixed(2),
  )
  calculatedProductBumpInfo.priceAmount = parseFloat(
    Number(
      product.price / 100 + calculatedProductTax * (product.price / 10000),
    ).toFixed(2),
  )
  calculatedProductBumpInfo.currency = product.currency

  return calculatedProductBumpInfo
}

export function getTaxAmount(price: number, tax: number) {
  return parseFloat(Number(tax * (price / 100)).toFixed(2))
}

export function getProductNetPrice(
  price: number,
  quantity: number,
  tax: number,
  coupon?: Coupon,
) {
  let priceWithoutTax = price * quantity

  if (coupon) {
    if (coupon.discountType === couponTypes.fixed) {
      const netAmountAfterDiscount = priceWithoutTax - coupon.discountAmount
      priceWithoutTax = netAmountAfterDiscount > 0 ? netAmountAfterDiscount : 0
    } else if (coupon.discountType === couponTypes.percent) {
      priceWithoutTax -=
        Math.round(coupon.discountAmount * priceWithoutTax) / 100
    }
  }

  const taxAmount = getTaxAmount(priceWithoutTax, tax)

  return centsToEuro(priceWithoutTax + taxAmount)
}

export const transformPricingDto = (
  pricingDto: CalculatedPrice,
): OfferPricing => ({
  entityId: pricingDto.entityId,
  entityType: pricingDto.entityType,
  originalAmount: pricingDto.originalAmount,
  netAmount: pricingDto.net,
  grossAmount: pricingDto.net + pricingDto.tax,
  discountAmount: pricingDto.discount,
  taxAmount: pricingDto.tax,
  taxPercent: pricingDto.taxPercent,
})
