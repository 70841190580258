import { BoxShadowInterface } from 'tools/commands/migrate/utils/migrateUtils'
import styled from 'styled-components'
import { PhoneInput } from 'common/components/core/PhoneInput'
import { CountrySelectorButtonUi } from 'common/components/core/PhoneInput/components/CountrySelector/ui/CountrySelectorUi'
import { CountrySelectorWrapperUi } from 'common/components/core/PhoneInput/components/CountrySelector/ui/CountrySelectorWrapperUi'
import { PhoneInputInputUi } from 'common/components/core/PhoneInput/components/PhoneInput/ui/PhoneInputInputUi'
import { fieldStyles } from 'common/components/entities/Field/utils'
import { AlignType, BorderType, PaddingType } from 'common/types/styleTypes'
import { FontProps } from 'common/utils/styleUtilsNew'

export interface PhoneNumberFieldUiNewProps
  extends FontProps,
    BoxShadowInterface {
  hasIcon?: boolean
  hasErrors?: boolean
  padding?: PaddingType
  mobilePadding?: PaddingType
  backgroundColor?: string
  mobileBackgroundColor?: string
  placeholderColor?: string
  mobilePlaceholderColor?: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  lineHeight?: number
  mobileLineHeight?: number
  textAlign?: AlignType
  mobileTextAlign?: AlignType
}

const PhoneNumberFieldUiNew = styled(PhoneInput)<PhoneNumberFieldUiNewProps>`
  ${CountrySelectorWrapperUi} {
    ${fieldStyles}

    display: flex;
    width: unset;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-right: none;
    padding: 0;

    ${CountrySelectorButtonUi} {
      ${fieldStyles}

      border: none;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      padding: 0;
      padding-left: 9px;
      height: unset;
    }
  }

  ${PhoneInputInputUi} {
    ${fieldStyles}

    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    height: unset;
  }
`
export default PhoneNumberFieldUiNew
