import React from 'react'
import PropTypes from 'prop-types'

const Audio = ({
  src = 'https://s3-eu-west-1.amazonaws.com/data.systeme.io/common/audio-sample.ogg',
  htmlAttrId = '',
}) => (
  <audio
    id={htmlAttrId}
    src={src}
    style={{ width: '100%', height: '32px' }}
    controlslist="nodownload"
    controls
  />
)

Audio.propTypes = {
  src: PropTypes.string,
  htmlAttrId: PropTypes.string,
  autoPlay: PropTypes.bool,
}

export default Audio
