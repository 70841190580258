import styled, { css, Interpolation } from 'styled-components'
import {
  BackgroundColorInterface,
  BackgroundImageInterface,
  BackgroundInterface,
} from 'common/types/background-interfaces'
import { BackgroundImageType } from 'common/types/common-types'
import {
  backgroundColorCss,
  getBackgroundStyles,
  getBackgroundWithImageAndFixed,
  mobileBackgroundColorCss,
} from 'common/utils/styleUtilsNew'

export interface CommonBodyBackgroundUiStylesInterface
  extends BackgroundInterface,
    BackgroundColorInterface,
    BackgroundImageInterface {
  blur?: number
  mobileMaxWidth?: string
  zIndex?: number | null
}

export interface CommonBodyBackgroundUiProps
  extends CommonBodyBackgroundUiStylesInterface {
  styles?: Interpolation<CommonBodyBackgroundUiStylesInterface>
}

const getDefaultBackgroundStylesCss = css`
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: center center;
`

const getBlur = (blur = 0) => css`
  filter: blur(${blur}px);
`

const getImage = (image: BackgroundImageType = null) =>
  image &&
  css`
    background-image: ${image};
  `

const getZIndex = (value: number | null = null) =>
  value &&
  css`
    z-index: ${value};
  `

export const CommonBodyBackgroundMobileStyles = css<CommonBodyBackgroundUiStylesInterface>`
  ${p =>
    getBackgroundWithImageAndFixed(
      p.mobileBackground ?? p.background,
      p.mobileBackgroundImage ?? p.backgroundImage,
      getDefaultBackgroundStylesCss,
    )}
  ${p => getBackgroundStyles(p.mobileBackground ?? p.background)};
  ${mobileBackgroundColorCss}
  ${p => getImage(p.mobileBackgroundImage)};
`

const CommonBodyBackgroundStyles = css<CommonBodyBackgroundUiStylesInterface>`
  ${p => getBackgroundStyles(p.background)};
  ${backgroundColorCss}
  ${p => getImage(p?.backgroundImage)};
`

export const CommonBodyBackgroundUi = styled.div<CommonBodyBackgroundUiProps>`
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  ${p => getZIndex(p.zIndex)}
  ${p => getBlur(p.blur ?? 0)}

  ${CommonBodyBackgroundStyles}

  ${p => p.styles}
`
