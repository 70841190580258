import styled, { css } from 'styled-components'
import { widthValues } from 'common/constants/settings'
import {
  backgroundColorCss,
  mobileBackgroundColorCss,
} from 'common/utils/styleUtilsNew'

const SectionContainerOldUi = styled.div`
  max-width: ${({ width }) => widthValues[width]};
  width: 100%;
  margin: auto;
  ${backgroundColorCss}
  ${p => p.theme.phone} {
    ${mobileBackgroundColorCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBackgroundColorCss}
    `}
`

export default SectionContainerOldUi
