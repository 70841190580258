import React, { MouseEvent } from 'react'
import FaqItemArrowUi from 'common/components/entities/Faq/ui/FaqItemArrowUi'
import FaqItemContentUi from 'common/components/entities/Faq/ui/FaqItemContentUi'
import FaqItemTitleUi from 'common/components/entities/Faq/ui/FaqItemTitleUi'
import FaqItemUi from 'common/components/entities/Faq/ui/FaqItemUi'
import { PaddingType } from 'common/types/styleTypes'

type FaqItemProps = {
  backgroundColor: string
  mobileBackgroundColor?: string
  fontSize: number
  mobileFontSize: number
  itemBorderRadius: number
  titleColor: string
  mobileTitleColor?: string
  title: string
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  expanded: boolean
  itemPadding: PaddingType
  mobileItemPadding: PaddingType
  children: React.ReactElement
  toggleCollapse?: (e: MouseEvent<HTMLDivElement>) => void
  iconClassName: string
  closedIconClassName?: string
  iconColor: string
  mobileIconColor?: string
  boxShadow?: string
  mobileBoxShadow?: string
  showIcons: boolean
  mobileShowIcons: boolean
}

const FaqItem = ({
  backgroundColor,
  mobileBackgroundColor,
  fontSize,
  mobileFontSize,
  titleColor,
  mobileTitleColor,
  title,
  fontFamily,
  fontStyle,
  fontWeight,
  expanded,
  children,
  itemPadding,
  mobileItemPadding,
  itemBorderRadius,
  toggleCollapse,
  iconClassName,
  closedIconClassName,
  iconColor,
  mobileIconColor,
  boxShadow,
  mobileBoxShadow,
  showIcons = true,
  mobileShowIcons = true,
}: FaqItemProps) => {
  const arrowClassName = expanded
    ? iconClassName
    : closedIconClassName || iconClassName

  return (
    <FaqItemUi
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      padding={itemPadding}
      mobilePadding={mobileItemPadding}
      borderRadius={itemBorderRadius}
      onClick={e => toggleCollapse && toggleCollapse(e)}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
    >
      <FaqItemTitleUi
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        color={titleColor}
        mobileColor={mobileTitleColor}
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        expanded={expanded}
      >
        {title}
        <FaqItemArrowUi
          showIcons={showIcons}
          mobileShowIcons={mobileShowIcons}
          expanded={expanded}
          hasClosedIcon={!!closedIconClassName}
          iconColor={iconColor}
          mobileIconColor={mobileIconColor}
          className={arrowClassName}
        />
      </FaqItemTitleUi>
      <FaqItemContentUi expanded={expanded} onClick={e => e.stopPropagation()}>
        {children}
      </FaqItemContentUi>
    </FaqItemUi>
  )
}

export default FaqItem
