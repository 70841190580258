import React from 'react'
import CommonText, {
  calculateHeadlineFontFamily,
} from 'common/components/entities/TextNew/ui/TextUi'
import { TextInterface } from 'common/types/entities/TextInterface'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import { useDelay } from '../../hooks/useDelay'
import { usePopupOpenerRef } from '../../hooks/usePopupOpenerRef'

function Headline({ entity }: { entity: TextInterface }) {
  const ref = usePopupOpenerRef()
  const isVisible = useDelay(entity.delay)
  const linkColor = usePage(pageSelectors.getGlobalLinkColor)
  const headingColor = usePage(pageSelectors.getGlobalHeadingColor)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const mobileGlobalFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalTextLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileTextLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const fontProperties = usePage(pageSelectors.getGlobalFontProperties)
  const globalTextAlign = usePage(pageSelectors.getGlobalHeadingAlign)
  const globalMobileTextAlign = usePage(
    pageSelectors.getGlobalMobileHeadingAlign,
  )

  const fontFamily = calculateHeadlineFontFamily(
    entity.fontFamily,
    fontProperties.headingFontFamily,
    fontProperties.fontFamily,
  )

  const mobileFontFamily = calculateHeadlineFontFamily(
    entity.mobileFontFamily || entity.fontFamily,
    fontProperties.headingFontFamily,
    fontProperties.fontFamily,
  )

  return isVisible ? (
    <StyleWrapper
      appearance={entity.appearance}
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
    >
      <CommonText
        id={entity.htmlAttrId}
        linkColor={linkColor}
        fontSize={entity.fontSize || globalFontSize}
        mobileFontSize={entity.mobileFontSize || mobileGlobalFontSize}
        lineHeight={entity.lineHeight || globalTextLineHeight}
        mobileLineHeight={entity.mobileLineHeight || globalMobileTextLineHeight}
        color={headingColor}
        fontFamily={fontFamily}
        mobileFontFamily={mobileFontFamily}
        fontStyle={
          entity.fontStyle ||
          fontProperties.headingFontStyle ||
          fontProperties.fontStyle
        }
        mobileFontStyle={entity.mobileFontStyle}
        fontWeight={
          entity.fontWeight ||
          fontProperties.headingFontWeight ||
          fontProperties.fontWeight
        }
        mobileFontWeight={entity.mobileFontWeight}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        textAlign={entity.textAlign || globalTextAlign}
        mobileTextAlign={entity.mobileTextAlign || globalMobileTextAlign}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        letterSpacing={entity.letterSpacing}
        mobileLetterSpacing={entity.mobileLetterSpacing}
        dangerouslySetInnerHTML={{ __html: entity.html || '' }}
        ref={ref}
        enableHeadings
      />
    </StyleWrapper>
  ) : (
    <div />
  )
}

export default Headline
