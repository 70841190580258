import couponTypes from 'common/components/entities/Coupon/couponTypes'
import { Coupon } from 'common/types/CouponType'
import { PricePlan } from '../types/OfferInterface'
import { SelectedShipping } from '../types/entities/ShippingFeesInterface'
import { getTaxAmount } from './productUtils'

type CurrencyDisplayOptions = 'code' | 'symbol' | 'narrowSymbol' | 'name'

export const getNetPrice = (
  amount: number,
  quantity: number,
  coupon?: Coupon,
) => {
  let price = amount * quantity

  if (coupon) {
    if (coupon.discountType === couponTypes.fixed) {
      const netAmountAfterDiscount = price - coupon.discountAmount
      price = netAmountAfterDiscount > 0 ? netAmountAfterDiscount : 0
    } else if (coupon.discountType === couponTypes.percent) {
      price -= Math.round(coupon.discountAmount * price) / 100
    }
  }
  return price
}

export const getReadablePrice = (
  amount: number,
  currency: string,
  language: string,
  currencyDisplay: CurrencyDisplayOptions = 'narrowSymbol',
): string => {
  try {
    return new Intl.NumberFormat(language, {
      style: 'currency',
      currency,
      currencyDisplay,
    }).format(amount)
  } catch (e) {
    if (e instanceof RangeError && currencyDisplay !== 'symbol') {
      return getReadablePrice(amount, currency, language, 'symbol')
    }

    throw e
  }
}

export function getPricePlanAmount(plan: PricePlan) {
  return plan && 'subscriptionPlan' in plan
    ? plan.subscriptionPlan.amount
    : plan && plan.directChargeAmount
}

export function centsToEuro(cents: number) {
  return Number((Math.round(cents * 100) / 100 / 100).toFixed(2))
}

export function getPriceWithCoupon(
  price: number,
  coupon?: Coupon,
  quantity = 1,
  tax = 0,
) {
  const priceWithoutTax = getNetPrice(price, quantity, coupon)

  let taxAmount = 0
  if (tax) {
    taxAmount = getTaxAmount(priceWithoutTax, tax)
  }

  return (priceWithoutTax + taxAmount) / 100
}

export function calculateShippingTotal(
  shipping: SelectedShipping,
  taxPercent: number,
) {
  const shippingTax = shipping ? (shipping.price * taxPercent) / 100 : 0
  return shippingTax + (shipping?.price ?? 0)
}
