import PropTypes from 'prop-types'
import React from 'react'
import RawHtmlUi from './ui/RawHtmlUi'

const RawHtml = ({ html, htmlAttrId, styles = {} }) => (
  <RawHtmlUi
    styles={styles}
    attrId={htmlAttrId}
    dangerouslySetInnerHTML={{ __html: html }}
  />
)

RawHtml.propTypes = {
  htmlAttrId: PropTypes.string,
  html: PropTypes.string.isRequired,
  forwardedRef: PropTypes.func,
  styles: PropTypes.objectOf(PropTypes.string),
}

export default RawHtml
