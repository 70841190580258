import { useDispatch } from 'react-redux'
import { changeBookingField } from 'publisher/actions/optInActions'
import { optInSelectors, useTypedSelector } from 'publisher/store'
import { BookingFieldsSlug } from 'publisher/store/optIn/optInStateInterface'

export const useCalendarSelectors = () => {
  const dispatch = useDispatch()

  const selectedDate = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'date'),
  )
  const selectedTimeSlot = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'timeSlot'),
  )
  const selectedTimeZone = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'timeZone'),
  )
  const selectedLocationId = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'eventLocationId'),
  )

  const handleChangeField = <T>(slug: BookingFieldsSlug, value: T) => {
    dispatch(changeBookingField(slug, value))
  }

  return {
    selectedDate,
    selectedTimeSlot,
    selectedTimeZone,
    selectedLocationId,
    handleChangeField,
  }
}
