import styled from 'styled-components'

interface WebinarRegistrationSelectUiProps {
  'data-test-id'?: string
}

const WebinarRegistrationSelectUi = styled.select.attrs<WebinarRegistrationSelectUiProps>(
  ({ 'data-test-id': testId }) => ({
    'data-test-id': testId,
  }),
)<WebinarRegistrationSelectUiProps>`
  height: 42px;
  font-size: 16px;
  text-indent: 10px;
  outline: none;
  border: 1px solid #b0baca;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
`

export default WebinarRegistrationSelectUi
