import React from 'react'
import { BackgroundImageType } from 'common/types/common-types'
import {
  CommonBodyBackgroundUi,
  CommonBodyBackgroundUiProps,
} from './ui/body-background-ui'
import { CommonBodyUi } from './ui/body-ui'

interface CommonBodyProps extends CommonBodyBackgroundUiProps {
  children?: React.ReactNode
  isClient?: boolean
  backgroundImage?: BackgroundImageType
  mobileBackgroundImage?: BackgroundImageType
  zIndex?: number | null
}

export const CommonBody = ({ children, ...styles }: CommonBodyProps) => {
  return (
    <CommonBodyUi>
      <CommonBodyBackgroundUi {...styles} />
      {children}
    </CommonBodyUi>
  )
}
