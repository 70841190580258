import React from 'react'

export const HourglassIcon = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_192011_12324)">
        <path
          d="M8.73229 5.70833H16.275C15.5187 7.69896 13.8156 9.22812 12.5156 10.1604C11.2094 9.22083 9.49688 7.6875 8.73229 5.70833ZM21.875 22.375V25.5H3.125V22.375C3.125 18.2021 5.45833 15.0698 7.75208 13C5.45833 10.9302 3.125 7.79792 3.125 3.625C3.125 1.90208 4.52708 0.5 6.25 0.5H18.75C20.4729 0.5 21.875 1.90208 21.875 3.625C21.875 7.79688 19.551 10.9292 17.2656 13C19.55 15.0708 21.875 18.2031 21.875 22.375ZM18.6562 4.82812L18.8458 3.625H6.15313L6.34687 4.83125C6.9375 8.51875 10.1073 11.0906 11.9437 12.2948L12.5135 12.6687L13.0844 12.2958C14.9146 11.1021 18.0708 8.54167 18.6562 4.82812Z"
          fill="#64748B"
        />
      </g>
      <defs>
        <clipPath id="clip0_192011_12324">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
