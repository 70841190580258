import styled, { CSSProp } from 'styled-components'
import { DropDownDialCodeUi } from './DropDownDialCodeUi'

export const DropDownItemUi = styled.li<{
  $isFocused?: boolean
  $isSelected?: boolean
  $styles?: CSSProp
}>`
  display: flex;
  min-height: 2em; // min-height (instead of just height) for safari compatibility
  box-sizing: border-box;
  align-items: center;
  padding: 0.14em 0.6em;

  &:hover {
    background-color: white;
    cursor: pointer;
  }

  ${({ $isSelected, $isFocused }) =>
    ($isSelected || $isFocused) &&
    `background-color: white;
    color: #222;
    ${DropDownDialCodeUi}{
      color: gray;
    }
  `}
  ${({ $isFocused }) => $isFocused && 'color: gray;'}

  ${({ $styles }) => $styles}
`
