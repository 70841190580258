import styled, { css } from 'styled-components'

export const CommonBodyUi = styled.div`
  position: relative;
  width: 100%;

  // keeps editor controls inside the viewport
  // in case if some elements are wider than screen size
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      overflow: hidden;
    `}
`
