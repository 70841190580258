import styled, { css } from 'styled-components'
import { Background } from 'common/types/BackgroundType'
import {
  backgroundColorCss,
  backgroundCss,
  backgroundImageFixForIOS,
  mobileBackgroundColorCss,
  mobileBackgroundCss,
} from 'common/utils/styleUtilsNew'

export type CommonInlineBodyBackgroundUiProps = {
  blur?: number
  background?: Background
  backgroundImage?: string | null
  mobileBackgroundImage?: string | null
  backgroundColor?: string
  mobileBackgroundColor?: string
  mobileBackground?: Background
}
export const CommonInlineBodyBackgroundUi = styled.section<CommonInlineBodyBackgroundUiProps>`
  margin: auto;
  width: 100%;
  height: inherit;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${backgroundCss}

  ${p =>
    p.backgroundImage &&
    css`
      background-image: ${p.backgroundImage};
    `};
  ${backgroundColorCss}

  ${({ blur = 0 }) => css`
    filter: blur(${blur}px);
  `}

  ${p => p.theme.phone} {
    ${mobileBackgroundCss}
    ${p =>
      p.mobileBackgroundImage &&
      css`
        background-image: ${p.mobileBackgroundImage};
      `};
    ${mobileBackgroundColorCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      background-image: ${p.mobileBackgroundImage || 'none'};

      ${mobileBackgroundCss}
      ${mobileBackgroundColorCss}
    `}
  ${p =>
    backgroundImageFixForIOS(
      !!(p.mobileBackgroundImage || p.backgroundImage),
      (p.mobileBackground?.backgroundAttachment ||
        p.background?.backgroundAttachment) ??
        '',
    )}
`
