import styled, { css } from 'styled-components'

const ColumnOldUi = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: ${p => p.gridColumnStart};
  grid-column-end: ${p => p.gridColumnStart + p.size};
  ${p =>
    !p.disableStacking &&
    css`
      ${p => p.theme.phone} {
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 12;
        padding-left: 0;
        padding-right: 0;
      }
    `}
  /* compensate body video /common/components/entities/BodyOld/ui/BodyUi.js */
  & video {
    object-fit: contain !important;
  }
  ${({ styles = {} }) => styles}
`

export default ColumnOldUi
