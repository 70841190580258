import PropTypes from 'prop-types'
import React from 'react'
import { wrapFontFamilyByStyles } from 'common/components/entities/Text/utils'
import { defaultTextFontSize } from 'common/constants/settings'
import TextUi from './ui/TextUi'

const Text = ({
  htmlAttrId,
  styles,
  mobileStyles,
  mobileListItemIconColor,
  textColor,
  flexBasis,
  verticalSpacing,
  mobileVerticalSpacing,
  listItemIconColor = '',
  listItemIconSize = defaultTextFontSize,
  linkColor = null,
  fontSize = null,
  mobileFontSize = null,
  lineHeight = null,
  mobileLineHeight = null,
  listItemClassName = '',
  children,
}) => {
  return (
    <TextUi
      attrId={htmlAttrId}
      styles={wrapFontFamilyByStyles(styles)}
      mobileStyles={wrapFontFamilyByStyles(mobileStyles)}
      listItemIconColor={listItemIconColor}
      mobileListItemIconColor={mobileListItemIconColor}
      listItemIconSize={listItemIconSize}
      linkColor={linkColor}
      fontSize={fontSize}
      mobileFontSize={mobileFontSize}
      lineHeight={lineHeight}
      mobileLineHeight={mobileLineHeight}
      textColor={textColor}
      flexBasis={flexBasis}
      listItemClassName={listItemClassName}
      verticalSpacing={verticalSpacing}
      mobileVerticalSpacing={mobileVerticalSpacing}
    >
      {children}
    </TextUi>
  )
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileStyles: PropTypes.any,
  listItemIconColor: PropTypes.string,
  mobileListItemIconColor: PropTypes.string,
  listItemIconSize: PropTypes.string,
  linkColor: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.string,
  mobileFontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  mobileLineHeight: PropTypes.string,
  htmlAttrId: PropTypes.string,
  flexBasis: PropTypes.string,
  listItemClassName: PropTypes.string,
  iconList: PropTypes.objectOf(PropTypes.string),
}

export default Text
