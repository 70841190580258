import React from 'react'
import ButtonLoader from 'common/components/core/ButtonLoader'
import ButtonContentUi from 'common/components/entities/Button/ui/button-content-ui'
import ButtonIconUi from 'common/components/entities/Button/ui/button-icon-ui'
import ButtonUi from 'common/components/entities/Button/ui/button-ui'
import SubTextUiNew from 'common/components/entities/Button/ui/subtext-ui'
import { CommonButtonProps } from 'common/types/entities/button-interface'

export interface ButtonProps extends CommonButtonProps {
  attrId: string
  dataTestId?: string
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  onClick?: (e: React.SyntheticEvent) => void
}

const Button = ({
  disabled = false,
  loading = false,
  loadingText,
  attrId,
  dataTestId,
  text,
  subText,
  padding,
  mobilePadding,
  alignSelf,
  mobileAlignSelf,
  width,
  mobileWidth,
  textFontSize,
  mobileTextFontSize,
  subTextFontSize,
  mobileSubTextFontSize,
  textFontFamily,
  textFontWeight,
  textFontStyle,
  subTextFontFamily,
  subTextFontWeight,
  subTextFontStyle,
  mobileTextFontFamily,
  mobileTextFontWeight,
  mobileTextFontStyle,
  mobileSubTextFontFamily,
  mobileSubTextFontWeight,
  mobileSubTextFontStyle,
  textColor,
  subTextColor,
  mobileTextColor,
  mobileSubTextColor,
  background,
  mobileBackground,
  backgroundColor,
  mobileBackgroundColor,
  iconClassNameBefore,
  iconClassNameAfter,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
  onClick,
  lineHeight,
  mobileLineHeight,
  hover,
  mobileHover,
}: ButtonProps) => {
  return (
    <ButtonUi
      data-test-id={dataTestId}
      id={attrId}
      border={border}
      mobileBorder={mobileBorder}
      color={textColor}
      fontFamily={textFontFamily}
      fontWeight={textFontWeight}
      fontStyle={textFontStyle}
      fontSize={textFontSize}
      mobileFontSize={mobileTextFontSize}
      mobileColor={mobileTextColor}
      mobileFontFamily={mobileTextFontFamily}
      mobileFontWeight={mobileTextFontWeight}
      mobileFontStyle={mobileTextFontStyle}
      background={background ?? backgroundColor}
      mobileBackground={mobileBackground ?? mobileBackgroundColor}
      padding={padding}
      mobilePadding={mobilePadding}
      onClick={onClick}
      width={width}
      mobileWidth={mobileWidth}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      lineHeight={lineHeight}
      mobileLineHeight={mobileLineHeight}
      disabled={disabled}
      hover={hover}
      mobileHover={mobileHover}
    >
      {loading ? (
        <ButtonContentUi>
          {iconClassNameBefore && (
            <ButtonIconUi className={iconClassNameBefore} placed={'left'} />
          )}
          {loading && <ButtonLoader />}
          {loadingText}
          {iconClassNameAfter && (
            <ButtonIconUi className={iconClassNameAfter} placed={'right'} />
          )}
        </ButtonContentUi>
      ) : (
        <>
          <ButtonContentUi>
            {iconClassNameBefore && (
              <ButtonIconUi className={iconClassNameBefore} placed={'left'} />
            )}
            {text}
            {iconClassNameAfter && (
              <ButtonIconUi className={iconClassNameAfter} placed={'right'} />
            )}
          </ButtonContentUi>
          {subText && (
            <SubTextUiNew
              color={subTextColor}
              fontFamily={subTextFontFamily}
              fontWeight={subTextFontWeight}
              fontStyle={subTextFontStyle}
              fontSize={subTextFontSize}
              mobileFontSize={mobileSubTextFontSize}
              mobileColor={mobileSubTextColor}
              mobileFontFamily={mobileSubTextFontFamily}
              mobileFontWeight={mobileSubTextFontWeight}
              mobileFontStyle={mobileSubTextFontStyle}
            >
              {subText}
            </SubTextUiNew>
          )}
        </>
      )}
    </ButtonUi>
  )
}

export default Button
