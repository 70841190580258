import { getLanguageCodeByLocale } from 'common/enums/LanguageCodeEnum'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { CurrencyDisplayOptions } from 'common/types/currency-format'

export const currencyFormat = (
  amount: number,
  currency: string,
  locale?: PageLocaleEnum,
  noDecimals?: boolean,
  currencyDisplay: CurrencyDisplayOptions = 'symbol',
) =>
  Intl.NumberFormat(getLanguageCodeByLocale(locale ?? PageLocaleEnum.ENGLISH), {
    style: 'currency',
    currency,
    currencyDisplay,
    maximumFractionDigits: noDecimals ? 0 : undefined,
    minimumFractionDigits: noDecimals ? 0 : 2, // we need 2 fractional digits for tests
  }).format(amount)
