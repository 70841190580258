import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as exceptions from 'common/errors'
import * as api from 'publisher/api/contactUsApi'
import useManagement from 'publisher/hooks/useManagement'
import { useShowContactUsSuccess } from 'publisher/pages/contact-us/components/ContactUs'
import {
  usePage,
  typedPageSelectors,
  managementSelectors,
} from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import { validateEmptyOptInFields } from 'publisher/utils/fieldValidation'

function useContactUsSubmit() {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const { showSuccess, setIsDisabled, isCaptcha, setErrors, errors, fields } =
    useShowContactUsSuccess()

  const isDesktop = useManagement(managementSelectors.isDesktop)
  const mandatoryCheckboxes = usePage(p =>
    pageSelectors.getVisibleMandatoryCheckboxes(p, isDesktop),
  )
  const confirmedCheckboxesIds = useManagement(
    managementSelectors.getConfirmedCheckboxesIds,
  )

  const locale = usePage(typedPageSelectors.getLocale)

  const textFields: Record<string, { value: string }> = {
    email: { value: fields.email },
    message: { value: fields.message },
    first_name: { value: fields.first_name },
    subject: { value: fields.subject },
  }
  const captchaOpenKey = useManagement(managementSelectors.getCaptcha)

  if (captchaOpenKey) {
    textFields.recaptcha = { value: fields.recaptcha }
  }

  useEffect(() => {
    if (isCaptcha) {
      setIsDisabled(true)
    }
  }, [])

  const submit = async () => {
    const emptyFieldErrors = validateEmptyOptInFields(textFields, t)
    const checkboxesErrors = mandatoryCheckboxes.reduce((errors, checkbox) => {
      return confirmedCheckboxesIds.includes(checkbox.id)
        ? errors
        : [
            ...errors,
            'options' in checkbox
              ? checkbox.options.errorText
              : checkbox.errorText,
          ]
    }, [] as string[])

    if (Object.keys(emptyFieldErrors).length || checkboxesErrors.length > 0) {
      const common: string[] = []
      setErrors({
        common,
        fields: emptyFieldErrors,
        checkbox: checkboxesErrors,
      })
      return
    }

    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('contact_us_request[email]', fields.email)
      formData.append('contact_us_request[name]', fields.first_name)
      formData.append('contact_us_request[message]', fields.message)
      formData.append('contact_us_request[subject]', fields.subject)
      if (fields.recaptcha) {
        formData.append('contact_us_request[captcha]', fields.recaptcha)
      }
      if (fields.attachments) {
        formData.append(
          'contact_us_request[attachments][0][file][file]',
          fields.attachments,
        )
      }
      await api.send(formData, locale)
      showSuccess()
    } catch (error) {
      if (error instanceof exceptions.BadRequest) {
        const { common = [], fields = {} } = error.response.data.errors
        window.dispatchEvent(new Event('form_updated'))
        setErrors({ common, fields })
      } else if (error instanceof exceptions.NetworkError) {
        setErrors({ common: [t('core.errors.no_connection')], fields: {} })
      } else {
        ;(window as any).Rollbar.error(`Custom login error`, error)
      }
    }
    setLoading(false)
  }

  return { isLoading, errors, submit }
}

export default useContactUsSubmit
