import React from 'react'

export const IconUnavailable = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1249 19.9998C4.61094 19.9998 0.125 15.5139 0.125 9.9999C0.125 4.48594 4.61094 0 10.1249 0C15.6389 0 20.1248 4.48594 20.1248 9.9999C20.1248 15.5139 15.6389 19.9998 10.1249 19.9998ZM7.23351 5.44049C7.1538 5.44049 7.0789 5.4715 7.0226 5.5278L5.6519 6.8985C5.56939 6.98101 5.54289 7.10466 5.5844 7.2135C5.59839 7.25283 5.62109 7.28886 5.6519 7.3206L8.5526 10.2204L5.6519 13.1211C5.62221 13.1508 5.59887 13.1875 5.5844 13.2273C5.54298 13.3359 5.56948 13.4599 5.6519 13.5432L7.0226 14.913C7.0789 14.9693 7.1538 15.0003 7.23351 15.0003C7.26954 15.0003 7.30486 14.9939 7.3385 14.9814C7.37739 14.9673 7.41312 14.9445 7.4447 14.9139L10.3454 12.0132L13.2452 14.913C13.2765 14.9442 13.3125 14.9672 13.3523 14.9814C13.3863 14.9939 13.4218 15.0003 13.4576 15.0003C13.5374 15.0003 13.6119 14.9693 13.6673 14.913L15.038 13.5432C15.1204 13.4608 15.1472 13.3368 15.1064 13.2273C15.0908 13.187 15.0672 13.1503 15.038 13.1211L12.1373 10.2204L15.038 7.3206C15.0683 7.28934 15.0914 7.2533 15.1064 7.2135C15.1472 7.10399 15.1204 6.97999 15.038 6.8976L13.6673 5.5278C13.611 5.4715 13.5362 5.44049 13.4568 5.44049C13.4208 5.44049 13.3853 5.44685 13.3514 5.4594C13.312 5.47461 13.2763 5.49763 13.2452 5.5278L10.3454 8.4285L7.4447 5.5278C7.41362 5.49763 7.37788 5.47461 7.3385 5.4594C7.30488 5.44685 7.26955 5.44049 7.23351 5.44049Z"
      fill="#142D63"
      fillOpacity="0.3"
    />
  </svg>
)
