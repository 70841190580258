import React from 'react'

export const CheckIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_192011_12174)">
        <path
          d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
          fill="#00BA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.3878 74.3845L16.1012 54.8125C13.5976 52.1329 13.7413 47.8936 16.4209 45.39C19.1004 42.8865 23.3398 43.0306 25.8433 45.7097L39.9488 60.8066L62.276 39.9455C62.4768 39.7577 62.6866 39.5857 62.9035 39.4274L73.4031 29.6176C76.0827 27.114 80.3224 27.2581 82.8256 29.9377C85.3291 32.6168 85.185 36.8566 82.5059 39.3601L50.4374 69.3227L50.4031 69.2861L39.3102 79.6507L34.3878 74.3845Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_192011_12174">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
