import React from 'react'
import { useDispatch } from 'react-redux'
import { PhoneInputProps } from 'common/components/core/PhoneInput'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import FieldIconUiNew from 'common/components/entities/Field/field-icon-ui'
import PhoneNumberFieldUiNew from 'common/components/entities/Field/phone-number-field-ui'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { getLanguageCodeByLocale } from 'common/enums/LanguageCodeEnum'
import { FieldInterface } from 'common/types/entities/field-interface'
import { changeField } from 'publisher/actions/optInActions'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useFieldsStorage } from 'publisher/components/entities/Field/hooks/use-fields-storage'
import usePage, { selectors } from 'publisher/hooks/usePage'
import { useOptIn } from 'publisher/store'
import {
  getCustomerCountry,
  getFieldErrorsBySlug,
  getFieldValueBySlug,
} from 'publisher/store/optIn/optInSelectors'

export interface PhoneNumberFieldProps {
  entity: FieldInterface
}

const PhoneNumberField = ({ entity }: PhoneNumberFieldProps) => {
  const dispatch = useDispatch()
  const errors = useOptIn(optIn =>
    getFieldErrorsBySlug(optIn, FieldSlugEnum.PhoneNumber),
  )
  const locale = usePage(selectors.getLocale)

  const phoneNumber = useOptIn(optIn =>
    getFieldValueBySlug(optIn, FieldSlugEnum.PhoneNumber),
  )
  const customerCountry = useOptIn(getCustomerCountry) || undefined

  const handlePhoneNumber: PhoneInputProps['onChange'] = ({
    unformatedPhone,
  }) => {
    dispatch(changeField(entity.slug, unformatedPhone))
  }

  const { isReady } = useFieldsStorage(entity.slug ?? '')

  return (
    <StyleWrapper
      id={entity.htmlAttrId}
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      {typeof window !== 'undefined' && isReady && (
        <FieldWrapperUi>
          {entity.iconClassName && (
            <FieldIconUiNew
              color={entity.color}
              mobileColor={entity.mobileColor}
              fontSize={entity.fontSize}
              mobileFontSize={entity?.mobileFontSize}
              className={entity.iconClassName}
            />
          )}
          <PhoneNumberFieldUiNew
            initialCountry={customerCountry?.toLowerCase()}
            value={phoneNumber || ''}
            onChange={handlePhoneNumber}
            hasIcon={!!entity.iconClassName}
            hasErrors={!!errors.length}
            textAlign={entity.textAlign}
            mobileTextAlign={entity.mobileTextAlign}
            padding={entity.padding}
            mobilePadding={entity.mobilePadding}
            border={entity.border}
            mobileBorder={entity.mobileBorder}
            backgroundColor={entity.backgroundColor}
            mobileBackgroundColor={entity.mobileBackgroundColor}
            color={entity.color}
            mobileColor={entity.mobileColor}
            placeholder={entity.placeholder}
            placeholderColor={entity.placeholderColor}
            mobilePlaceholderColor={entity.mobilePlaceholderColor}
            fontSize={entity.fontSize}
            mobileFontSize={entity.mobileFontSize}
            boxShadow={entity.boxShadow}
            mobileBoxShadow={entity.mobileBoxShadow}
            countrySelectorStyleProps={{
              dropdownStyleProps: {
                style: { fontSize: entity.fontSize },
                searchInputStyle: { fontSize: entity.fontSize },
              },
            }}
            countrySelectorProps={{
              language: getLanguageCodeByLocale(locale),
            }}
          />
        </FieldWrapperUi>
      )}
      <FieldErrors errors={errors} />
    </StyleWrapper>
  )
}

export default PhoneNumberField
