import styled from 'styled-components'

const StepFooterUi = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  color: #4c5b5c;
`

export default StepFooterUi
