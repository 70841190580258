import styled, { css } from 'styled-components'
import { AlignType, BorderType, PaddingType } from 'common/types/styleTypes'
import {
  borderCss,
  mobileBorderCss,
  backgroundColorCss,
  mobileBackgroundColorCss,
  fontCss,
  mobileFontCss,
  boxShadowCss,
  mobileBoxShadowCss,
  paddingsCss,
  mobilePaddingsCss,
  FontProps,
  placeholderColorCss,
  mobilePlaceholderColorCss,
  lineHeightCss,
  mobileLineHeightCss,
  textAlignCss,
  mobileTextAlignCss,
} from 'common/utils/styleUtilsNew'

type FieldUiProps = {
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  padding: PaddingType
  mobilePadding?: PaddingType
  backgroundColor?: string
  mobileBackgroundColor?: string
  boxShadow?: string
  mobileBoxShadow?: string
  hasIcon?: boolean
  hasErrors?: boolean
  placeholderColor?: string
  mobilePlaceholderColor?: string
  lineHeight?: number
  mobileLineHeight?: number
  textAlign?: AlignType
  mobileTextAlign?: AlignType
} & FontProps

const FieldUi = styled.input<FieldUiProps>`
  width: 100%;
  font-size: 16px;
  padding: 12px 0;
  outline: none;
  text-indent: 10px;
  text-align: left;
  appearance: none;
  :disabled {
    opacity: 0.4;
  }
  ${fontCss}
  ${paddingsCss}
  ${boxShadowCss}
  ${borderCss}
  ${backgroundColorCss}
  ${placeholderColorCss}
  ${lineHeightCss}
  ${textAlignCss}
  
  ${p => p.hasIcon && p.fontSize && `padding-right: ${p.fontSize + 10}px;`}
  ${p => p.hasErrors && `border-color: red;`}

  ${p => p.theme.phone} {
    ${mobileFontCss}
    ${mobilePaddingsCss}
    ${mobileBoxShadowCss}
    ${mobileBorderCss}
    ${mobileBackgroundColorCss}
    ${mobilePlaceholderColorCss}
    ${mobileLineHeightCss}
    ${mobileTextAlignCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
      ${mobilePaddingsCss}
      ${mobileBoxShadowCss}
      ${mobileBorderCss}
      ${mobileBackgroundColorCss}
      ${mobilePlaceholderColorCss}
      ${mobileLineHeightCss}
      ${mobileTextAlignCss}
    `}

  ${p =>
    p.theme.isEditor &&
    css`
      pointer-events: none;
    `}
`
export default FieldUi
