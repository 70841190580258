import PropTypes from 'prop-types'
import styled from 'styled-components'


const TwoStepPaymentFormContentUi = styled.div`
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

TwoStepPaymentFormContentUi.propTypes = {
  styles: PropTypes.shape({
    padding: PropTypes.string.isRequired,
  }).isRequired,
  mobileStyles: PropTypes.shape({
    padding: PropTypes.string,
  }),
}

export default TwoStepPaymentFormContentUi
