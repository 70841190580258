import React from 'react'

export const UserIcon = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_192011_12314)">
        <path
          d="M12.5 13C15.9518 13 18.75 10.2018 18.75 6.75C18.75 3.29822 15.9518 0.5 12.5 0.5C9.04822 0.5 6.25 3.29822 6.25 6.75C6.25 10.2018 9.04822 13 12.5 13Z"
          fill="#64748B"
        />
        <path
          d="M12.5 15.084C7.32471 15.0897 3.13076 19.2837 3.125 24.459C3.125 25.0343 3.59136 25.5006 4.16665 25.5006H20.8333C21.4086 25.5006 21.875 25.0343 21.875 24.459C21.8692 19.2837 17.6753 15.0897 12.5 15.084Z"
          fill="#64748B"
        />
      </g>
      <defs>
        <clipPath id="clip0_192011_12314">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
