import React from 'react'

export const IconCourses = () => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.7188 28.4375C31.7188 28.7276 31.6035 29.0058 31.3984 29.2109C31.1933 29.416 30.9151 29.5312 30.625 29.5312H4.375C4.08492 29.5312 3.80672 29.416 3.6016 29.2109C3.39648 29.0058 3.28125 28.7276 3.28125 28.4375C3.28125 28.1474 3.39648 27.8692 3.6016 27.6641C3.80672 27.459 4.08492 27.3438 4.375 27.3438H30.625C30.9151 27.3438 31.1933 27.459 31.3984 27.6641C31.6035 27.8692 31.7188 28.1474 31.7188 28.4375ZM31.7188 7.65625V22.9688C31.7188 23.5489 31.4883 24.1053 31.078 24.5155C30.6678 24.9258 30.1114 25.1562 29.5312 25.1562H5.46875C4.88859 25.1562 4.33219 24.9258 3.92195 24.5155C3.51172 24.1053 3.28125 23.5489 3.28125 22.9688V7.65625C3.28125 7.07609 3.51172 6.51969 3.92195 6.10945C4.33219 5.69922 4.88859 5.46875 5.46875 5.46875H29.5312C30.1114 5.46875 30.6678 5.69922 31.078 6.10945C31.4883 6.51969 31.7188 7.07609 31.7188 7.65625ZM22.4219 15.3125C22.4218 15.1368 22.3794 14.9636 22.2983 14.8077C22.2171 14.6519 22.0996 14.5178 21.9557 14.417L16.4869 10.5889C16.323 10.4741 16.1308 10.4064 15.9312 10.3933C15.7315 10.3802 15.5321 10.422 15.3546 10.5144C15.1771 10.6067 15.0283 10.746 14.9245 10.917C14.8206 11.0881 14.7657 11.2843 14.7656 11.4844V19.1406C14.7657 19.3407 14.8206 19.5369 14.9245 19.708C15.0283 19.879 15.1771 20.0183 15.3546 20.1106C15.5321 20.203 15.7315 20.2448 15.9312 20.2317C16.1308 20.2186 16.323 20.1509 16.4869 20.0361L21.9557 16.208C22.0996 16.1072 22.2171 15.9731 22.2983 15.8173C22.3794 15.6614 22.4218 15.4882 22.4219 15.3125Z"
      fill="#142D63"
    />
  </svg>
)
