import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { PageTypeEnum } from '../enums/PageTypeEnum'

export const pageTypes = {
  inline: 'inline',
  popup: 'popup',
  squeeze: 'squeeze',
  salesPage: 'sales_page',
  lecture: 'lecture',
  webinarSession: 'webinar_session',
  webinarRegistration: 'webinar_registration',
  webinarThankYou: 'webinar_thank_you_page',
  infoPage: 'info_page',
  optInThankYou: 'opt_in_thank_you',
  offerPage: 'offer_page',
  offerUpSellPage: 'offer_upsell_page',
  offerDownSellPage: 'offer_downsell_page',
  inactiveFunnelPage: 'inactive_funnel_page',
  offerThankYouPage: 'offer_thank_you_page',
  funnelContactUs: 'funnel_contact_us',
  offerInner: 'offer_inner',
  customLogin: 'custom_login',
  blogPostLayout: 'blog_post_layout',
  blogPostBody: 'blog_post_body',
  blogLayout: 'blog_layout',
  blogStatic: 'blog_static',
  blogPostListing: 'blog_post_listing',
  blogHome: 'blog_home',
  courseIntroduction: 'course_introduction',
}

export const blogPageTypes = [
  pageTypes.blogPostLayout,
  pageTypes.blogPostBody,
  pageTypes.blogLayout,
  pageTypes.blogStatic,
  pageTypes.blogPostListing,
  pageTypes.blogHome,
]

export const bodyTypes = [structureTypes.BODY, EntityTypeEnum.Body]

export const pageTypeNode = {
  [pageTypes.inline]: [structureTypes.INLINE, EntityTypeEnum.InlineBody],
  [pageTypes.popup]: [
    structureTypes.REMOTE_POPUP,
    EntityTypeEnum.RemotePopupBody,
  ],
  [pageTypes.squeeze]: bodyTypes,
  [pageTypes.salesPage]: bodyTypes,
  [pageTypes.webinarSession]: bodyTypes,
  [pageTypes.lecture]: bodyTypes,
  [pageTypes.webinarRegistration]: bodyTypes,
  [pageTypes.webinarThankYou]: bodyTypes,
  [pageTypes.infoPage]: bodyTypes,
  [pageTypes.offerPage]: bodyTypes,
  [pageTypes.offerUpSellPage]: bodyTypes,
  [pageTypes.offerInner]: bodyTypes,
  [pageTypes.offerDownSellPage]: bodyTypes,
  [pageTypes.inactiveFunnelPage]: bodyTypes,
  [pageTypes.optInThankYou]: bodyTypes,
  [pageTypes.offerThankYouPage]: bodyTypes,
  [pageTypes.customLogin]: bodyTypes,
  [pageTypes.blogPostBody]: bodyTypes,
  [pageTypes.blogStatic]: bodyTypes,
  [pageTypes.blogPostListing]: bodyTypes,
  [pageTypes.blogHome]: bodyTypes,
  [pageTypes.blogPostLayout]: bodyTypes,
  [pageTypes.blogLayout]: bodyTypes,
  [pageTypes.courseIntroduction]: bodyTypes,
  [PageTypeEnum.FunnelContactUs]: bodyTypes,
  [PageTypeEnum.NotFound]: bodyTypes,
  [PageTypeEnum.LinkInBio]: bodyTypes,
}

export const pageTypesWithAutomationRules = []
