import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CommonColumnOld from 'common/components/entities/Column/ColumnOld'
import * as types from 'common/types'
import BaseNodeChildren from './ChildrenEntities'

function ColumnOld(props) {
  const {
    disableStacking = false,
    entity: { styles, options, childIds },
  } = props
  return (
    <CommonColumnOld
      gridColumnStart={props.gridColumnStart}
      styles={styles}
      size={options.size}
      disableStacking={disableStacking}
    >
      <BaseNodeChildren childIds={childIds} />
    </CommonColumnOld>
  )
}

ColumnOld.propTypes = {
  entity: types.entity.isRequired,
  gridColumnStart: PropTypes.number.isRequired,
  disableStacking: PropTypes.bool,
}

export default memo(ColumnOld)
