import React from 'react'
import PropTypes from 'prop-types'
import StepHeaderHeadlinesUi from './ui/StepHeaderHeadlinesUi'
import StepHeadlineUi from './ui/StepHeadlineUi'
import StepHeaderNumberUi from './ui/StepHeaderNumberUi'
import StepSubHeadlineUi from './ui/StepSubHeadlineUi'
import TwoStepPaymentFormHeaderItemUi from './ui/TwoStepPaymentFormHeaderItemUi'

function HeaderItem({
  headline,
  subHeadline,
  active = false,
  styles,
  mobileStyles,
  number,
  headerUnderlineColor,
}) {
  const calculatedMobileStyles = {
    backgroundColor: mobileStyles.backgroundColor || styles.backgroundColor,
    color: mobileStyles.color || styles.color,
  }

  return (
    <TwoStepPaymentFormHeaderItemUi
      active={active}
      styles={styles}
      mobileStyles={calculatedMobileStyles}
      headerUnderlineColor={headerUnderlineColor}
    >
      <StepHeaderNumberUi>{number}</StepHeaderNumberUi>
      <StepHeaderHeadlinesUi>
        <StepHeadlineUi>{headline}</StepHeadlineUi>
        <StepSubHeadlineUi>{subHeadline}</StepSubHeadlineUi>
      </StepHeaderHeadlinesUi>
    </TwoStepPaymentFormHeaderItemUi>
  )
}

HeaderItem.propTypes = {
  active: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  subHeadline: PropTypes.string.isRequired,
  headerUnderlineColor: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  mobileStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
}

export default HeaderItem
