import React from 'react'
import { CloseRemotePopupBodyButtonUi } from './ui/close-remote-popup-body-button-ui'
import { RemotePopupBodyContentUi } from './ui/remote-popup-body-content-ui'
import {
  RemotePopupBodyScrollUi,
  RemotePopupBodyScrollUiProps,
} from './ui/remote-popup-body-scroll-ui'
import {
  RemotePopupBodyUi,
  RemotePopupBodyUiProps,
} from './ui/remote-popup-body-ui'
import { CSSObject } from 'styled-components'

interface RemotePopupBodyProps
  extends Omit<RemotePopupBodyUiProps, 'color' | 'mobileColor'>,
    RemotePopupBodyScrollUiProps {
  children?: React.ReactNode
  hideOverflow?: boolean
  onClose?: () => void
  showClose: boolean
  margin?: CSSObject['margin']
  closeButtonColor?: string
  mobileCloseButtonColor?: string
}

export const CommonRemotePopupBody = ({
  showClose = false,
  onClose,
  children,
  margin,
  background,
  mobileBackground,
  backgroundColor,
  mobileBackgroundColor,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
  padding,
  mobilePadding,
  closeButtonColor,
  mobileCloseButtonColor,
  maxHeight = 'calc(100vh - 40px)',
  backgroundImage,
  mobileBackgroundImage,
  hideOverflow = false,
}: RemotePopupBodyProps) => {
  return (
    <RemotePopupBodyUi
      background={background}
      mobileBackground={mobileBackground}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      border={border}
      mobileBorder={mobileBorder}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      color={closeButtonColor}
      mobileColor={mobileCloseButtonColor}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      margin={margin}
    >
      <RemotePopupBodyScrollUi
        maxHeight={maxHeight}
        padding={padding}
        mobilePadding={mobilePadding}
        hideOverflow={hideOverflow}
      >
        {showClose && (
          <CloseRemotePopupBodyButtonUi
            backgroundColor={closeButtonColor}
            mobileBackgroundColor={mobileCloseButtonColor}
            onClick={e => {
              e.preventDefault()
              onClose?.()
            }}
          />
        )}
        <RemotePopupBodyContentUi>{children}</RemotePopupBodyContentUi>
      </RemotePopupBodyScrollUi>
    </RemotePopupBodyUi>
  )
}
