import React, { ReactNode } from 'react'
import {
  SectionContainerType,
  SectionInterface,
} from 'common/components/entities/Section/types/SectionInterface'
import SectionUi from 'common/components/entities/Section/ui/SectionUi'
import {
  backgroundPositionStyles,
  backgroundPositionValues,
  detectBackgroundPositionType,
  detectBackgroundType,
} from 'client/components/core/Sidebar/components/Settings/styles/BackgroundImageNew/utils'
import SectionContainerUi from './ui/SectionContainerUi'

interface SectionProps
  extends Pick<
      SectionInterface,
      | 'background'
      | 'containerBackgroundColor'
      | 'mobileContainerBackgroundColor'
    >,
    SectionContainerType {
  htmlAttrId: string
  children: ReactNode
  backgroundImage: string
  mobileBackgroundImage: string
  width: string
}

const Section = ({
  htmlAttrId,
  backgroundImage,
  mobileBackgroundImage,
  background,
  backgroundColor,
  mobileBackgroundColor,
  mobilePadding,
  padding,
  boxShadow,
  mobileBoxShadow,
  width,
  containerBackgroundColor,
  mobileContainerBackgroundColor,
  children,
}: SectionProps) => (
  <SectionUi
    htmlAttrId={htmlAttrId}
    backgroundImage={backgroundImage}
    mobileBackgroundImage={mobileBackgroundImage}
    backgroundColor={backgroundColor}
    mobileBackgroundColor={mobileBackgroundColor}
    padding={padding}
    mobilePadding={mobilePadding}
    boxShadow={boxShadow}
    mobileBoxShadow={mobileBoxShadow}
    background={background}
    style={{
      ...backgroundPositionStyles[detectBackgroundType(background)],
      backgroundPosition:
        backgroundPositionValues[detectBackgroundPositionType(background)],
    }}
  >
    <SectionContainerUi
      width={width}
      backgroundColor={containerBackgroundColor}
      mobileBackgroundColor={mobileContainerBackgroundColor}
    >
      {children}
    </SectionContainerUi>
  </SectionUi>
)

export default Section
