import styled, { css } from 'styled-components'

export const PriceGroupHeaderUi = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  border-bottom: 1px solid #e4e8f0;
  gap: 30px;

  @media only screen and (max-width: 1000px) {
    padding: 20px 15px;
    gap: 15px;
  }

  ${p => p.theme.phone} {
    padding: 26px 10px;
    gap: 15px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      padding: 26px 10px;
      gap: 15px;
    `}
`

export const PriceGroupHeaderTitleUi = styled.h2`
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  color: #142d63;

  @media only screen and (max-width: 1000px) {
    font-size: 22px;
    line-height: 32px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      font-size: 22px;
      line-height: 32px;
    `}
`

export const PriceGroupHeaderIconUi = styled.div`
  display: flex;
  width: 35px;
  height: 35px;

  @media only screen and (max-width: 1000px) {
    width: 30px;
    height: 30px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      width: 30px;
      height: 30px;
    `}
`
