import styled, { css } from 'styled-components'

type PageUiProps = {
  $textFontFamily?: string
  $textFontWeight?: string
  $textFontStyle?: string
}

const PageUi = styled.div<PageUiProps>`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  ${p =>
    p.$textFontFamily &&
    css`
      font-family: ${p.$textFontFamily};
    `};
  ${p =>
    p.$textFontWeight &&
    css`
      font-weight: ${p.$textFontWeight};
    `};
  ${p =>
    p.$textFontStyle &&
    css`
      font-style: ${p.$textFontStyle};
    `};
`

export default PageUi
