import { getBlogCategoryTitle } from 'publisher/reducers/blogReducer'
import React, { useEffect, useState } from 'react'
import BaseTextEntity from 'common/components/entities/BaseTextEntity'
import { BlogCategoryTitleInterface } from 'common/types/entities/BlogCategoryTitleInterface'
import useBlog from '../../hooks/useBlog'
import StyleWrapper from '../core/StyleWrapper'

function BlogCategoryTitle({ entity }: { entity: BlogCategoryTitleInterface }) {
  const blogCategoryTitle = useBlog(getBlogCategoryTitle)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  })
  return (
    isClient && (
      <StyleWrapper
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance}
      >
        <BaseTextEntity
          as="h1"
          id={entity.htmlAttrId}
          fontSize={entity.fontSize}
          fontFamily={entity.fontFamily}
          fontStyle={entity.fontStyle}
          fontWeight={entity.fontWeight}
          mobileFontSize={entity.mobileFontSize}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontWeight={entity.mobileFontWeight}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          color={entity.color}
          mobileColor={entity.mobileColor}
          textAlign={entity.textAlign}
          mobileTextAlign={entity.mobileTextAlign}
        >
          {blogCategoryTitle}
        </BaseTextEntity>
      </StyleWrapper>
    )
  )
}

export default BlogCategoryTitle
