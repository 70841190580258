import styled from 'styled-components'
import { PhoneInput } from 'common/components/core/PhoneInput'
import { CountrySelectorButtonUi } from 'common/components/core/PhoneInput/components/CountrySelector/ui/CountrySelectorUi'
import { CountrySelectorWrapperUi } from 'common/components/core/PhoneInput/components/CountrySelector/ui/CountrySelectorWrapperUi'
import { PhoneInputInputUi } from 'common/components/core/PhoneInput/components/PhoneInput/ui/PhoneInputInputUi'
import { fieldStyles } from 'common/components/entities/Field'

const PhoneNumberFieldUi = styled(PhoneInput)<{
  hasIcon?: boolean
  styles: Record<string, any>
  mobileStyles: Record<string, any>
  borderType: string
  mobileBorderType: string
  placeholderColor: string
  mobilePlaceholderColor: string
}>`
  ${CountrySelectorWrapperUi} {
    ${fieldStyles};
    display: flex;
    width: unset;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-right: none;
    padding: 0;

    /** NOTE: Fix an overriding the padding-right in the fieldStyles for a case: the hasIcon and the mobileStyles.fontSize are truthy
     *  SC - https://app.shortcut.com/systeme/story/50286/a-problem-with-the-display-of-the-phone-form-field-in-the-mobile-version */
    ${p => p.theme.phone} {
      padding-right: 0;
    }
    /**/

    ${CountrySelectorButtonUi} {
      ${fieldStyles};
      border: none;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      padding: 0;
      padding-left: 9px;
      height: unset;

      /** NOTE: Fix an overriding the padding-right in the fieldStyles for a case: the hasIcon and the mobileStyles.fontSize are truthy
       *  SC - https://app.shortcut.com/systeme/story/50286/a-problem-with-the-display-of-the-phone-form-field-in-the-mobile-version */
      ${p => p.theme.phone} {
        padding-right: 0;
      }
      /**/
    }
  }
  ${PhoneInputInputUi} {
    ${fieldStyles};
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    height: unset;
  }
`
export default PhoneNumberFieldUi
