import PropTypes from 'prop-types'
import React from 'react'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import { POPUP_CONTENT_ID } from 'client/constants/editorIds'
import PopupClose from './ui/PopupClose'
import PopupContent from './ui/PopupContent'
import PopupDialog from './ui/PopupDialog'
import PopupScrollContentUi from './ui/PopupScrollContentUi'

const prepareWidth = width => {
  if (!width) return '600px'

  if (typeof width === 'number') return `${width}px`

  return width
}

const Popup = ({
  showClose = false,
  onCloseHandler,
  children,
  styles,
  mobileStyles,
  overflow = 'visible',
  maxHeight = 'calc(100vh - 40px)',
  attrId,
  isDataNosnippet = true,
}) => {
  const { width, padding, ...restStyles } = styles
  const { padding: mobilePadding, ...restMobileStyles } = mobileStyles

  return (
    <PopupDialog
      styles={{
        ...restStyles,
        maxHeight,
        width: prepareWidth(width),
      }}
      mobileStyles={restMobileStyles}
      overflow={overflow}
      id={attrId}
      data-nosnippet={isDataNosnippet}
    >
      <PopupScrollContentUi
        styles={{ padding }}
        mobileStyles={{ padding: mobilePadding }}
        id={POPUP_CONTENT_ID}
      >
        {Boolean(Number(showClose)) && (
          <PopupClose color={styles.color} close={onCloseHandler} />
        )}
        <PopupContent>{children}</PopupContent>
      </PopupScrollContentUi>
    </PopupDialog>
  )
}

Popup.propTypes = {
  attrId: PropTypes.string,
  children: PropTypes.node.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  maxHeight: PropTypes.string,
  overflow: PropTypes.string,
  isDataNosnippet: PropTypes.bool,
}

export default provideMobileStyles(Popup)
