import React, { ReactNode } from 'react'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import RowUi from 'common/components/entities/Row/ui/RowUi'
import { CommonContainerInterface } from 'common/types/entities/EntityInterface'
import {
  backgroundPositionStyles,
  backgroundPositionValues,
  detectBackgroundPositionType,
  detectBackgroundType,
} from 'client/components/core/Sidebar/components/Settings/styles/BackgroundImageNew/utils'

interface CommonRowProps
  extends CommonContainerInterface,
    Pick<
      RowInterface,
      'htmlAttrId' | 'disableStacking' | 'reverseColumns' | 'background'
    > {
  children: ReactNode
  disableColumnGap: boolean
  onMouseOver?: () => void
  onMouseOut?: () => void
  forwardedRef?: React.Ref<HTMLDivElement>
  backgroundImage: string
  mobileBackgroundImage: string
}

const CommonRow = ({
  children,
  padding,
  mobilePadding,
  htmlAttrId,
  disableStacking,
  disableColumnGap,
  reverseColumns,
  backgroundColor,
  mobileBackgroundColor,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
  onMouseOver,
  onMouseOut,
  forwardedRef,
  background,
  backgroundImage,
  mobileBackgroundImage,
}: CommonRowProps) => (
  <RowUi
    ref={forwardedRef}
    padding={padding}
    mobilePadding={mobilePadding}
    htmlAttrId={htmlAttrId}
    disableStacking={disableStacking}
    disableColumnGap={disableColumnGap}
    reverseColumns={reverseColumns}
    border={border}
    mobileBorder={mobileBorder}
    backgroundColor={backgroundColor}
    mobileBackgroundColor={mobileBackgroundColor}
    boxShadow={boxShadow}
    mobileBoxShadow={mobileBoxShadow}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    background={background}
    style={{
      ...backgroundPositionStyles[detectBackgroundType(background)],
      backgroundPosition:
        backgroundPositionValues[detectBackgroundPositionType(background)],
    }}
    backgroundImage={backgroundImage}
    mobileBackgroundImage={mobileBackgroundImage}
  >
    {React.Children.map(children, child =>
      React.isValidElement(child) ? React.cloneElement(child) : child,
    )}
  </RowUi>
)
export default CommonRow
