import React from 'react'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import BasePlayer from './BasePlayer'
import useCalculateAutoPlay from './useCalculateAutoPlay'
import { useVideoPlaying } from './useVideoPlaying'

function SourcePlayer({ entity }: { entity: VideoInterface }) {
  const autoplay = useCalculateAutoPlay(entity)
  const { playing, setPlaying } = useVideoPlaying(entity)

  if (!entity.url) {
    return <div />
  }

  return (
    <BasePlayer
      attrId={entity.htmlAttrId}
      autoplay={autoplay}
      playing={playing}
      setPlaying={setPlaying}
      url={entity.url}
      controls={entity.controls}
      boxShadow={entity.boxShadow}
      mobileBoxShadow={entity.mobileBoxShadow}
      border={entity.border}
      mobileBorder={entity.mobileBorder}
    />
  )
}

export default SourcePlayer
