import axios from 'axios'

export interface LoginSubmitData {
  email: string
  password: string
  _remember_me: 'on' | 'off'
  _csrf_token: string
}

export interface ConfirmRegistrationData {
  plainPassword: {
    first: string
    second: string
  }
  firstName: string
  lastName: string
  _csrf_token: string
}

export interface ResetPasswordData {
  plainPassword: {
    first: string
    second: string
  }
  _csrf_token: string
}

export interface ResetRequestData {
  email: string
  _csrf_token: string
}

export type MemberSubmitData =
  | LoginSubmitData
  | ConfirmRegistrationData
  | ResetPasswordData
  | ResetRequestData

export const sendForm = (values: MemberSubmitData) =>
  axios.post(location.href, values, {
    headers: {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
