import styled, { css } from 'styled-components'

export const PriceElementPlansWrapperUi = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: 1000px) {
    margin-top: 30px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      margin-top: 30px;
    `}
`

export const PriceElementPlansPaginationUi = styled.div`
  display: none;
  ${p => p.theme.phone} {
    width: 50%;
    display: flex;
    align-items: flex-end;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      width: 50%;
      display: flex;
      align-items: flex-end;
    `}
`
export const PriceElementPlansPaginationButtonUi = styled.button`
  background-color: transparent;
  font-size: 30px;
  color: rgba(4, 164, 252, 1);
  cursor: pointer;
  transition: color 0.25s ease;

  &:disabled {
    color: #c5ccd9;
    cursor: default;
  }
`

export const PriceElementPlansUi = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;

  @media only screen and (max-width: 1200px) {
    gap: 10px;
  }

  @media only screen and (max-width: 1000px) {
    gap: 30px;
  }

  ${p => p.theme.phone} {
    width: 50%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      gap: 30px;
      width: 50%;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scrollbar-width: none;
    `}
`

export const PriceElementPlanUi = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  ${p => p.theme.phone} {
    scroll-snap-align: start;
    min-width: 100%;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      scroll-snap-align: start;
      min-width: 100%;
    `}
`

export const PriceElementPlanTitleUi = styled.div`
  color: #142d63;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  width: 100%;
  text-align: center;
`

export const PriceElementPlanPriceWrapperUi = styled.div`
  margin-bottom: 20px;
  margin-top: 15px;
  color: #142d63;
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;

  ${p => p.theme.phone} {
    margin-bottom: 15px;
    margin-top: 10px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      margin-bottom: 15px;
      margin-top: 10px;
    `}
`

export const PriceElementPlanPriceUi = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;

  @media only screen and (max-width: 1200px) {
    font-size: 24px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      font-size: 24px;
    `}
`

export const PriceElementPlanButtonUi = styled.button`
  width: 100%;
  background-color: #00a0ff;
  border-radius: 5px;
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-transform: uppercase;
  padding: 6px 0;
  cursor: pointer;

  @media only screen and (max-width: 1200px) {
    font-size: 16px;
    line-height: 30px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      font-size: 16px;
      line-height: 30px;
    `}
`
