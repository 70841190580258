import PropTypes from 'prop-types'
import React from 'react'
import ColumnOldUi from './ui/ColumnOldUi'

const ColumnOld = ({
  size,
  styles,
  disableStacking = false,
  children,
  ref = () => {},
  ...restProps
}) => (
  <ColumnOldUi
    {...restProps}
    ref={ref}
    styles={styles}
    size={size}
    disableStacking={disableStacking}
  >
    {children}
  </ColumnOldUi>
)

ColumnOld.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.number.isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  disableStacking: PropTypes.bool,
}

export default ColumnOld
