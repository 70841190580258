export const parseToObject = stringColor => {
  const rgbaArray = stringColor
    .split('(')[1]
    .split(')')[0]
    .split(',')

  return {
    r: +rgbaArray[0],
    g: +rgbaArray[1],
    b: +rgbaArray[2],
    a: +rgbaArray[3],
  }
}

export const getColorAsString = color =>
  `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`

export const isRgba = style => new RegExp('^rgba').test(style)

export const changeStringColorOpacity = (stringColor, opacity) => {
  const colorObj = parseToObject(stringColor)
  colorObj.a = opacity
  return getColorAsString(colorObj)
}
