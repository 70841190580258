import styled from 'styled-components'

const StepHeaderNumberUi = styled.div`
  flex-basis: 30px;
  font-size: 27px;
  font-weight: bold;
  display: flex;
  align-items: center;
`

export default StepHeaderNumberUi
