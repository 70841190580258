const isWindow = typeof window !== 'undefined'

export const isIphone = (): boolean => /iPhone/i.test(isWindow ? window.navigator?.userAgent : '')

export const isIpad = (): boolean => {

  if (!isWindow) {
    return false
  }

  return /iPad/i.test(window.navigator?.userAgent) ||
    (window.navigator?.userAgent.includes("Mac") && window.navigator?.maxTouchPoints > 1);
}

export const isIpod = (): boolean => /iPod/i.test(isWindow ? window.navigator?.userAgent : '')

export const isIOS = (): boolean => isIphone() || isIpad() || isIpod()

export const isMobileDevice = (): boolean => {

  if (!isWindow) {
    return false
  }

  const regex = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
  return regex.some((b) => window.navigator?.userAgent.match(b))
}

export const isTabletDevice = (): boolean => {
  if (!isWindow) {
    return false
  }

  const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
  return regex.test(window.navigator?.userAgent.toLowerCase())
}

export const isDesktopDevice = (): boolean => !isMobileDevice() && !isTabletDevice()