import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import SelectNewUi from 'common/components/core/Select/ui/select-new'
import { getLanguageCodeByLocale } from 'common/enums/LanguageCodeEnum'
import { FieldInterface } from 'common/types/entities/field-interface'
import { changeField } from 'publisher/actions/optInActions'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useFieldsStorage } from 'publisher/components/entities/Field/hooks/use-fields-storage'
import { typedPageSelectors, useOptIn, usePage } from 'publisher/store'
import {
  getFieldValueBySlug,
  getFieldErrorsBySlug,
  getAvailableCountries,
} from 'publisher/store/opt-in/optInSelectors'
import getCountries from 'publisher/utils/getCountries'

type CountryFieldProps = {
  entity: FieldInterface
}

function CountryFieldNew({ entity }: CountryFieldProps) {
  const value = useOptIn(state => getFieldValueBySlug(state, entity.slug))
  const errors = useOptIn(state => getFieldErrorsBySlug(state, entity.slug))
  const [countries, setCountries] = useState<Record<string, string>[]>([])
  const locale = usePage(state => typedPageSelectors.getLocale(state))
  const availableCountries = useOptIn(state => getAvailableCountries(state))
  const availableCountriesCodes = Object.keys(availableCountries)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (entity.slug) {
      dispatch(changeField(entity.slug, e.target.value))
    }
  }

  useFieldsStorage(entity.slug ?? '')

  useEffect(() => {
    if (availableCountriesCodes.length === 0) {
      setCountries(getCountries(getLanguageCodeByLocale(locale)))
    } else {
      setCountries(
        Object.keys(availableCountries).map(code => ({
          code,
          name: availableCountries[code],
        })),
      )
      if (availableCountriesCodes.length === 1) {
        dispatch(changeField(entity.slug, availableCountriesCodes[0]))
      }
    }
  }, [locale])

  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <SelectNewUi
        value={value}
        onChange={handleChange}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        textAlign={entity.textAlign}
        mobileTextAlign={entity.mobileTextAlign}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        color={entity.color}
        mobileColor={entity.mobileColor}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
      >
        <option value="">{t('entities.field.country.placeholder')}</option>
        {countries.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </SelectNewUi>
      <FieldErrors errors={errors} />
    </StyleWrapper>
  )
}

export default CountryFieldNew
