import styled, { css } from 'styled-components'
import { Background } from 'common/types/BackgroundType'
import { BlogPageBodyInterface } from 'common/types/entities/blog-page-body-interface'
import {
  backgroundColorCss,
  backgroundCss,
  backgroundImageFixForIOS,
  mobileBackgroundColorCss,
  mobileBackgroundCss,
} from 'common/utils/styleUtilsNew'

export interface CommonBlogPageBodyUiProps {
  backgroundColor?: string
  mobileBackgroundColor?: string
  blur?: number
  background?: BlogPageBodyInterface['background']
  backgroundImage?: string | null
  mobileBackgroundImage?: string | null
  mobileBackground?: Background
}

export const CommonBlogPageBodyUi = styled.div<CommonBlogPageBodyUiProps>`
  position: absolute;
  width: 100%;
  height: 100%;

  ${p =>
    p.backgroundImage &&
    css`
      background-image: ${p.backgroundImage};
    `};
  ${backgroundCss}
  ${backgroundColorCss}

  ${({ blur = 0 }) => css`
    filter: blur(${blur}px);
  `}

  ${p => p.theme.phone} {
    ${mobileBackgroundCss}
    ${p =>
      p.mobileBackgroundImage &&
      css`
        background-image: ${p.mobileBackgroundImage};
      `};

    ${mobileBackgroundColorCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      background-image: ${p.mobileBackgroundImage || 'none'};
      ${mobileBackgroundCss}
      ${mobileBackgroundColorCss}
    `}
  ${p =>
    backgroundImageFixForIOS(
      !!(p.mobileBackgroundImage || p.backgroundImage),
      (p.mobileBackground?.backgroundAttachment ||
        p.background?.backgroundAttachment) ??
        '',
    )}
`
