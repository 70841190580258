import { css } from 'styled-components'
import { PhoneNumberFieldUiNewProps } from 'common/components/entities/Field/phone-number-field-ui'
import {
  backgroundColorCss,
  borderCss,
  getBorderType,
  mobileBackgroundColorCss,
  mobileBorderCss,
  mobilePaddingsCss,
  mobilePlaceholderColorCss,
  paddingsCss,
  placeholderColorCss,
  textAlignCss,
  mobileTextAlignCss,
  mobileBoxShadowCss,
  boxShadowCss,
} from 'common/utils/styleUtilsNew'

export const fieldStyles = () => css<PhoneNumberFieldUiNewProps>`
  width: 100%;
  font-size: 16px;
  padding: 12px 0;
  outline: none;
  text-indent: 10px;
  appearance: none;

  ${p =>
    p.hasIcon &&
    css`
      padding-right: ${(p.fontSize ?? 0) + 10}px;
    `};
  ${p => p.theme.phone} {
    ${mobileTextAlignCss}
    ${mobilePaddingsCss}
    ${mobileBackgroundColorCss}
    ${mobileBorderCss}
    ${mobilePlaceholderColorCss}
    ${mobileBoxShadowCss}
    ${p => p.mobileBorder?.type && getBorderType(p.mobileBorder.type)}
    ${p =>
      p.mobileBorder
        ? p.mobileBorder.style
        : css`
            border-style: none;
          `}
    ${p =>
      p.hasIcon &&
      p.mobileFontSize &&
      css`
        padding-right: ${p.mobileFontSize + 10}px;
      `};
  }

  ${textAlignCss}
  ${paddingsCss}
  ${backgroundColorCss}
  ${borderCss}
  ${placeholderColorCss}
  ${boxShadowCss}
  ${p => p.border?.type && getBorderType(p.border.type)}
  ${p =>
    p.hasErrors &&
    css`
      border-color: red;
    `}
    ${p =>
    p.placeholderColor &&
    css`
      &::placeholder {
        color: ${p.placeholderColor};
      }
    `}
      :disabled {
    opacity: 0.4;
  }

  ${p => p.theme.phone} {
    ${mobileTextAlignCss}
    ${mobilePaddingsCss}
    ${mobileBackgroundColorCss}
    ${mobileBorderCss}
    ${mobilePlaceholderColorCss}
    ${mobileBoxShadowCss}
    ${p => p.mobileBorder?.type && getBorderType(p.mobileBorder.type)}
            ${p => p.border?.type && getBorderType(p.border.type)}
            ${p =>
      p.mobilePlaceholderColor &&
      css`
        &::placeholder {
          color: ${p.mobilePlaceholderColor};
        }
      `}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    p.mobilePlaceholderColor &&
    css`
      &::placeholder {
        color: ${p.mobilePlaceholderColor};
      }
    `}
`
