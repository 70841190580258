import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { usePriceElement } from './hooks/usePriceElement'
import { CommonPriceElementGroup } from './price-element-group'
import { UserPlansResponseInterface } from './price-element-interface'
import { CommonPriceElementPeriod } from './price-element-period'
import { CommonPriceElementPlans } from './price-element-plans'
import {
  CommonPriceElementWrapperUi,
  CommonPriceElementContainerUi,
  CommonPriceElementTableContainerUi,
  CommonPriceElementTitleUi,
  CommonPriceElementPlansWrapperUi,
} from './ui'

interface CommonPriceElementProps {
  locale: PageLocaleEnum
  pricePlans: UserPlansResponseInterface[] | null
  openPopup: (id: number) => void
}

export const CommonPriceElement = ({
  locale = PageLocaleEnum.ENGLISH,
  pricePlans,
  openPopup,
}: CommonPriceElementProps) => {
  const { activeBtn, priceGroup, pricePeriod, togglePeriod } = usePriceElement({
    locale,
    pricePlans,
  })
  const { t } = useTranslation()

  const [activeIndex, setActiveIndex] = useState(0)
  const containersRef = useRef<HTMLDivElement[]>([])

  const registerContainer = (ref: HTMLDivElement | null) => {
    if (ref && !containersRef.current.includes(ref)) {
      containersRef.current.push(ref)
    }
  }

  const syncScroll = (index: number) => {
    const limitedIndex = Math.min(index, 3)
    setActiveIndex(limitedIndex)

    containersRef.current.forEach(container => {
      if (!container) return
      const items = container.querySelectorAll('.price-element-scroll-item')
      const targetItem = items[index]

      if (targetItem) {
        const containerRect = container.getBoundingClientRect()
        const targetRect = targetItem.getBoundingClientRect()
        const newScrollLeft =
          container.scrollLeft + (targetRect.left - containerRect.left)

        container.scrollTo({ left: newScrollLeft, behavior: 'smooth' })
      }
    })
  }

  useEffect(() => {
    const observers: IntersectionObserver[] = []

    containersRef.current.forEach(container => {
      if (!container) return
      const items = container.querySelectorAll('.price-element-scroll-item')

      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const index = [...items].indexOf(entry.target)
              if (index <= 3) {
                setActiveIndex(index)
                syncScroll(index)
              }
            }
          })
        },
        { threshold: 0.5 },
      )

      items.forEach(item => observer.observe(item))
      observers.push(observer)
    })

    return () => {
      observers.forEach(observer => observer.disconnect())
    }
  }, [])

  return (
    <CommonPriceElementWrapperUi>
      <CommonPriceElementContainerUi>
        <CommonPriceElementTitleUi>
          {t('components.price_element.title')}:
        </CommonPriceElementTitleUi>
        <CommonPriceElementPlansWrapperUi>
          <CommonPriceElementPeriod
            locale={locale}
            activeBtn={activeBtn}
            onClick={togglePeriod}
          />
          <CommonPriceElementPlans
            plans={pricePeriod}
            onClick={openPopup}
            activeIndex={activeIndex}
            syncScroll={syncScroll}
            registerContainer={registerContainer}
          />
        </CommonPriceElementPlansWrapperUi>
        <CommonPriceElementTableContainerUi>
          {priceGroup.map(el => (
            <CommonPriceElementGroup
              key={el.id}
              data={el}
              syncScroll={syncScroll}
              registerContainer={registerContainer}
            />
          ))}
        </CommonPriceElementTableContainerUi>
      </CommonPriceElementContainerUi>
    </CommonPriceElementWrapperUi>
  )
}
