import React from 'react'
import {
  CommonInlineBodyBackgroundUi,
  CommonInlineBodyBackgroundUiProps,
} from './ui/common-inline-body-background-ui'
import CommonInlineBodyUi, {
  CommonInlineBodyUiProps,
} from './ui/common-inline-body-ui'

interface CommonInlineBodyProps
  extends CommonInlineBodyUiProps,
    CommonInlineBodyBackgroundUiProps {
  children: React.ReactNode
}
const CommonInlineBody = ({
  children,
  blur,
  background,
  backgroundImage,
  mobileBackgroundImage,
  backgroundColor,
  mobileBackgroundColor,
  mobileBackground,
  ...styles
}: CommonInlineBodyProps) => {
  return (
    <CommonInlineBodyUi {...styles}>
      <CommonInlineBodyBackgroundUi
        blur={blur}
        background={background}
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
        backgroundColor={backgroundColor}
        mobileBackgroundColor={mobileBackgroundColor}
        mobileBackground={mobileBackground}
      />
      {children}
    </CommonInlineBodyUi>
  )
}

export default CommonInlineBody
