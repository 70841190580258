import React from 'react'

export const IconArrow = () => (
  <svg
    width="33"
    height="64"
    viewBox="0 0 33 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2883 61.4417C16.8776 60.2967 15.4964 59.1333 14.0347 58.0521C13.7989 57.8783 10.8869 55.8305 11.1491 55.7066C14.5648 54.1006 17.4116 53.0181 20.2365 50.3244C22.9627 47.725 25.2126 44.5428 26.9659 41.2172C30.2019 35.0802 31.9647 28.3374 32.3046 21.4113C32.595 15.4809 31.7854 8.43989 28.0485 3.57159C27.3484 2.65977 23.232 -1.13688 22.1054 1.02495C21.5793 2.03585 22.9035 2.46572 23.5434 3.03982C24.8506 4.2133 25.6674 5.65849 26.3848 7.26896C31.6739 19.1342 25.6001 35.9323 17.2935 44.8245C15.0705 47.204 12.4726 49.2242 9.33665 50.2583C8.64694 50.4858 6.15991 51.4244 5.70569 50.8747C5.26579 50.3433 7.45565 46.8794 7.75743 46.1761C8.83842 43.6586 10.544 40.495 10.7826 37.7329C10.8905 36.4889 10.6187 35.3282 9.2311 35.0926C7.57074 34.8101 6.92616 36.4421 6.29298 37.6109C4.50356 40.9133 3.29151 44.5416 1.8962 48.0255C0.625069 51.1991 -1.25778 54.0651 2.17456 56.2853C5.60631 58.5059 9.45421 60.2757 13.0925 62.1294C14.1077 62.6464 16.6885 64.3483 18.0283 63.8462C20.0001 63.1074 17.749 62.0981 18.2882 61.4416C17.0272 60.4187 18.2575 61.479 18.2882 61.4416L18.2883 61.4417Z"
      fill="#142D63"
    />
  </svg>
)
