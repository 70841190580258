import React from 'react'
import {
  CommonBlogPageBodyUi,
  CommonBlogPageBodyUiProps,
} from './common-blog-page-body-ui'
import { CommonBlogPageBodyWrapperUi } from './common-blog-page-body-wrapper-ui'

interface CommonBlogPostLayoutBodyProps extends CommonBlogPageBodyUiProps {
  children: React.ReactNode
}

export function CommonBlogPageBody({
  children,
  ...styles
}: CommonBlogPostLayoutBodyProps) {
  return (
    <CommonBlogPageBodyWrapperUi>
      <CommonBlogPageBodyUi {...styles} />
      {children}
    </CommonBlogPageBodyWrapperUi>
  )
}
