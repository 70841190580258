import PropTypes from 'prop-types'
import React from 'react'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import PopupClose from './ui/PopupClose'
import PopupContent from './ui/PopupContent'
import { RemotePopupDialog } from './ui/PopupDialog'
import PopupScrollContentUi from './ui/PopupScrollContentUi'

const Popup = ({
  showClose = false,
  onCloseHandler,
  children,
  styles,
  mobileStyles,
  overflow = 'visible',
  maxHeight = 'calc(100vh - 40px)',
  isDataNosnippet = true,
}) => {
  const { padding, ...restStyles } = styles
  const { padding: mobilePadding, ...restMobileStyles } = mobileStyles
  return (
    <RemotePopupDialog
      styles={{ ...restStyles, maxHeight }}
      mobileStyles={restMobileStyles}
      overflow={overflow}
      data-nosnippet={isDataNosnippet}
    >
      <PopupScrollContentUi
        styles={{ padding }}
        mobileStyles={{ padding: mobilePadding }}
      >
        {Boolean(Number(showClose)) && (
          <PopupClose color={styles.color} close={onCloseHandler} />
        )}
        <PopupContent>{children}</PopupContent>
      </PopupScrollContentUi>
    </RemotePopupDialog>
  )
}

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  maxHeight: PropTypes.string,
  overflow: PropTypes.string,
  isDataNosnippet: PropTypes.bool,
}

export default provideMobileStyles(Popup)
