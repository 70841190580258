import React from 'react'

export const IconPayment = () => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.625 6.5625H4.375C3.79484 6.5625 3.23844 6.79297 2.8282 7.2032C2.41797 7.61344 2.1875 8.16984 2.1875 8.75V26.25C2.1875 26.8302 2.41797 27.3866 2.8282 27.7968C3.23844 28.207 3.79484 28.4375 4.375 28.4375H30.625C31.2052 28.4375 31.7616 28.207 32.1718 27.7968C32.582 27.3866 32.8125 26.8302 32.8125 26.25V8.75C32.8125 8.16984 32.582 7.61344 32.1718 7.2032C31.7616 6.79297 31.2052 6.5625 30.625 6.5625ZM18.5938 24.0625H16.4062C16.1162 24.0625 15.838 23.9473 15.6329 23.7421C15.4277 23.537 15.3125 23.2588 15.3125 22.9688C15.3125 22.6787 15.4277 22.4005 15.6329 22.1954C15.838 21.9902 16.1162 21.875 16.4062 21.875H18.5938C18.8838 21.875 19.162 21.9902 19.3671 22.1954C19.5723 22.4005 19.6875 22.6787 19.6875 22.9688C19.6875 23.2588 19.5723 23.537 19.3671 23.7421C19.162 23.9473 18.8838 24.0625 18.5938 24.0625ZM27.3438 24.0625H22.9688C22.6787 24.0625 22.4005 23.9473 22.1954 23.7421C21.9902 23.537 21.875 23.2588 21.875 22.9688C21.875 22.6787 21.9902 22.4005 22.1954 22.1954C22.4005 21.9902 22.6787 21.875 22.9688 21.875H27.3438C27.6338 21.875 27.912 21.9902 28.1171 22.1954C28.3223 22.4005 28.4375 22.6787 28.4375 22.9688C28.4375 23.2588 28.3223 23.537 28.1171 23.7421C27.912 23.9473 27.6338 24.0625 27.3438 24.0625ZM4.375 12.0312V8.75H30.625V12.0312H4.375Z"
      fill="#142D63"
    />
  </svg>
)
