import styled, { css } from 'styled-components'

export const PriceGroupItemUi = styled.div`
  width: 100%;
  display: flex;
  min-height: 88px;
  border-bottom: 1px solid #e4e8f0;
`

export const PriceGroupItemTitleUi = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7faff;
  color: #000033;
  padding-left: 30px;
  width: 330px;
  font-weight: bold;
  line-height: 28px;
  font-size: 18px;
  flex-shrink: 0;

  @media only screen and (max-width: 1000px) {
    font-size: 16px;
    width: 30%;
    padding-left: 15px;
  }

  ${p => p.theme.phone} {
    text-align: center;
    padding: 10px;
    justify-content: center;
    width: 50%;
    font-size: 18px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      text-align: center;
      padding: 10px;
      justify-content: center;
      width: 50%;
      font-size: 18px;
    `}
`

export const PriceGroupPlansUi = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;

  ${p => p.theme.phone} {
    width: 50%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      width: 50%;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scrollbar-width: none;
    `}
`

export const PriceGroupItemPlanUi = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #142d63;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  ${p => p.theme.phone} {
    scroll-snap-align: start;
    min-width: 100%;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      scroll-snap-align: start;
      min-width: 100%;
    `}
`
