import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconAffiliate } from '../icons/icon-affiliate'
import { IconAvailable } from '../icons/icon-available'
import { IconBlogs } from '../icons/icon-blogs'
import { IconBookingCalendar } from '../icons/icon-booking-calendar'
import { IconCommunities } from '../icons/icon-communities'
import { IconCourses } from '../icons/icon-courses'
import { IconECommerce } from '../icons/icon-e-commerce'
import { IconEmail } from '../icons/icon-email'
import { IconFunnel } from '../icons/icon-funnel'
import { IconIntegrations } from '../icons/icon-integrations'
import { IconLink } from '../icons/icon-link'
import { IconPayment } from '../icons/icon-payment'
import { IconSupport } from '../icons/icon-support'
import { IconTeam } from '../icons/icon-team'
import { IconUnavailable } from '../icons/icon-unavailable'
import {
  PlanGroupIconType,
  PriceElementGroupInterface,
} from '../price-element-interface'
import { CommonPriceElementUnknownUi } from '../ui'
import {
  PriceGroupHeaderIconUi,
  PriceGroupHeaderTitleUi,
  PriceGroupHeaderUi,
} from './ui/price-group-header'
import {
  PriceGroupItemPlanUi,
  PriceGroupItemTitleUi,
  PriceGroupItemUi,
  PriceGroupPlansUi,
} from './ui/price-group-items'

interface CommonPriceGroupProps {
  data: PriceElementGroupInterface
  syncScroll: (index: number) => void
  registerContainer: (ref: HTMLDivElement | null) => void
}

export const CommonPriceElementGroup = ({
  data,
  registerContainer,
}: CommonPriceGroupProps) => {
  const { t } = useTranslation()
  const getIcon = (icon: PlanGroupIconType) => {
    switch (icon) {
      case 'email':
        return <IconEmail />
      case 'funnel':
        return <IconFunnel />
      case 'booking':
        return <IconBookingCalendar />
      case 'payment':
        return <IconPayment />
      case 'blogs':
        return <IconBlogs />
      case 'link':
        return <IconLink />
      case 'e-commerce':
        return <IconECommerce />
      case 'courses':
        return <IconCourses />
      case 'communities':
        return <IconCommunities />
      case 'affiliate':
        return <IconAffiliate />
      case 'support':
        return <IconSupport />
      case 'team':
        return <IconTeam />
      case 'integrations':
        return <IconIntegrations />

      default:
        break
    }
  }

  const getPlanContent = (value: boolean | string) => {
    if (typeof value === 'boolean') {
      return value ? <IconAvailable /> : <IconUnavailable />
    }

    if (value === 'unknown') {
      return ''
    }

    if (value === 'Unlimited') {
      return t('components.price_element.unlimited')
    }

    return value
  }

  return (
    <>
      <PriceGroupHeaderUi>
        <PriceGroupHeaderIconUi>{getIcon(data.icon)}</PriceGroupHeaderIconUi>
        <PriceGroupHeaderTitleUi>{t(data.title)}</PriceGroupHeaderTitleUi>
      </PriceGroupHeaderUi>
      {data.items.map(el => (
        <PriceGroupItemUi key={el.title}>
          <PriceGroupItemTitleUi>{t(el.title)}</PriceGroupItemTitleUi>
          <PriceGroupPlansUi ref={registerContainer}>
            {el.plans.map((plan, idx) => (
              <PriceGroupItemPlanUi
                className="price-element-scroll-item"
                key={plan.toString() + idx.toString()}
              >
                {getPlanContent(plan) ? (
                  getPlanContent(plan)
                ) : (
                  <CommonPriceElementUnknownUi width="100px" />
                )}
              </PriceGroupItemPlanUi>
            ))}
          </PriceGroupPlansUi>
        </PriceGroupItemUi>
      ))}
    </>
  )
}
