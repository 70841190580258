import styled, { css } from 'styled-components'
import { AlignType } from 'common/types/styleTypes'
import { mobileTextCss, textCss } from 'common/utils/styleUtilsNew'

export type CheckboxUiProps = {
  textAlign: AlignType
  mobileAlignSelf?: AlignType
}

const CheckboxUi = styled.div<CheckboxUiProps>`
  ${textCss}

  ${p => p.theme.phone} {
    ${mobileTextCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileTextCss}
    `}
`

export default CheckboxUi
