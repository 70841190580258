import styled from 'styled-components'
import PropTypes from 'prop-types'


const StepButtonUi = styled.button`
  display: flex;
  padding: 15px 0;
  width: 100%;
  border: none;
  outline: none;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

StepButtonUi.propTypes = {
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  mobileStyles: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
}

export default StepButtonUi
