import styled, { css } from 'styled-components'
import { AlignType, BorderType, PaddingType } from 'common/types/styleTypes'
import {
  borderCss,
  mobileBorderCss,
  backgroundColorCss,
  mobileBackgroundColorCss,
  fontCss,
  mobileFontCss,
  boxShadowCss,
  mobileBoxShadowCss,
  paddingsCss,
  mobilePaddingsCss,
  FontProps,
  placeholderColorCss,
  mobilePlaceholderColorCss,
  textAlignCss,
  mobileTextAlignCss,
  mobileLineHeightCss,
  lineHeightCss,
} from 'common/utils/styleUtilsNew'

type SelectNewUiProps = {
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  padding: PaddingType
  mobilePadding?: PaddingType
  backgroundColor?: string
  mobileBackgroundColor?: string
  boxShadow?: string
  mobileBoxShadow?: string
  hasErrors?: boolean
  placeholderColor?: string
  mobilePlaceholderColor?: string
  lineHeight?: number
  mobileLineHeight?: number
  textAlign?: AlignType
  mobileTextAlign?: AlignType
} & FontProps

const SelectNewUi = styled.select<SelectNewUiProps>`
  font-size: 16px;
  text-indent: 10px;
  padding: 12px 0;
  outline: none;
  border: 1px solid #b0baca;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;

  ${paddingsCss}
  ${boxShadowCss}
  ${borderCss}
  ${backgroundColorCss}
  ${fontCss}
  ${placeholderColorCss}
  ${textAlignCss}
  ${lineHeightCss}
  ${p => p.hasErrors && `border-color: red;`}

  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
    ${mobileBoxShadowCss}
    ${mobileBorderCss}
    ${mobileBackgroundColorCss}
    ${mobileFontCss}
    ${mobilePlaceholderColorCss}
    ${mobileTextAlignCss}
    ${mobileLineHeightCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
      ${mobileBoxShadowCss}
      ${mobileBorderCss}
      ${mobileBackgroundColorCss}
      ${mobileFontCss}
      ${mobilePlaceholderColorCss}
      ${mobileTextAlignCss}
      ${mobileLineHeightCss}
    `}

  ${p =>
    p.theme.isEditor &&
    css`
      pointer-events: none;
    `}
`

export default SelectNewUi
