import styled, { css, CSSObject } from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'

export interface RemotePopupBodyScrollUiProps {
  padding?: PaddingType
  mobilePadding?: PaddingType
  maxHeight?: CSSObject['height']
  hideOverflow?: boolean
}

export const RemotePopupBodyScrollUi = styled.div<RemotePopupBodyScrollUiProps>`
  ${p =>
    p.maxHeight
      ? css`
          max-height: ${p.maxHeight};
        `
      : css``}
  ${p =>
    !p.hideOverflow &&
    css`
      overflow-x: hidden;
      overflow-y: auto;
    `}

  ${paddingsCss}

  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
    `}
`
