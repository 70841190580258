import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import {
  defaultVideoStyles,
  backgroundPositionValues,
} from 'common/constants/settings'
import videoTypes from 'common/constants/videoTypes'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import BodyBackgroundUi from './ui/BodyBackgroundUi'
import BodyUi from './ui/BodyUi'

const getBackgroundImage = file => (file ? `url("${file.path}")` : 'none')

const getBlurFilter = blur => `blur(${blur || 0}px)`

export const getVideoUrlFromOptions = ({
  videoType,
  videoFile,
  youtubeUrl,
  videoUrl,
}) => {
  let url = ''

  if (videoType === videoTypes.URL) {
    url = videoUrl || ''
  } else if (videoType === videoTypes.FILE) {
    url = videoFile ? videoFile.path : ''
  } else if (videoType === videoTypes.YOUTUBE) {
    url = youtubeUrl || ''
  }

  return url
}

const Body = ({
  children,
  videoPlay = false,
  videoLoop = false,
  backgroundStyles = {},
  backgroundFile,
  mobileBackgroundFile,
  ...restProps
}) => {
  const { styles, options, mobileStyles, mobileOptions } = restProps
  const { backgroundColor, ...restStyles } = styles
  const { backgroundColor: mobileBackgroundColor, ...restMobileStyles } =
    mobileStyles
  const { blur } = options
  const backgroundImage = getBackgroundImage(backgroundFile)
  const mobileBackgroundImage = getBackgroundImage(mobileBackgroundFile)
  const filter = getBlurFilter(blur)
  const url = getVideoUrlFromOptions(options)

  return (
    <BodyUi $mobileStyles={restMobileStyles} $styles={restStyles}>
      <BodyBackgroundUi
        $styles={{
          ...styles,
          ...backgroundStyles,
          ...backgroundPositionValues[options.backgroundPosition],
          filter,
          backgroundColor,
          backgroundImage,
        }}
        $mobileStyles={{
          backgroundColor: mobileBackgroundColor,
          backgroundImage: mobileBackgroundImage,
          ...mobileStyles,
          ...backgroundPositionValues[mobileOptions.backgroundPosition],
        }}
      />
      {children}
      {url && (
        <ReactPlayer
          url={url}
          style={defaultVideoStyles}
          playing={videoPlay}
          loop={videoLoop}
          width="100%"
          height="100%"
        />
      )}
    </BodyUi>
  )
}

Body.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  videoFile: PropTypes.objectOf(PropTypes.any),
  backgroundFile: PropTypes.objectOf(PropTypes.any),
  mobileBackgroundFile: PropTypes.objectOf(PropTypes.any),
  videoUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  videoPlay: PropTypes.bool,
  videoLoop: PropTypes.bool,
  videoType: PropTypes.string,
  backgroundStyles: PropTypes.objectOf(PropTypes.any),
}

export default provideMobileStyles(Body)
