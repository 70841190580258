import PropTypes from 'prop-types'
import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import ButtonLoader from 'common/components/core/ButtonLoader'
import CommonButton, { wrapperStyles } from 'common/components/entities/Button'
import { borderTypeKeys } from 'common/constants/settings'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'

function BaseButton({
  mobileStyles,
  styles,
  handleClick,
  asLink = false,
  href,
  disabled = false,
  attrId = '',
  text = '',
  borderType,
  mobileBorderType,
  dataTestId = '',
  appearance = {},
  loading = false,
  loadingText = '',
  subText = '',
  subTextColor,
  mobileSubTextColor,
  subTextFontSize,
  mobileSubTextFontSize,
  subTextFontFamily,
  mobileSubTextFontFamily,
  subTextFontWeight,
  mobileSubTextFontWeight,
  subTextFontStyle,
  mobileSubTextFontStyle,
  iconClassNameBefore,
  iconClassNameAfter,
  hoverStyles,
  hoverMobileStyles,
}) {
  const [margins, restStyles] = splitMarginsAndRestStyles(styles)
  const [mobileMargins, mobileRestStyles] =
    splitMarginsAndRestStyles(mobileStyles)

  return (
    <StyleWrapper
      styles={{ ...margins, ...wrapperStyles }}
      mobileStyles={mobileMargins}
      desktop={appearance.desktop}
      mobile={appearance.mobile}
    >
      <CommonButton
        dataTestId={dataTestId}
        attrId={attrId}
        mobileStyles={mobileRestStyles}
        styles={restStyles}
        click={handleClick}
        asLink={asLink}
        href={href}
        disabled={disabled}
        borderType={borderType}
        mobileBorderType={mobileBorderType || borderType}
        subText={subText}
        hoverStyles={{
          textColor: hoverStyles?.textColor,
          backgroundColor: hoverStyles?.backgroundColor,
          subTextColor: hoverStyles?.subTextColor,
          transform: hoverStyles?.transform,
          offset: hoverStyles?.offset,
        }}
        hoverMobileStyles={{
          textColor: hoverMobileStyles?.textColor,
          backgroundColor: hoverMobileStyles?.backgroundColor,
          subTextColor: hoverMobileStyles?.subTextColor,
          transform: hoverMobileStyles?.transform,
          offset: hoverMobileStyles?.offset,
        }}
        subTextStyles={{
          color: subTextColor,
          fontSize: subTextFontSize,
          fontWeight: subTextFontWeight,
          fontStyle: subTextFontStyle,
          fontFamily: subTextFontFamily,
        }}
        subTextMobileStyles={{
          color: mobileSubTextColor,
          fontSize: mobileSubTextFontSize,
          fontWeight: mobileSubTextFontWeight,
          fontStyle: mobileSubTextFontStyle,
          fontFamily: mobileSubTextFontFamily,
        }}
        iconClassNameBefore={iconClassNameBefore}
        iconClassNameAfter={iconClassNameAfter}
      >
        {loading && <ButtonLoader />}
        {loading && loadingText ? loadingText : text}
      </CommonButton>
    </StyleWrapper>
  )
}

const stylesPropTypesShape = {
  fontSize: PropTypes.string,
  borderTopLeftRadius: PropTypes.string,
  borderTopRightRadius: PropTypes.string,
  borderBottomRightRadius: PropTypes.string,
  borderBottomLeftRadius: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  marginTop: PropTypes.string,
  alignSelf: PropTypes.string,
}

BaseButton.propTypes = {
  mobileStyles: PropTypes.shape(stylesPropTypesShape),
  styles: PropTypes.shape(stylesPropTypesShape),
  asLink: PropTypes.bool,
  href: PropTypes.string,
  handleClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  attrId: PropTypes.string,
  borderType: PropTypes.oneOf(Object.values(borderTypeKeys)),
  mobileBorderType: PropTypes.oneOf(Object.values(borderTypeKeys)),
  dataTestId: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  hoverStyles: PropTypes.shape({
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    subTextColor: PropTypes.string,
    transform: PropTypes.string,
    offset: PropTypes.number,
  }),
  hoverMobileStyles: PropTypes.shape({
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    subTextColor: PropTypes.string,
    transform: PropTypes.string,
    offset: PropTypes.number,
  }),
  appearance: PropTypes.shape({
    desktop: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
  }),
  subText: PropTypes.string,
  subTextColor: PropTypes.string,
  mobileSubTextColor: PropTypes.string,
  subTextFontSize: PropTypes.string,
  mobileSubTextFontSize: PropTypes.string,
  subTextFontFamily: PropTypes.string,
  mobileSubTextFontFamily: PropTypes.string,
  subTextFontWeight: PropTypes.string,
  mobileSubTextFontWeight: PropTypes.string,
  subTextFontStyle: PropTypes.string,
  mobileSubTextFontStyle: PropTypes.string,
  iconClassNameBefore: PropTypes.string,
  iconClassNameAfter: PropTypes.string,
}

export default BaseButton
