import React from 'react'

export const IconSupport = () => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.7187 17.4995V28.437C31.7187 29.8874 31.1426 31.2784 30.117 32.304C29.0914 33.3296 27.7004 33.9058 26.25 33.9058H18.5937C18.3037 33.9058 18.0255 33.7906 17.8203 33.5854C17.6152 33.3803 17.5 33.1021 17.5 32.812C17.5 32.522 17.6152 32.2438 17.8203 32.0386C18.0255 31.8335 18.3037 31.7183 18.5937 31.7183H26.25C27.1202 31.7183 27.9548 31.3726 28.5702 30.7572C29.1855 30.1419 29.5312 29.3073 29.5312 28.437H26.25C25.3798 28.437 24.5452 28.0913 23.9298 27.476C23.3144 26.8606 22.9687 26.026 22.9687 25.1558V19.687C22.9687 18.8168 23.3144 17.9822 23.9298 17.3669C24.5452 16.7515 25.3798 16.4058 26.25 16.4058H29.4834C29.2764 14.1321 28.4268 11.9642 27.0337 10.1554C25.6406 8.34651 23.7615 6.97139 21.6161 6.19063C19.4706 5.40986 17.1473 5.25567 14.9174 5.74607C12.6876 6.23647 10.6434 7.35121 9.02344 8.96009C7.0091 10.9479 5.76669 13.5868 5.51797 16.4058H8.75C9.62024 16.4058 10.4548 16.7515 11.0702 17.3669C11.6855 17.9822 12.0312 18.8168 12.0312 19.687V25.1558C12.0312 26.026 11.6855 26.8606 11.0702 27.476C10.4548 28.0913 9.62024 28.437 8.75 28.437H6.5625C5.69226 28.437 4.85766 28.0913 4.24231 27.476C3.62695 26.8606 3.28125 26.026 3.28125 25.1558V17.4995C3.28432 14.683 4.12292 11.9307 5.69092 9.59095C7.25892 7.25123 9.48582 5.42929 12.0898 4.35575C14.6937 3.28221 17.5576 3.00533 20.3189 3.56016C23.0803 4.11499 25.615 5.47658 27.6021 7.47259C28.9141 8.79098 29.9531 10.3553 30.6595 12.0759C31.3659 13.7965 31.7259 15.6396 31.7187 17.4995Z"
      fill="#142D63"
    />
  </svg>
)
