import React from 'react'

export const IconLink = () => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.9844 27.3442C28.9845 28.0626 28.8274 28.7723 28.524 29.4235C28.2206 30.0746 27.7783 30.6515 27.2283 31.1136C26.6782 31.5756 26.0336 31.9117 25.3399 32.0981C24.6461 32.2845 23.9199 32.3168 23.2124 32.1927C22.5048 32.0687 21.8329 31.7912 21.244 31.3798C20.6551 30.9684 20.1633 30.4331 19.8033 29.8114C19.4433 29.1898 19.2238 28.4968 19.1601 27.7813C19.0964 27.0657 19.1901 26.3449 19.4346 25.6694L12.1885 21.0141C11.4965 21.693 10.6194 22.1523 9.66723 22.3346C8.7151 22.5168 7.73036 22.4138 6.83653 22.0386C5.94271 21.6633 5.17961 21.0324 4.64298 20.225C4.10634 19.4177 3.82007 18.4699 3.82007 17.5005C3.82007 16.5311 4.10634 15.5832 4.64298 14.7759C5.17961 13.9686 5.94271 13.3377 6.83653 12.9624C7.73036 12.5871 8.7151 12.4841 9.66723 12.6664C10.6194 12.8486 11.4965 13.308 12.1885 13.9868L19.4346 9.33836C19.0197 8.19819 19.0394 6.94511 19.49 5.81855C19.9406 4.692 20.7905 3.77097 21.8773 3.23145C22.964 2.69193 24.2115 2.57175 25.3813 2.89387C26.5511 3.21599 27.5612 3.95784 28.2185 4.97765C28.8759 5.99746 29.1345 7.22373 28.9449 8.42214C28.7552 9.62056 28.1307 10.7071 27.1906 11.4741C26.2505 12.2411 25.0607 12.6349 23.8486 12.5801C22.6365 12.5254 21.4871 12.0259 20.62 11.1772L13.3739 15.8325C13.7655 16.9147 13.7655 18.0999 13.3739 19.1821L20.62 23.8374C21.3118 23.1603 22.1879 22.7023 23.1388 22.5206C24.0896 22.3389 25.0729 22.4416 25.9656 22.8159C26.8583 23.1902 27.6209 23.8194 28.1578 24.6248C28.6948 25.4303 28.9823 26.3762 28.9844 27.3442Z"
      fill="#142D63"
    />
  </svg>
)
