import React, { useEffect, useRef, useState } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonLatestBlogPostsCarousel from 'common/components/entities/BlogLatestPostsCarousel'
import {
  publisherImagePlaceholderSrc,
  publisherBlogPostTs,
  rangePosts,
} from 'common/components/entities/BlogPostsNew/utils'
import CommonCarousel from 'common/components/entities/Carousel'
import { BlogPost } from 'common/types/entities/BlogPostListingInterface'
import { LatestBlogPostsCarouselInterface } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import CarouselSlide from 'publisher/components/entities/Carousel/CarouselSlide'
import useBlog, { selectors as blogSelectors } from 'publisher/hooks/useBlog'
import { managementSelectors, useManagement } from 'publisher/store'
import { usePage, typedPageSelectors } from 'publisher/store/index'

function parsePosts(postsReplacement: string) {
  try {
    return JSON.parse(postsReplacement) as BlogPost[]
  } catch (e) {
    return []
  }
}

function LatestBlogPostsCarousel({
  entity,
}: {
  entity: LatestBlogPostsCarouselInterface
}) {
  const [isClient, setIsClient] = useState(false)
  const [needScroll, setNeedScroll] = useState(false)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const carouselRef = useRef<HTMLDivElement>(null)
  const blogPath = useBlog(blogSelectors.getBlogPath)
  const locale = usePage(typedPageSelectors.getLocale)
  const isPreview = useManagement(managementSelectors.isPreview)
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const latestPosts = parsePosts(entity.postsReplacement)
  const calculatedRowSize = isDesktop ? entity.maxPostsPerRow : 1

  useEffect(() => {
    setIsClient(true)
  }, [])

  const posts = isPreview
    ? rangePosts(
        entity.maxPosts,
        publisherBlogPostTs,
        publisherImagePlaceholderSrc,
      )
    : latestPosts

  function updateActiveSlideIndex(index: number) {
    setActiveSlideIndex(prevIndex => {
      setNeedScroll(prevIndex !== index)
      return index
    })
  }

  const allowedPosts = posts.filter((post, index) => index < +entity.maxPosts)

  const carouselSlidesPosts = allowedPosts.reduce((acc, post, index) => {
    index % calculatedRowSize === 0 && acc.push([])
    acc[acc.length - 1].push(post)
    return acc
  }, [] as BlogPost[][])

  return (
    <>
      {isClient && (
        <StyleWrapper
          mobile={entity.appearance.mobile}
          desktop={entity.appearance.desktop}
          styles={entity.margin}
          mobileStyles={entity.mobileMargin}
        >
          <CommonCarousel
            arrowsColor={entity.arrowsColor}
            controlsColor={entity.controlsColor}
            padding={entity.padding}
            mobilePadding={entity.mobilePadding}
            totalItems={carouselSlidesPosts.length}
            htmlAttrId={entity.htmlAttrId}
            showArrows={entity.showArrows}
            mobileShowArrows={entity.mobileShowArrows}
            switchActiveSlideNumber={updateActiveSlideIndex}
            activeSlideIndex={activeSlideIndex}
            carouselRef={carouselRef}
          >
            {carouselSlidesPosts.map((carouselSlideEntity, index) => (
              <CarouselSlide
                key={index}
                active={index === activeSlideIndex}
                setActive={() => updateActiveSlideIndex(index)}
                carouselRef={carouselRef}
                needScroll={needScroll}
              >
                <CommonLatestBlogPostsCarousel
                  htmlAttrId={entity.htmlAttrId}
                  imagePlacement={entity.imagePlacement}
                  mobileImagePlacement={entity.mobileImagePlacement}
                  maxPosts={entity.maxPosts}
                  locale={locale}
                  blogPath={blogPath}
                  posts={[carouselSlideEntity]}
                  imageMargin={entity.imageMargin}
                  mobileImageMargin={entity.mobileImageMargin}
                  titleMargin={entity.titleMargin}
                  mobileTitleMargin={entity.mobileTitleMargin}
                  categoriesMargin={entity.categoriesMargin}
                  mobileCategoriesMargin={entity.mobileCategoriesMargin}
                  descriptionMargin={entity.descriptionMargin}
                  mobileDescriptionMargin={entity.mobileDescriptionMargin}
                  dateMargin={entity.dateMargin}
                  mobileDateMargin={entity.mobileDateMargin}
                  maxPostsPerRow={calculatedRowSize}
                  showDate={entity.showDate}
                  padding={entity.padding}
                  mobilePadding={entity.mobilePadding}
                  imageBorder={entity.imageBorder}
                  mobileImageBorder={entity.mobileImageBorder}
                  imageBoxShadow={entity.imageBoxShadow}
                  mobileImageBoxShadow={entity.mobileImageBoxShadow}
                  titleFontSize={entity.titleFontSize}
                  mobileTitleFontSize={entity.mobileTitleFontSize}
                  titleLineHeight={entity.titleLineHeight}
                  mobileTitleLineHeight={entity.mobileTitleLineHeight}
                  titleFontFamily={entity.titleFontFamily}
                  titleFontWeight={entity.titleFontWeight}
                  titleFontStyle={entity.titleFontStyle}
                  mobileTitleFontFamily={entity.mobileTitleFontFamily}
                  mobileTitleFontWeight={entity.mobileTitleFontWeight}
                  mobileTitleFontStyle={entity.mobileTitleFontStyle}
                  titleLetterSpacing={entity.titleLetterSpacing}
                  mobileTitleLetterSpacing={entity.mobileTitleLetterSpacing}
                  titleAlign={entity.titleAlign}
                  mobileTitleAlign={entity.mobileTitleAlign}
                  titleColor={entity.titleColor}
                  mobileTitleColor={entity.mobileTitleColor}
                  descriptionFontSize={entity.descriptionFontSize}
                  mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
                  descriptionLineHeight={entity.descriptionLineHeight}
                  mobileDescriptionLineHeight={
                    entity.mobileDescriptionLineHeight
                  }
                  descriptionFontFamily={entity.descriptionFontFamily}
                  descriptionFontWeight={entity.descriptionFontWeight}
                  descriptionFontStyle={entity.descriptionFontStyle}
                  mobileDescriptionFontFamily={
                    entity.mobileDescriptionFontFamily
                  }
                  mobileDescriptionFontWeight={
                    entity.mobileDescriptionFontWeight
                  }
                  mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
                  descriptionAlign={entity.descriptionAlign}
                  mobileDescriptionAlign={entity.mobileDescriptionAlign}
                  descriptionColor={entity.descriptionColor}
                  mobileDescriptionColor={entity.mobileDescriptionColor}
                  categoriesFontSize={entity.categoriesFontSize}
                  mobileCategoriesFontSize={entity.mobileCategoriesFontSize}
                  categoriesFontFamily={entity.categoriesFontFamily}
                  categoriesFontWeight={entity.categoriesFontWeight}
                  categoriesFontStyle={entity.categoriesFontStyle}
                  mobileCategoriesFontFamily={entity.mobileCategoriesFontFamily}
                  mobileCategoriesFontWeight={entity.mobileCategoriesFontWeight}
                  mobileCategoriesFontStyle={entity.mobileCategoriesFontStyle}
                  categoriesAlign={entity.categoriesAlign}
                  mobileCategoriesAlign={entity.mobileCategoriesAlign}
                  categoriesColor={entity.categoriesColor}
                  mobileCategoriesColor={entity.mobileCategoriesColor}
                  dateFontSize={entity.dateFontSize}
                  mobileDateFontSize={entity.mobileDateFontSize}
                  dateFontFamily={entity.dateFontFamily}
                  dateFontWeight={entity.dateFontWeight}
                  dateFontStyle={entity.dateFontStyle}
                  mobileDateFontFamily={entity.mobileDateFontFamily}
                  mobileDateFontWeight={entity.mobileDateFontWeight}
                  mobileDateFontStyle={entity.mobileDateFontStyle}
                  dateAlign={entity.dateAlign}
                  mobileDateAlign={entity.mobileDateAlign}
                  dateColor={entity.dateColor}
                  mobileDateColor={entity.mobileDateColor}
                  columnsGap={entity.columnsGap}
                  postBackgroundColor={entity.postBackgroundColor}
                  mobilePostBackgroundColor={entity.mobilePostBackgroundColor}
                />
              </CarouselSlide>
            ))}
          </CommonCarousel>
        </StyleWrapper>
      )}
    </>
  )
}

export default LatestBlogPostsCarousel
