import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { FontProps } from 'common/utils/styleUtilsNew'
import { CheckIcon } from '../ui/icons/CheckIcon'
import { ThankYouTitleUi } from './ui/thank-you-title-ui'

export interface ThankYouTitleProps {
  entity: BookingCalendarInterface
}

export const ThankYouTitle = ({ entity }: ThankYouTitleProps & FontProps) => {
  const { t } = useTranslation()
  return (
    <ThankYouTitleUi>
      <CheckIcon />
      <p style={{ fontWeight: 700, fontSize: '22px' }}>
        {t('entity_settings.booking_calendar.booking_confirmed')}
      </p>
      <span>{entity.thankYouStepSubHeadline}</span>
    </ThankYouTitleUi>
  )
}
