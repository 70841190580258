import PropTypes from 'prop-types'
import { Provider as RollbarProvider, LEVEL_ERROR } from '@rollbar/react'
import React, { createContext } from 'react'
import { StyleSheetManager } from 'styled-components'
import { Provider } from 'react-redux'
import Page from 'publisher/components/Page'
import { CreateEntityElementProvider } from 'publisher/context/create-entity-element-context'
import { shouldForwardProp } from 'common/utils/should-forward-prop'

export const CreateEntitiesContext = createContext(null)
export const rollbarConfig = (pageId, reportLevel) => ({
  enabled: process.env.NODE_ENV !== 'development',
  environment: process.env.APP_ENV,
  accessToken: process.env.ROLLBAR_PUBLISHER_KEY,
  captureUncaught: false,
  captureUnhandledRejections: false,
  autoInstrument: {
    network: false,
    log: false,
  },
  payload: {
    host: process.env.SYSTEME_IO_HOST,
    context: {
      pageId,
    },
  },
  reportLevel,
})

const App = ({
  store,
  createEntityElement,
  createPage,
  rollbarReportLevel = LEVEL_ERROR,
}) => {
  return (
    <CreateEntityElementProvider createEntityElement={createEntityElement}>
      <RollbarProvider
        config={rollbarConfig(store.getState().page.id, rollbarReportLevel)}
      >
        <Provider store={store}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <Page createPage={createPage} />
          </StyleSheetManager>
        </Provider>
      </RollbarProvider>
    </CreateEntityElementProvider>
  )
}

App.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  createPage: PropTypes.func.isRequired,
  createEntityElement: PropTypes.func.isRequired,
}

export default App
