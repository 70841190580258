import { useEffect, useState } from 'react'
import { EventInterface } from 'common/components/entities/Calendar/calendar-interface'
import { AxiosResponse } from 'axios'

interface UseEventArgs {
  eventId?: EventInterface['id']
  fetcher: (
    eventId: EventInterface['id'],
  ) => Promise<AxiosResponse<EventInterface, any>>
}

export const useEvent = ({ eventId, fetcher }: UseEventArgs) => {
  const [event, setEvent] = useState<EventInterface>()
  const [error, setError] = useState<unknown>(null)
  const [isFetching, setIsFetching] = useState(false)

  const fetch = async (eventId: EventInterface['id']) => {
    setIsFetching(true)
    try {
      const data = await fetcher(eventId)
      setEvent(data.data)
    } catch (error) {
      setError(error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (eventId) fetch(eventId)
  }, [eventId])

  return { event, isFetching, error, fetch }
}
