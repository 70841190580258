import { useEffect, useState } from 'react'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import {
  PricePlanPeriodType,
  PriceElementPeriodInterface,
  PriceElementGroupInterface,
  UserPlansResponseInterface,
} from '../price-element-interface'
import {
  getDefaultPricePeriod,
  getPriceGroup,
  getPriceGroupDefault,
  getPricePeriod,
} from '../utils'

interface usePriceElementProps {
  locale: PageLocaleEnum
  pricePlans: UserPlansResponseInterface[] | null
}

export const usePriceElement = ({
  locale,
  pricePlans,
}: usePriceElementProps) => {
  const [activeBtn, setActiveBtn] = useState<PricePlanPeriodType>('monthly')
  const [pricePeriod, setPricePeriod] = useState<PriceElementPeriodInterface[]>(
    getDefaultPricePeriod(4, activeBtn),
  )
  const [priceGroupMonthly, setPriceGroupMonthly] = useState<
    PriceElementGroupInterface[] | null
  >(null)
  const [priceGroupAnnually, setPriceGroupAnnually] = useState<
    PriceElementGroupInterface[] | null
  >(null)
  const [priceGroup, setPriceGroup] = useState<PriceElementGroupInterface[]>(
    getPriceGroupDefault(),
  )

  const togglePeriod = (btnType: PricePlanPeriodType) => {
    if (btnType === activeBtn || !pricePlans) {
      return
    }
    setPricePeriod(getPricePeriod(pricePlans, btnType, locale))
    setPriceGroup(
      (btnType === 'monthly' ? priceGroupMonthly : priceGroupAnnually) ?? [],
    )
    setActiveBtn(btnType)
  }

  useEffect(() => {
    if (pricePlans) {
      const priceGroupMonthlyData = getPriceGroup('monthly', pricePlans)
      setPriceGroup(priceGroupMonthlyData)
      setPriceGroupMonthly(priceGroupMonthlyData)
      setPriceGroupAnnually(getPriceGroup('annually', pricePlans))
      setPricePeriod(getPricePeriod(pricePlans, activeBtn, locale))
    }
  }, [pricePlans])

  return {
    activeBtn,
    pricePeriod,
    priceGroup,
    pricePlans,
    togglePeriod,
    priceGroupMonthly,
    priceGroupAnnually,
  }
}
