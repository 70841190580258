import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CommonField from 'common/components/entities/Field/FieldUi'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import FieldIconUiNew from 'common/components/entities/Field/field-icon-ui'
import { FieldInterface } from 'common/types/entities/field-interface'
import { changeField } from 'publisher/actions/optInActions'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useOptIn } from 'publisher/store'
import {
  getFieldErrorsBySlug,
  getFieldValueBySlug,
} from 'publisher/store/opt-in/optInSelectors'
import fieldValidation from 'publisher/utils/fieldValidation'

type FieldNewProps = {
  entity: FieldInterface
  needInitialise?: boolean
  type?: string
  readOnly?: boolean
  disabled?: boolean
  extraErrors?: string[]
}

const BaseFieldNew = ({
  entity,
  needInitialise = true,
  type = 'text',
  readOnly = false,
  disabled = false,
  extraErrors = [],
}: FieldNewProps) => {
  const value = useOptIn(state => getFieldValueBySlug(state, entity.slug))
  const errors = useOptIn(state => getFieldErrorsBySlug(state, entity.slug))
  const iconColor = entity.color || 'initial'
  const mobileIconColor = entity.mobileColor || iconColor
  const [inlineErrors, setInlineErrors] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleChange = (value?: string) => {
    if (entity.slug) {
      dispatch(changeField(entity.slug, value))
    }
  }

  const handleBlur = (value?: string) => {
    const errors = fieldValidation(entity.slug, value)
    setInlineErrors(errors.map((error: string) => t(error)))
    setTimeout(() => {
      window.dispatchEvent(new Event('form_updated'))
    }, 100)
  }

  const calculatedErrors = inlineErrors.length > 0 ? inlineErrors : errors

  // need to init all fields
  useEffect(() => {
    if (needInitialise) {
      handleChange(value)
    }
  }, [needInitialise])

  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <FieldWrapperUi>
        {entity.iconClassName && (
          <FieldIconUiNew
            color={iconColor}
            fontSize={entity.fontSize}
            mobileFontSize={entity.mobileFontSize}
            mobileColor={mobileIconColor}
            className={entity.iconClassName}
          />
        )}
        <CommonField
          id={entity.htmlAttrId}
          type={type}
          name={entity.slug}
          placeholder={entity.placeholder}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          textAlign={entity.textAlign}
          mobileTextAlign={entity.mobileTextAlign}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          placeholderColor={entity.placeholderColor}
          mobilePlaceholderColor={entity.mobilePlaceholderColor}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e.target.value)
          }
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            handleBlur(e.target.value)
          }
          color={entity.color}
          mobileColor={entity.mobileColor}
          fontSize={entity.fontSize}
          mobileFontSize={entity.mobileFontSize}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          hasErrors={calculatedErrors.length > 0}
          disabled={disabled}
          value={value}
          readOnly={readOnly}
        />
      </FieldWrapperUi>
      <FieldErrors errors={[...calculatedErrors, ...extraErrors]} />
    </StyleWrapper>
  )
}

export default BaseFieldNew
