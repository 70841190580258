import PropTypes from 'prop-types'
import styled from 'styled-components'

const TwoStepPaymentFormUi = styled.div`
  overflow: hidden;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

TwoStepPaymentFormUi.propTypes = {
  styles: PropTypes.shape({
    borderTopLeftRadius: PropTypes.string.isRequired,
    borderTopRightRadius: PropTypes.string.isRequired,
    borderBottomRightRadius: PropTypes.string.isRequired,
    borderBottomLeftRadius: PropTypes.string.isRequired,
    borderStyle: PropTypes.string.isRequired,
    borderWidth: PropTypes.string.isRequired,
    borderColor: PropTypes.string.isRequired,
    marginTop: PropTypes.string.isRequired,
    marginRight: PropTypes.string.isRequired,
    marginBottom: PropTypes.string.isRequired,
    marginLeft: PropTypes.string.isRequired,
  }).isRequired,
  mobileStyles: PropTypes.shape({
    borderTopLeftRadius: PropTypes.string.isRequired,
    borderTopRightRadius: PropTypes.string.isRequired,
    borderBottomRightRadius: PropTypes.string.isRequired,
    borderBottomLeftRadius: PropTypes.string.isRequired,
    borderStyle: PropTypes.string.isRequired,
    borderWidth: PropTypes.string.isRequired,
    borderColor: PropTypes.string.isRequired,
    marginTop: PropTypes.string.isRequired,
    marginRight: PropTypes.string.isRequired,
    marginBottom: PropTypes.string.isRequired,
    marginLeft: PropTypes.string.isRequired,
  }).isRequired,
}

export default TwoStepPaymentFormUi
