import styled, { css } from 'styled-components'
import {
  colorCss,
  fontSizeCss,
  mobileColorCss,
  mobileFontSizeCss,
} from 'common/utils/styleUtilsNew'

type FieldIconUiNewProps = {
  color?: string
  mobileColor?: string
  fontSize?: number
  mobileFontSize?: number
}

const FieldIconUiNew = styled.i<FieldIconUiNewProps>`
  color: initial;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  ${colorCss}
  ${fontSizeCss}

  ${p => p.theme.phone} {
    right: 10px;
    ${mobileColorCss}
    ${mobileFontSizeCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      left: 10px;
      ${mobileColorCss}
      ${mobileFontSizeCss}
    `}
`

export default FieldIconUiNew
