import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { IconArrow } from '../icons/icon-arrow'
import { PricePlanPeriodType } from '../price-element-interface'
import {
  PriceElementPeriodUi,
  PriceElementPeriodSwitcherUi,
  PriceElementPeriodBtnUi,
  PriceElementPeriodDescriptionUi,
  PriceElementPeriodSavingUi,
  PriceElementPeriodDescriptionWrapperUi,
  PriceElementPeriodSavingTextUi,
  PriceElementPeriodSavingIconUi,
} from './ui'

interface CommonPriceElementPeriodProps {
  activeBtn: PricePlanPeriodType
  onClick: (value: PricePlanPeriodType) => void
  locale: PageLocaleEnum
}

export const CommonPriceElementPeriod = ({
  activeBtn,
  onClick,
  locale,
}: CommonPriceElementPeriodProps) => {
  const { t } = useTranslation()

  return (
    <PriceElementPeriodUi>
      <PriceElementPeriodDescriptionWrapperUi>
        <PriceElementPeriodDescriptionUi>
          {t('components.price_element.period.title')}
        </PriceElementPeriodDescriptionUi>
        <PriceElementPeriodSavingUi>
          <PriceElementPeriodSavingIconUi>
            <IconArrow></IconArrow>
          </PriceElementPeriodSavingIconUi>
          <PriceElementPeriodSavingTextUi locale={locale}>
            {t('components.price_element.period.save')}
          </PriceElementPeriodSavingTextUi>
          30%
        </PriceElementPeriodSavingUi>
      </PriceElementPeriodDescriptionWrapperUi>
      <PriceElementPeriodSwitcherUi>
        <PriceElementPeriodBtnUi
          onClick={() => onClick('monthly')}
          isActive={activeBtn === 'monthly'}
        >
          {t('components.price_element.period.monthly')}
        </PriceElementPeriodBtnUi>
        <PriceElementPeriodBtnUi
          onClick={() => onClick('annually')}
          isActive={activeBtn === 'annually'}
        >
          {t('components.price_element.period.annually')}
        </PriceElementPeriodBtnUi>
      </PriceElementPeriodSwitcherUi>
    </PriceElementPeriodUi>
  )
}
