import React from 'react'

export const IconEmail = () => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.6366 6.062C31.6366 6.062 31.6366 6.07568 31.6366 6.08251L23.6796 32.3243C23.5591 32.7505 23.3112 33.1297 22.9691 33.411C22.627 33.6923 22.207 33.8622 21.7655 33.8979C21.7026 33.9034 21.6397 33.9061 21.5768 33.9061C21.1631 33.9074 20.7577 33.7903 20.4083 33.5687C20.059 33.347 19.7804 33.03 19.6054 32.6552L14.6288 22.4423C14.579 22.3399 14.5624 22.2246 14.5812 22.1123C14.6001 22.0001 14.6535 21.8965 14.7341 21.8161L22.6528 13.8974C22.8493 13.6906 22.9572 13.4152 22.9535 13.13C22.9499 12.8448 22.835 12.5723 22.6333 12.3706C22.4316 12.1689 22.1591 12.054 21.8739 12.0504C21.5887 12.0467 21.3133 12.1546 21.1065 12.3511L13.1837 20.2698C13.1032 20.3503 12.9997 20.4038 12.8874 20.4226C12.7752 20.4415 12.6599 20.4249 12.5575 20.3751L2.33504 15.3999C1.93531 15.2081 1.60328 14.8994 1.38296 14.5147C1.16264 14.1299 1.06443 13.6873 1.10135 13.2455C1.13827 12.8037 1.30857 12.3835 1.58968 12.0406C1.87079 11.6978 2.24945 11.4485 2.67547 11.3257L28.9173 3.36865H28.9378C29.3114 3.26367 29.7062 3.25999 30.0818 3.35798C30.4573 3.45597 30.8 3.6521 31.0747 3.92625C31.3494 4.20039 31.5462 4.54268 31.6449 4.91801C31.7437 5.29333 31.7408 5.68816 31.6366 6.062Z"
      fill="#142D63"
    />
  </svg>
)
