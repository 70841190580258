import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FieldInterface } from 'common/types/entities/field-interface'
import { changeField } from 'publisher/actions/optInActions'
import BaseFieldNew from 'publisher/components/entities/field-new/base-field'
import { useOptIn } from 'publisher/store'
import { getFieldValueBySlug } from 'publisher/store/opt-in/optInSelectors'

type EmailFieldProps = {
  entity: FieldInterface
}

function EmailFieldNew({ entity }: EmailFieldProps) {
  const value = useOptIn(state => getFieldValueBySlug(state, entity.slug))
  const [isPredefined, setPredefined] = useState(false)
  const dispatch = useDispatch()

  const handleChange = (value?: string) => {
    if (entity.slug) {
      dispatch(changeField(entity.slug, value))
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const email = searchParams.get('email')
    if (email) {
      handleChange(email)
    } else if (value) {
      // when value isPredefined it is a customer value
      // customer email must be the same as registered with
      setPredefined(true)
    } else {
      handleChange('')
    }
  }, [])

  return (
    <BaseFieldNew
      entity={entity}
      readOnly={isPredefined}
      disabled={isPredefined}
      needInitialise={false}
      type="email"
    />
  )
}

export default EmailFieldNew
