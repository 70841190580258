import styled, { css } from 'styled-components'
import { SectionContainerType } from 'common/components/entities/Section/types/SectionInterface'
import {
  backgroundImageFixForIOS,
  containerCss,
} from 'common/utils/styleUtilsNew'
import { Background } from 'common/types/BackgroundType'

interface SectionUiProps extends SectionContainerType {
  htmlAttrId: string
  backgroundImage: string
  mobileBackgroundImage: string
  background: Background
}

const SectionUi = styled.section.attrs<SectionUiProps>(p => ({
  id: p.htmlAttrId,
}))<SectionUiProps>`
  display: flex;
  position: relative;
  ${containerCss}
  background-image:${p => p.backgroundImage};
  ${p => p.theme.phone} {
    background-image: ${p => p.mobileBackgroundImage};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      background-image: ${p.mobileBackgroundImage};
    `}

  ${p =>
    backgroundImageFixForIOS(
      !!(p.mobileBackgroundImage || p.backgroundImage),
      p.background?.backgroundAttachment ?? '',
    )}
`

export default SectionUi
