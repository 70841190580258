import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { currencyFormat } from 'common/utils/currency-format'
import {
  PricePlanPeriodType,
  UserPlansResponseInterface,
  PriceElementGroupInterface,
  PriceElementPeriodInterface,
} from '../price-element-interface'
import { CurrencyEnum } from 'common/types/CurrencyType'

export const UNLIMITED_TRESHOLD = 100000
export const UNLIMITED_VARIANTS_TRESHOLD = 400

export const GROUP_TITLE = {
  contacts: 'components.price_element.group.contacts',
  sales: 'components.price_element.group.sales',
  booking: 'components.price_element.group.booking',
  payment: 'components.price_element.group.payment',
  blogs: 'components.price_element.group.blogs',
  link: 'components.price_element.group.link',
  eCommerce: 'components.price_element.group.e_commerce',
  courses: 'components.price_element.group.courses',
  communities: 'components.price_element.group.communities',
  affiliate: 'components.price_element.group.affiliate',
  support: 'components.price_element.group.support',
  team: 'components.price_element.group.team',
  integrations: 'components.price_element.group.integrations',
}

export const PRICE_PLAN_TITLE = {
  contacts: 'components.price_element.plans.contacts',
  emailsSent: 'components.price_element.plans.email_sent',
  emailCampaigns: 'components.price_element.plans.email_campaigns',
  emailNewsletters: 'components.price_element.plans.email_newsletters',
  automationRules: 'components.price_element.plans.automation_rules',
  workflows: 'components.price_element.plans.workflows',
  api: 'components.price_element.plans.api',
  salesFunnels: 'components.price_element.plans.sales_funnels',
  salesFunnelsSteps: 'components.price_element.plans.sales_funnels_steps',
  deadlineFunnels: 'components.price_element.plans.deadline_funnels',
  evergreenWebinars: 'components.price_element.plans.evergreen_webinars',
  tests: 'components.price_element.plans.tests',
  customDomains: 'components.price_element.plans.custom_domains',
  fileStorageSpace: 'components.price_element.plans.file_storage_space',
  clickUpsells: 'components.price_element.plans.click_upsells',
  orderBumps: 'components.price_element.plans.order_bumps',
  transactionFees: 'components.price_element.plans.transaction_fees',
  events: 'components.price_element.plans.events',
  bookings: 'components.price_element.plans.bookings',
  stripe: 'components.price_element.plans.stripe',
  applePay: 'components.price_element.plans.apple_pay',
  paypal: 'components.price_element.plans.paypal',
  razorpay: 'components.price_element.plans.razorpay',
  flutterwave: 'components.price_element.plans.flutterwave',
  paystack: 'components.price_element.plans.paystack',
  xendit: 'components.price_element.plans.xendit',
  cash: 'components.price_element.plans.cash',
  blogs: 'components.price_element.plans.blogs',
  blogPosts: 'components.price_element.plans.blogs_posts',
  mobileLink: 'components.price_element.plans.mobile_link',
  mobileStore: 'components.price_element.plans.mobile_store',
  physicalProducts: 'components.price_element.plans.physical_products',
  shippingManagement: 'components.price_element.plans.shipping_management',
  inventoryManagement: 'components.price_element.plans.inventory_management',
  courses: 'components.price_element.plans.courses',
  students: 'components.price_element.plans.students',
  studentsActivityHistory:
    'components.price_element.plans.students_activity_history',
  communities: 'components.price_element.plans.communities',
  communityMembers: 'components.price_element.plans.community_members',
  affiliateProgram: 'components.price_element.plans.affiliate_program',
  support: 'components.price_element.plans.support',
  freeMigration: 'components.price_element.plans.free_migration',
  kickstart: 'components.price_element.plans.kickstart',
  assistantAccounts: 'components.price_element.plans.assistant_accounts',
  googleSheetsIntegration:
    'components.price_element.plans.google_sheets_integration',
  activeCampaignIntegration:
    'components.price_element.plans.active_campaign_integration',
  sendGridIntegration: 'components.price_element.plans.send_grid_integration',
  zoomIntegration: 'components.price_element.plans.zoom_integration',
}

export const getPriceGroupDefault = (): PriceElementGroupInterface[] => [
  {
    id: 1,
    icon: 'email',
    title: GROUP_TITLE.contacts,
    items: [
      {
        title: PRICE_PLAN_TITLE.contacts,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.emailsSent,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.emailCampaigns,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.emailNewsletters,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.automationRules,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.workflows,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.api,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 2,
    icon: 'funnel',
    title: GROUP_TITLE.sales,
    items: [
      {
        title: PRICE_PLAN_TITLE.salesFunnels,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.salesFunnelsSteps,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.deadlineFunnels,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.evergreenWebinars,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.tests,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.customDomains,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.fileStorageSpace,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.clickUpsells,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.orderBumps,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.transactionFees,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 3,
    icon: 'booking',
    title: GROUP_TITLE.booking,
    items: [
      {
        title: PRICE_PLAN_TITLE.events,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.bookings,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 4,
    icon: 'payment',
    title: GROUP_TITLE.payment,
    items: [
      {
        title: PRICE_PLAN_TITLE.stripe,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.applePay,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.paypal,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.razorpay,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.flutterwave,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.paystack,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.xendit,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.cash,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 5,
    icon: 'blogs',
    title: GROUP_TITLE.blogs,
    items: [
      {
        title: PRICE_PLAN_TITLE.blogs,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.blogPosts,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 6,
    icon: 'link',
    title: GROUP_TITLE.link,
    items: [
      {
        title: PRICE_PLAN_TITLE.mobileLink,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.mobileStore,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.transactionFees,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 7,
    icon: 'e-commerce',
    title: GROUP_TITLE.eCommerce,
    items: [
      {
        title: PRICE_PLAN_TITLE.physicalProducts,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.shippingManagement,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.inventoryManagement,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.cash,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 8,
    icon: 'courses',
    title: GROUP_TITLE.courses,
    items: [
      {
        title: PRICE_PLAN_TITLE.courses,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.students,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.studentsActivityHistory,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 9,
    icon: 'communities',
    title: GROUP_TITLE.communities,
    items: [
      {
        title: PRICE_PLAN_TITLE.communities,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.communityMembers,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 10,
    icon: 'affiliate',
    title: GROUP_TITLE.affiliate,
    items: [
      {
        title: PRICE_PLAN_TITLE.affiliateProgram,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 11,
    icon: 'support',
    title: GROUP_TITLE.support,
    items: [
      {
        title: PRICE_PLAN_TITLE.support,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.freeMigration,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.kickstart,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 12,
    icon: 'team',
    title: GROUP_TITLE.team,
    items: [
      {
        title: PRICE_PLAN_TITLE.assistantAccounts,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
  {
    id: 13,
    icon: 'integrations',
    title: GROUP_TITLE.integrations,
    items: [
      {
        title: PRICE_PLAN_TITLE.googleSheetsIntegration,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.activeCampaignIntegration,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.sendGridIntegration,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
      {
        title: PRICE_PLAN_TITLE.zoomIntegration,
        plans: ['unknown', 'unknown', 'unknown', 'unknown'],
      },
    ],
  },
]

export const getPriceGroup = (
  period: PricePlanPeriodType,
  plans: UserPlansResponseInterface[],
): PriceElementGroupInterface[] => {
  const getPlan = (number: number): string => {
    return number >= UNLIMITED_TRESHOLD ? 'Unlimited' : new Intl.NumberFormat("en-IN").format(number)
  }

  return [
    {
      id: 1,
      icon: 'email',
      title: GROUP_TITLE.contacts,
      items: [
        {
          title: PRICE_PLAN_TITLE.contacts,
          plans: plans.map(el => getPlan(el.maxContacts)),
        },
        {
          title: PRICE_PLAN_TITLE.emailsSent,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
        {
          title: PRICE_PLAN_TITLE.emailCampaigns,
          plans: plans.map(el => getPlan(el.maxCampaigns)),
        },
        {
          title: PRICE_PLAN_TITLE.emailNewsletters,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
        {
          title: PRICE_PLAN_TITLE.automationRules,
          plans: plans.map(el => getPlan(el.maxAutomationRules)),
        },
        {
          title: PRICE_PLAN_TITLE.workflows,
          plans: plans.map(el => getPlan(el.maxWorkflows)),
        },
        {
          title: PRICE_PLAN_TITLE.api,
          plans: ['30', '60', '120', 'Unlimited'],
        },
      ],
    },
    {
      id: 2,
      icon: 'funnel',
      title: GROUP_TITLE.sales,
      items: [
        {
          title: PRICE_PLAN_TITLE.salesFunnels,
          plans: plans.map(el => getPlan(el.maxFunnels)),
        },
        {
          title: PRICE_PLAN_TITLE.salesFunnelsSteps,
          plans: plans.map(el => getPlan(el.maxFunnelSteps)),
        },
        {
          title: PRICE_PLAN_TITLE.deadlineFunnels,
          plans: [true, true, true, true],
        },
        {
          title: PRICE_PLAN_TITLE.evergreenWebinars,
          plans: plans.map(el => getPlan(el.maxWebinars)),
        },
        {
          title: PRICE_PLAN_TITLE.tests,
          plans: plans.map(el => getPlan(el.maxSplitTests)),
        },
        {
          title: PRICE_PLAN_TITLE.customDomains,
          plans: plans.map(el => getPlan(el.maxUserDomains)),
        },
        {
          title: PRICE_PLAN_TITLE.fileStorageSpace,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
        {
          title: PRICE_PLAN_TITLE.clickUpsells,
          plans: plans.map(el => getPlan(el.maxUpsells)),
        },
        {
          title: PRICE_PLAN_TITLE.orderBumps,
          plans: plans.map(el => getPlan(el.maxOrderBumps)),
        },
        {
          title: PRICE_PLAN_TITLE.transactionFees,
          plans: ['0%', '0%', '0%', '0%'],
        },
      ],
    },
    {
      id: 3,
      icon: 'booking',
      title: GROUP_TITLE.booking,
      items: [
        {
          title: PRICE_PLAN_TITLE.events,
          plans: ['1', '5', '20', 'Unlimited'],
        },
        {
          title: PRICE_PLAN_TITLE.bookings,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
      ],
    },
    {
      id: 4,
      icon: 'payment',
      title: GROUP_TITLE.payment,
      items: [
        { title: PRICE_PLAN_TITLE.stripe, plans: [true, true, true, true] },
        { title: PRICE_PLAN_TITLE.applePay, plans: [true, true, true, true] },
        { title: PRICE_PLAN_TITLE.paypal, plans: [true, true, true, true] },
        { title: PRICE_PLAN_TITLE.razorpay, plans: [true, true, true, true] },
        {
          title: PRICE_PLAN_TITLE.flutterwave,
          plans: [true, true, true, true],
        },
        { title: PRICE_PLAN_TITLE.paystack, plans: [true, true, true, true] },
        { title: PRICE_PLAN_TITLE.xendit, plans: [true, true, true, true] },
        { title: PRICE_PLAN_TITLE.cash, plans: [true, true, true, true] },
      ],
    },
    {
      id: 5,
      icon: 'blogs',
      title: GROUP_TITLE.blogs,
      items: [
        {
          title: PRICE_PLAN_TITLE.blogs,
          plans: plans.map(el => getPlan(el.maxBlogs)),
        },
        {
          title: PRICE_PLAN_TITLE.blogPosts,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
      ],
    },
    {
      id: 6,
      icon: 'link',
      title: GROUP_TITLE.link,
      items: [
        { title: PRICE_PLAN_TITLE.mobileLink, plans: [true, true, true, true] },
        {
          title: PRICE_PLAN_TITLE.mobileStore,
          plans: ['1', '3', '10', 'Unlimited'],
        },
        {
          title: PRICE_PLAN_TITLE.transactionFees,
          plans: ['0%', '0%', '0%', '0%'],
        },
      ],
    },
    {
      id: 7,
      icon: 'e-commerce',
      title: GROUP_TITLE.eCommerce,
      items: [
        {
          title: PRICE_PLAN_TITLE.physicalProducts,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
        {
          title: PRICE_PLAN_TITLE.shippingManagement,
          plans: [true, true, true, true],
        },
        {
          title: PRICE_PLAN_TITLE.inventoryManagement,
          plans: [true, true, true, true],
        },
        { title: PRICE_PLAN_TITLE.cash, plans: [true, true, true, true] },
      ],
    },
    {
      id: 8,
      icon: 'courses',
      title: GROUP_TITLE.courses,
      items: [
        {
          title: PRICE_PLAN_TITLE.courses,
          plans: plans.map(el => getPlan(el.maxMembershipCourses)),
        },
        {
          title: PRICE_PLAN_TITLE.students,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
        {
          title: PRICE_PLAN_TITLE.studentsActivityHistory,
          plans: [true, true, true, true],
        },
      ],
    },
    {
      id: 9,
      icon: 'communities',
      title: GROUP_TITLE.communities,
      items: [
        {
          title: PRICE_PLAN_TITLE.communities,
          plans: plans.map(el => getPlan(el.maxCommunities)),
        },
        {
          title: PRICE_PLAN_TITLE.communityMembers,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
      ],
    },
    {
      id: 10,
      icon: 'affiliate',
      title: GROUP_TITLE.affiliate,
      items: [
        {
          title: PRICE_PLAN_TITLE.affiliateProgram,
          plans: [true, true, true, true],
        },
      ],
    },
    {
      id: 11,
      icon: 'support',
      title: GROUP_TITLE.support,
      items: [
        { title: PRICE_PLAN_TITLE.support, plans: [true, true, true, true] },
        {
          title: PRICE_PLAN_TITLE.freeMigration,
          plans: [
            false,
            period === 'annually' ? true : false,
            period === 'annually' ? true : false,
            true,
          ],
        },
        {
          title: PRICE_PLAN_TITLE.kickstart,
          plans: [
            false,
            period === 'annually' ? true : false,
            period === 'annually' ? true : false,
            true,
          ],
        },
      ],
    },
    {
      id: 12,
      icon: 'team',
      title: GROUP_TITLE.team,
      items: [
        {
          title: PRICE_PLAN_TITLE.assistantAccounts,
          plans: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
        },
      ],
    },
    {
      id: 13,
      icon: 'integrations',
      title: GROUP_TITLE.integrations,
      items: [
        {
          title: PRICE_PLAN_TITLE.googleSheetsIntegration,
          plans: [true, true, true, true],
        },
        {
          title: PRICE_PLAN_TITLE.activeCampaignIntegration,
          plans: [true, true, true, true],
        },
        {
          title: PRICE_PLAN_TITLE.sendGridIntegration,
          plans: [true, true, true, true],
        },
        {
          title: PRICE_PLAN_TITLE.zoomIntegration,
          plans: [true, true, true, true],
        },
      ],
    },
  ]
}

export const getDefaultPricePeriod = (
  count: number,
  period: PricePlanPeriodType = 'monthly',
): PriceElementPeriodInterface[] =>
  [...Array(count).keys()].map(el => ({
    id: el,
    title: null,
    cost: null,
    currency: null,
    period:
      period === 'monthly'
        ? 'components.price_element.period.month'
        : 'components.price_element.period.year',
  }))

export const getPricePeriod = (
  response: UserPlansResponseInterface[],
  btnType: PricePlanPeriodType = 'monthly',
  locale: PageLocaleEnum,
): PriceElementPeriodInterface[] =>
  response.map(el => {
    const cost =
      btnType === 'monthly' ? el.monthCostWithTax : el.annualCostWithTax
    const period =
      btnType === 'monthly'
        ? 'components.price_element.period.month'
        : 'components.price_element.period.year'
    return {
      id: el.id,
      title: el.title,
      currency: el.currency,
      cost: currencyFormat(cost / 100, el.currency || CurrencyEnum.EURO, locale, true),
      period,
    }
  })
