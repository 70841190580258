import React from 'react'

export const LocationIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 2.5C12.3487 2.50298 9.80694 3.5575 7.93222 5.43222C6.0575 7.30694 5.00298 9.84875 5 12.5C5 16.5688 7.95375 19.9475 11.0825 23.5238C12.0725 24.6562 13.0975 25.8275 14.01 27.0125C14.1268 27.164 14.2767 27.2866 14.4484 27.3711C14.62 27.4555 14.8087 27.4993 15 27.4993C15.1913 27.4993 15.38 27.4555 15.5516 27.3711C15.7233 27.2866 15.8732 27.164 15.99 27.0125C16.9025 25.8275 17.9275 24.6562 18.9175 23.5238C22.0462 19.9475 25 16.5688 25 12.5C24.997 9.84875 23.9425 7.30694 22.0678 5.43222C20.1931 3.5575 17.6513 2.50298 15 2.5ZM15 16.25C14.2583 16.25 13.5333 16.0301 12.9166 15.618C12.2999 15.206 11.8193 14.6203 11.5355 13.9351C11.2516 13.2498 11.1774 12.4958 11.3221 11.7684C11.4667 11.041 11.8239 10.3728 12.3483 9.84835C12.8728 9.3239 13.541 8.96675 14.2684 8.82206C14.9958 8.67736 15.7498 8.75162 16.4351 9.03545C17.1203 9.31928 17.706 9.79993 18.118 10.4166C18.5301 11.0333 18.75 11.7583 18.75 12.5C18.75 13.4946 18.3549 14.4484 17.6517 15.1517C16.9484 15.8549 15.9946 16.25 15 16.25Z"
        fill="#64748B"
      />
    </svg>
  )
}
