import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CommonPriceElement } from 'common/components/entities/price-element'
import { PiceElementPopupType } from 'common/components/entities/price-element/price-element-interface'
import { PriceElementInterface } from 'common/types/entities/price-element-interface'
import { showPopup } from 'publisher/actionsManagement'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePage, typedPageSelectors } from 'publisher/store'
import { usePricePlans } from './hooks/usePricePlans'

interface PriceElementProps {
  entity: PriceElementInterface
}

function PriceElement({ entity }: PriceElementProps) {
  const [isClient, setIsClient] = useState(false)
  const locale = usePage(typedPageSelectors.getLocale)
  const dispatch = useDispatch()

  const handleOpenPopup = (btnId: number) => {
    let popupIdType: null | PiceElementPopupType = null

    switch (btnId) {
      case 0:
        popupIdType = 'popupIdFirstPlan'
        break
      case 1:
        popupIdType = 'popupIdSecondPlan'
        break
      case 2:
        popupIdType = 'popupIdThirdPlan'
        break
      case 3:
        popupIdType = 'popupIdFourthPlan'
        break

      default:
        break
    }

    if (popupIdType) {
      dispatch(showPopup(entity[popupIdType]))
    }
  }
  const { pricePlans } = usePricePlans()
  useEffect(() => {
    setIsClient(true)
  })
  return (
    isClient && (
      <StyleWrapper
        margin={entity.margin}
        appearance={entity.appearance}
        mobileMargin={entity.mobileMargin}
      >
        <CommonPriceElement
          locale={locale}
          pricePlans={pricePlans}
          openPopup={handleOpenPopup}
        />
      </StyleWrapper>
    )
  )
}

export default PriceElement
