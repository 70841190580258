import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Image from 'common/components/entities/ImageNew'
import {
  BookingInterface,
  EventInterface,
  EventLocationInterface,
} from '../../calendar-interface'
import {
  getReadableEventLocation,
  EventLocationTypeEnum,
  EventTimeZoneTypeEnum,
  TimeFormatEnum,
} from '../../constants'
import { ClockIcon } from '../../ui/icons/ClockIcon'
import { WorldIcon } from '../../ui/icons/WorldIcon'
import { formatDate, getAllTimeZones } from '../../utils'
import { EventInfoSkeleton } from './event-info-skeleton'
import { EventInfoWrapperUi } from './ui/event-info-wrapper-ui'
import { EventTimeZoneSelectWrapper } from './ui/event-timeZone-select-wrapper'
import { InputRadio } from './ui/input-radio'

interface EventInfoProps extends Partial<EventInterface> {
  selectedTimeZone: string
  onTimeZoneSelect?: (timeZone: string) => void
  selectedLocationId?: EventLocationInterface['id']
  onLocationSelect?: (locationId: EventLocationInterface['id']) => void
  titlesColor?: string
  mobileTitlesColor?: string
  color?: string
  mobileColor?: string
  isLoading?: boolean
  selectedSlotBackgroundColor: string
  booking?: Partial<BookingInterface>
  timeFormat?: TimeFormatEnum
  isMobile?: boolean
}

export const EDITOR_DURATION_PLACEHOLDER = 30

export const EventInfo = ({
  name,
  eventDuration,
  locations,
  selectedTimeZone,
  onTimeZoneSelect,
  selectedLocationId,
  onLocationSelect,
  displayTimezoneType,
  hostName,
  hostAvatarUrl,
  titlesColor,
  mobileTitlesColor,
  color,
  mobileColor,
  selectedSlotBackgroundColor,
  isLoading,
  booking,
  timeFormat,
  isMobile,
}: EventInfoProps) => {
  const timeZonesList = getAllTimeZones(timeFormat)

  const { t } = useTranslation()

  if (isLoading) return <EventInfoSkeleton />

  return (
    <EventInfoWrapperUi
      titlesColor={isMobile ? mobileTitlesColor : titlesColor}
      color={isMobile ? mobileColor : color}
    >
      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <div
            style={{
              borderRadius: '50%',
              overflow: 'hidden',
              height: '60px',
              objectFit: 'cover',
            }}
          >
            <Image
              src={
                hostAvatarUrl ||
                '/assets/images/default_post_author_avatar.webp'
              }
              alt={hostName}
              width={60}
              alignSelf="center"
              attrId={`${hostName}-avatar`}
            />
          </div>
          <span>{hostName}</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 30,
            margin: '40px 0',
          }}
        >
          <div>
            <span color={color}>
              {t('components.core.calendar.event_name')}
            </span>
            <p>{name || t('components.core.calendar.empty_event_name')}</p>
          </div>
          {!!booking && booking.date && (
            <div>
              <span>{t('components.core.calendar.booking_former_time')}</span>
              <BookingDate
                date={new Date(booking.date)}
                eventDuration={eventDuration || EDITOR_DURATION_PLACEHOLDER}
              />
            </div>
          )}
          <div>
            <span color={color}>
              {t('components.core.calendar.event_time')}
            </span>
            <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <ClockIcon /> {eventDuration || EDITOR_DURATION_PLACEHOLDER}{' '}
              {t('components.core.calendar.min')}
            </p>
          </div>
          <div>
            <span color={color}>
              {t('components.core.calendar.event_location.name')}
            </span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {!locations && (
                <p>
                  {t(
                    getReadableEventLocation(
                      EventLocationTypeEnum.OnlineMeeting,
                    ),
                  )}
                </p>
              )}
              {locations?.map(({ locationType, id }) => (
                <label
                  key={locationType}
                  htmlFor={`location-type-${locationType}`}
                  style={{
                    color,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <InputRadio
                    type="radio"
                    id={`location-type-${locationType}`}
                    checked={selectedLocationId === id}
                    disabled={!onLocationSelect}
                    onChange={() => onLocationSelect?.(id)}
                    checkedColor={selectedSlotBackgroundColor}
                  />
                  {t(getReadableEventLocation(locationType))}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span color={color}>{t('components.core.calendar.timezone')}</span>
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <WorldIcon />
          <EventTimeZoneSelectWrapper>
            <Select
              isDisabled={
                !onTimeZoneSelect ||
                displayTimezoneType === EventTimeZoneTypeEnum.Fixed
              }
              options={timeZonesList}
              styles={{
                control: provided => ({
                  ...provided,
                  border: 0,
                  backgroundColor: 'transparent',
                }),
                indicatorSeparator: () => ({}),
              }}
              value={{
                label: selectedTimeZone,
                value: selectedTimeZone,
              }}
              onChange={timeZone => {
                if (timeZone && onTimeZoneSelect)
                  onTimeZoneSelect?.(timeZone.value)
              }}
            />
          </EventTimeZoneSelectWrapper>
        </div>
      </div>
    </EventInfoWrapperUi>
  )
}

function BookingDate({
  date,
  eventDuration,
}: {
  date: Date
  eventDuration: number
}) {
  const { t } = useTranslation()

  const startTime = date.toTimeString().slice(0, 5)
  date.setMinutes(date.getMinutes() + eventDuration)
  const endTime = date.toTimeString().slice(0, 5)

  return (
    <>
      <p>
        {startTime} - {endTime}
      </p>
      <p>{formatDate(date, t)}</p>
    </>
  )
}
