import React from 'react'

export const IconFunnel = () => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.146 9.12734L31.135 9.13965L21.8751 19.0271V26.6137C21.8756 26.9745 21.7869 27.3298 21.6168 27.6481C21.4467 27.9663 21.2006 28.2375 20.9003 28.4375L16.5253 31.3551C16.1956 31.5747 15.8124 31.7006 15.4168 31.7195C15.0211 31.7384 14.6277 31.6494 14.2786 31.4622C13.9295 31.2749 13.6378 30.9964 13.4346 30.6563C13.2315 30.3163 13.1245 29.9274 13.1251 29.5312V19.0271L3.86512 9.13965L3.85419 9.12734C3.56947 8.81401 3.38179 8.42478 3.31393 8.00687C3.24607 7.58897 3.30094 7.16036 3.47188 6.77303C3.64282 6.38569 3.92249 6.05629 4.27696 5.82477C4.63142 5.59326 5.04546 5.46958 5.46884 5.46875H29.5313C29.9551 5.46879 30.3697 5.59189 30.7247 5.8231C31.0798 6.05431 31.3601 6.38366 31.5316 6.77115C31.703 7.15864 31.7582 7.58759 31.6905 8.00586C31.6228 8.42414 31.435 8.81375 31.1501 9.12734H31.146Z"
      fill="#142D63"
    />
  </svg>
)
