import PropTypes from 'prop-types'
import React from 'react'
import CommonPopup from 'common/components/entities/Popup'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'

function Popup({
  entity = {
    options: {
      dialogStyles: {},
      contentStyles: {},
      closedDays: 0,
    },
  },
  overflow = 'visible',
  maxHeight = 'calc(100vh - 40px)',
  closePopup,
}) {
  const { showClose } = entity.options
  return (
    <CommonPopup
      onCloseHandler={closePopup}
      attrId={entity.options.attrId}
      styles={entity.styles}
      mobileStyles={entity.mobileStyles}
      showClose={showClose}
      overflow={overflow}
      maxHeight={maxHeight}
    >
      <BaseNodeChildren childIds={entity.childIds} />
    </CommonPopup>
  )
}

Popup.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      attrId: PropTypes.string.isRequired,
      dialogStyles: PropTypes.objectOf(PropTypes.any),
      contentStyles: PropTypes.objectOf(PropTypes.any),
      showClose: PropTypes.bool,
      closedDays: PropTypes.number,
    }),
    styles: PropTypes.objectOf(PropTypes.any),
    mobileStyles: PropTypes.objectOf(PropTypes.any),
    childIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  closePopup: PropTypes.func.isRequired,
  overflow: PropTypes.string,
  maxHeight: PropTypes.string,
}

export default Popup
