import React from 'react'
import { MonthCaptionProps } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { getReadableMonthName } from '../constants'

export function MonthCaption({ calendarMonth, ...props }: MonthCaptionProps) {
  const { t } = useTranslation()

  return <div {...props}>{t(getReadableMonthName(calendarMonth.date))}</div>
}
