import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonBreadcrumbs from 'common/components/entities/Breadcrumbs'
import {
  Breadcrumb,
  BreadcrumbsInterface,
} from 'common/types/entities/BreadcrumbsInterface'
import useBlog, { selectors } from 'publisher/hooks/useBlog'

function Breadcrumbs({ entity }: { entity: BreadcrumbsInterface }) {
  const [isClient, setIsClient] = useState(false)
  const postCategories = useBlog(selectors.getBlogPostCategories)
  const { t } = useTranslation('common')
  const blogPath: string = useBlog(selectors.getBlogPath)
  const blogPostTitle: string = useBlog(selectors.getBlogPostTitle)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const breadcrumbs: Breadcrumb[] = [
    {
      name: t('components.breadcrumbs.items.home'),
      url: blogPath || '/',
    },
  ]

  if (postCategories.length > 0) {
    if (typeof postCategories[0] === 'object') {
      breadcrumbs.push({
        name: postCategories[0].name,
        url: `${blogPath}/category/${postCategories[0].path}`,
      })
    } else {
      breadcrumbs.push({
        name: postCategories[0],
        url: `${blogPath}/posts?category=${postCategories[0]}`,
      })
    }
  }

  if (blogPostTitle) {
    breadcrumbs.push({
      name: blogPostTitle,
    })
  }

  return (
    isClient && (
      <StyleWrapper
        mobile={entity.appearance.mobile}
        desktop={entity.appearance.desktop}
        styles={entity.margin}
        mobileStyles={entity.mobileMargin}
      >
        <CommonBreadcrumbs
          items={breadcrumbs}
          itemColor={entity.itemColor}
          lastItemColor={entity.lastItemColor}
          fontSize={entity.fontSize}
          fontStyle={entity.fontStyle}
          fontWeight={entity.fontWeight}
          fontFamily={entity.fontFamily}
          mobileFontSize={entity.mobileFontSize}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontWeight={entity.mobileFontWeight}
          mobileFontFamily={entity.mobileFontFamily}
        />
      </StyleWrapper>
    )
  )
}

export default Breadcrumbs
