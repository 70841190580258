import React from 'react'

export const CalendarCheckIcon = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_192011_12320)">
        <path
          d="M0 8.83333V7.79167C0 4.91979 2.33646 2.58333 5.20833 2.58333H6.25V1.54167C6.25 0.966667 6.71562 0.5 7.29167 0.5C7.86771 0.5 8.33333 0.966667 8.33333 1.54167V2.58333H16.6667V1.54167C16.6667 0.966667 17.1323 0.5 17.7083 0.5C18.2844 0.5 18.75 0.966667 18.75 1.54167V2.58333H19.7917C22.6635 2.58333 25 4.91979 25 7.79167V8.83333H0ZM25 10.9167V20.2917C25 23.1635 22.6635 25.5 19.7917 25.5H5.20833C2.33646 25.5 0 23.1635 0 20.2917V10.9167H25ZM18.575 14.2C18.175 13.7865 17.5167 13.774 17.1021 14.1729L12.15 18.9458C11.7583 19.3375 11.101 19.3667 10.651 18.9187L8.27812 16.7135C7.85833 16.3229 7.2 16.3448 6.80625 16.7677C6.41458 17.1885 6.43854 17.8479 6.86042 18.2396L9.20625 20.4187C9.79583 21.0094 10.5812 21.3344 11.4156 21.3344C12.25 21.3344 13.0365 21.0094 13.6115 20.4323L18.549 15.674C18.9625 15.275 18.975 14.6135 18.575 14.2Z"
          fill="#64748B"
        />
      </g>
      <defs>
        <clipPath id="clip0_192011_12320">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
