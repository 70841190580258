import styled, { css } from 'styled-components'
import { backgroundImageFixForIOS } from 'common/utils/styleUtilsNew'

const BodyBackgroundUi = styled.div`
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  ${p => p.theme.phone} {
    ${props =>
      props.$styles &&
      props.$styles.backgroundAttachment === 'fixed' &&
      props.$styles.backgroundImage &&
      css`
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        background-position: center center;
      `}
    ${({ $mobileStyles = {} }) => $mobileStyles}
  }
  ${({ $styles = {} }) => $styles}
  ${p =>
    backgroundImageFixForIOS(
      !!p.$styles.backgroundImage,
      p.$styles.backgroundAttachment ?? '',
    )}
`

export default BodyBackgroundUi
