import styled, { css } from 'styled-components'
import { AlignSelfType } from 'common/types/styleTypes'

type CaptchaProps = {
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
}

const CaptchaUi = styled.div<CaptchaProps>`
  align-self: ${p => p.alignSelf};
  ${p => p.theme.phone} {
    align-self: ${p => p.mobileAlignSelf ?? 'center'};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      align-self: ${p.mobileAlignSelf ?? 'center'};
    `}
`

export default CaptchaUi
