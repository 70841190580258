import styled, { css } from 'styled-components'

interface CommonThankYouStepUiProps {
  titlesColor?: string
  color?: string
}
export const CommonThankYouStepUi = styled.div<CommonThankYouStepUiProps>`
  max-width: 700px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  ${p =>
    p.color &&
    css`
      & p {
        color: ${p.titlesColor};
      }
    `}

  ${p =>
    p.titlesColor &&
    css`
      & span {
        color: ${p.color};
      }
    `}
`
