import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import Field from 'common/components/entities/Field'
import FieldIconUi from 'common/components/entities/Field/FieldIconUi'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import { changeField } from 'publisher/actions/optInActions'
import useOptIn, { selectors as optInSelectors } from 'publisher/hooks/useOptIn'
import fieldValidation from 'publisher/utils/fieldValidation'
import FieldErrors from '../../FieldErrors'
import { useFieldsStorage } from './hooks/use-fields-storage'

function BaseField({
  entity,
  disabled = false,
  readOnly = false,
  dataTestId = '',
  extraErrors = [],
  needInitialise = true,
  type = 'text',
}) {
  const slug = entity.options.slug
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [inlineErrors, setInlineErrors] = useState([])
  const value = useOptIn(optIn => optInSelectors.getFieldValue(optIn, slug))
  const errors = useOptIn(optIn => optInSelectors.getFieldErrors(optIn, slug))
  const handleChange = value => {
    if (slug) {
      dispatch(changeField(slug, value))
    }
  }

  const handleBlur = value => {
    const errors = fieldValidation(slug, value, entity.options.optional)
    setInlineErrors(errors.map(error => t(error)))
    setTimeout(() => {
      window.dispatchEvent(new Event('form_updated'))
    }, 100)
  }

  const calculatedErrors = inlineErrors.length > 0 ? inlineErrors : errors

  const iconColor = entity.styles.color ? entity.styles.color : 'initial'
  const mobileIconColor =
    entity.mobileStyles && entity.mobileStyles.color
      ? entity.mobileStyles.color
      : iconColor

  // need to init all fields
  useEffect(() => {
    if (needInitialise) {
      handleChange(value)
    }
  }, [needInitialise])

  useFieldsStorage(slug)

  const { marginTop, marginLeft, marginBottom, marginRight, ...restStyles } =
    entity.styles
  const {
    marginTop: mobileMarginTop,
    marginRight: mobileMarginRight,
    marginBottom: mobileMarginBottom,
    marginLeft: mobileMarginLeft,
    ...restMobileStyles
  } = entity.mobileStyles

  return (
    <StyleWrapper
      desktop={entity.options.appearance.desktop}
      mobile={entity.options.appearance.mobile}
      styles={{
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
      }}
      mobileStyles={{
        marginTop: mobileMarginTop,
        marginLeft: mobileMarginRight,
        marginBottom: mobileMarginBottom,
        marginRight: mobileMarginLeft,
      }}
    >
      <FieldWrapperUi>
        {entity.options.iconClassName && (
          <FieldIconUi
            color={iconColor}
            mobileColor={mobileIconColor}
            fontSize={entity.styles.fontSize}
            mobileFontSize={entity.mobileStyles && entity.mobileStyles.fontSize}
            className={entity.options.iconClassName}
          />
        )}
        <Field
          attrId={entity.options.attrId}
          type={type}
          name={entity.options.slug}
          hasIcon={!!entity.options.iconClassName}
          dataTestId={dataTestId}
          placeholderColor={entity.options.placeholderColor}
          mobilePlaceholderColor={entity.mobileOptions.placeholderColor}
          placeholder={entity.options.placeholder}
          styles={restStyles}
          mobileStyles={restMobileStyles}
          borderType={entity.options.borderType}
          mobileBorderType={entity.mobileOptions.borderType}
          onChange={e => handleChange(e.target.value)}
          onBlur={e => handleBlur(e.target.value)}
          value={value}
          hasErrors={calculatedErrors.length > 0}
          disabled={disabled}
          readOnly={readOnly}
        />
      </FieldWrapperUi>
      <FieldErrors errors={[...calculatedErrors, ...extraErrors]} />
    </StyleWrapper>
  )
}

BaseField.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      attrId: PropTypes.string,
      slug: PropTypes.string,
      optional: PropTypes.bool,
      placeholder: PropTypes.string,
      placeholderColor: PropTypes.string,
      iconClassName: PropTypes.string,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    mobileOptions: PropTypes.shape({
      placeholder: PropTypes.string,
      placeholderColor: PropTypes.string,
    }),
    styles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    mobileStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    type: PropTypes.string.isRequired,
  }),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  extraErrors: PropTypes.array,
  dataTestId: PropTypes.string,
  needInitialise: PropTypes.bool,
  type: PropTypes.string,
}

export default BaseField
