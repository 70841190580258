import axios from 'axios'
import {
  AvailabilitySlot,
  BookingInterface,
  EventInterface,
} from 'common/components/entities/Calendar/calendar-interface'
import { getQueryString } from 'common/utils/fetchHelper'

export function getEvent(eventId: EventInterface['id']) {
  return axios.get<EventInterface>(`/api/booking-calendar/event/${eventId}`)
}

interface GetBookingParams {
  timezone: string
  token: string
}

export function getBooking({ timezone, token }: GetBookingParams) {
  return axios.get<BookingInterface>(
    `/api/booking-calendar/booking?timezone=${timezone}&token=${token}`,
  )
}

interface RescheduleBookingParams {
  timezone: string
  token: string
  bookingId: BookingInterface['id']
  date: string
}

export const rescheduleBooking = ({
  timezone,
  token,
  bookingId,
  date,
}: RescheduleBookingParams) => {
  return axios.patch(
    `/api/booking-calendar/booking/reschedule?timezone=${timezone}&token=${token}`,
    { id: bookingId, date },
  )
}

export interface GetTimeSlotsArgs {
  ownerId: number
  eventId: EventInterface['id']
  startDate: string //2024-10-20,
  endDate: string // 2024-12-05
  timezone: string
}

export function getTimeSlots(args: GetTimeSlotsArgs) {
  const searchQuery = getQueryString(args)
  return axios.get<AvailabilitySlot[]>(
    `/api/booking-calendar/event/timeSlots?${searchQuery}`,
  )
}
