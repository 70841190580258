import React from 'react'
import { CommonBlogPostLayoutBodyUi } from './common-blog-post-layout-body-ui'
import { CommonBlogPostLayoutBodyWrapperUi } from './common-blog-post-layout-body-wrapper-ui'

interface CommonBlogPostLayoutBodyProps {
  children?: React.ReactNode
}

export function CommonBlogPostLayoutBody({
  children,
}: CommonBlogPostLayoutBodyProps) {
  return (
    <CommonBlogPostLayoutBodyWrapperUi>
      <CommonBlogPostLayoutBodyUi />
      {children}
    </CommonBlogPostLayoutBodyWrapperUi>
  )
}
