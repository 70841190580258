import { AnchorHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import {
  menuItemMixin,
  mobileMenuItemMixin,
} from 'common/components/entities/Menu/components/styles/menuItemMixin'
import { AlignType } from 'common/types/styleTypes'
import {
  fontCss,
  FontProps,
  lineHeightCss,
  mobileFontCss,
  mobileLineHeightCss,
} from 'common/utils/styleUtilsNew'

export type MenuItemUiProps = {
  isActive: boolean
  activeColor?: string
  underlineColor?: string
  lineHeight?: number
  mobileLineHeight?: number
  mobileActiveColor?: string
  mobileUnderlineColor?: string
  isMobile?: boolean
  color?: string
  subMenuColor?: string
  mobileColor?: string
  textAlign?: AlignType
} & FontProps &
  AnchorHTMLAttributes<HTMLAnchorElement>

const MenuItemUi = styled.a<MenuItemUiProps>`
  outline: none;
  display: flex; // fixes button's vertical align
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: ${p => p.textAlign || 'left'};
  ${fontCss}
  ${lineHeightCss}
  ${menuItemMixin}
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      overflow-wrap: anywhere;
      ${mobileFontCss}
      ${mobileLineHeightCss}
    `}
  ${p => p.theme.phone} {
    ${mobileFontCss}
    ${mobileLineHeightCss}
    ${mobileMenuItemMixin}
  }
`

export default MenuItemUi
