import PropTypes from 'prop-types'
import React from 'react'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import MetaTags from '../../components/core/MetaTags'
import BlogOnExitPopup from '../blog/BlogOnExitPopup'
import BlogOnloadPopups from '../blog/BlogOnloadPopups'
import BlogTriggeredPopup from '../blog/BlogTriggeredPopup'
import PageUi from '../ui/PageUi'

type BlogPageProps = {
  children: React.ReactNode
}

function BlogPage({ children }: BlogPageProps) {
  const globalTextFontProperties = usePage(
    pageSelectors.getGlobalFontProperties,
  )
  return (
    <PageUi
      $textFontFamily={globalTextFontProperties.fontFamily}
      $textFontWeight={globalTextFontProperties.fontWeight}
      $textFontStyle={globalTextFontProperties.fontStyle}
    >
      <MetaTags />
      {children}
      <BlogTriggeredPopup />
      <BlogOnExitPopup />
      <BlogOnloadPopups />
    </PageUi>
  )
}

BlogPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogPage
