// for publisher/components/entities/Body.js
import React from 'react'
import { CommonBody } from 'common/components/entities/body'
import { BodyInterface } from 'common/types/entities/body-interface'
import ChildrenEntities from 'publisher/components/ChildrenEntities'
import { useBackgroundImage } from 'publisher/hooks/useBackgroundImage'
import { BackgroundUi } from './ui/background-ui'

interface BodyProps {
  entity: BodyInterface
}

export function Body({ entity }: BodyProps) {
  const {
    childIds,
    background,
    backgroundColor,
    mobileBackgroundColor,
    blur,
    mobileBackground,
  } = entity

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  return (
    <CommonBody
      background={background}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      blur={blur ?? 0}
      zIndex={-1}
      mobileBackground={mobileBackground}
      mobileBackgroundImage={mobileBackgroundImage}
      mobileBackgroundColor={mobileBackgroundColor}
      styles={BackgroundUi}
    >
      <ChildrenEntities childIds={childIds as string[]} />
    </CommonBody>
  )
}
