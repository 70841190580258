import { useRollbar } from '@rollbar/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { redirectionTypes } from 'common/constants/buttonTypes'
import * as exceptions from 'common/errors'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { getLocalTimeZone } from 'common/utils/date-utils'
import {
  pageSelectors,
  typedPageSelectors,
  usePage as useTypedPage,
} from 'publisher/store'
import { getSurveysResults } from 'publisher/store/optIn/optInSelectors'
import { PageState } from 'publisher/store/page/PageStateInterface'
import { blogPageTypes } from '../../common/constants/pageTypes'
import { optInFail, optInSuccess } from '../actions/optInActions'
import { optIn } from '../api/optInApi'
import { Query, QueryKeys } from '../components/core/MatchMedia'
import { validateEmptyOptInFields } from '../utils/fieldValidation'
import { redirectTo } from '../utils/redirect'
import useManagement, { selectors as managementSel } from './useManagement'
import useOptIn, { selectors as optInSelectors } from './useOptIn'
import { useRecaptchaValidation } from './useRecaptchaValidation'

export interface UseOptInSubmit {
  optInButtonEntity: ButtonInterface
  closePopup: () => void
}

const useOptInSubmit = ({ optInButtonEntity, closePopup }: UseOptInSubmit) => {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const fields = useOptIn(optInSelectors.getCustomerOptInFields)
  const surveysResults = useOptIn(getSurveysResults)
  const dateTimeField = useOptIn(optInSelectors.getDateTimeField)
  const popupId = useTypedPage(p =>
    typedPageSelectors.getAscendantPopupId(p, optInButtonEntity),
  )
  const pageType = useTypedPage(typedPageSelectors.getPageType)
  const isTemplate = useTypedPage(typedPageSelectors.getIsPageTemplate)
  const isDesktop = useManagement(managementSel.isDesktop)
  const isPreview = useManagement(managementSel.isPreviewMode)
  const funnelStepId = useManagement(managementSel.getFunnelStepId)
  const confirmedCheckboxesIds = useManagement(
    managementSel.getConfirmedExplicitConsentsIds,
  )
  const visibleOptInFieldProperties = useTypedPage(p =>
    typedPageSelectors.getVisibleOptInFieldProperties(
      p,
      isDesktop,
      fields,
      popupId,
    ),
  )
  const mandatoryCheckboxes = useTypedPage(p =>
    typedPageSelectors.getVisibleMandatoryCheckboxes(p, isDesktop, popupId),
  )

  const visibleOptInFields = useTypedPage((p: PageState) =>
    pageSelectors.getVisibleOptInFields(p, isDesktop, popupId, fields),
  )

  const { validateRecaptchas } = useRecaptchaValidation(optInButtonEntity)

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (isTemplate || isPreview) {
      return
    }

    const checkboxesErrors = mandatoryCheckboxes.reduce((errors, checkbox) => {
      return confirmedCheckboxesIds.includes(checkbox.id)
        ? errors
        : [
            ...errors,
            'options' in checkbox
              ? checkbox.options.errorText
              : checkbox.errorText,
          ]
    }, [] as string[])

    if (checkboxesErrors.length > 0) {
      setErrors(checkboxesErrors)
      return
    }
    const emptyFieldErrors = validateEmptyOptInFields(
      visibleOptInFieldProperties,
      t,
    )
    // we need to leave blog pages because it may have inputs from layout or post layout
    if (
      Object.keys(emptyFieldErrors).length &&
      !blogPageTypes.includes(pageType)
    ) {
      const common: string[] = []
      dispatch(optInFail({ common, fields: emptyFieldErrors }))
      return
    }

    const { recaptchaToken, error } = validateRecaptchas()

    if (error) return

    setLoading(true)
    try {
      const { data } = await optIn({
        fields: visibleOptInFields,
        datetime: dateTimeField,
        timeZone: getLocalTimeZone(),
        isDesktop: window.matchMedia(Query[QueryKeys.DESKTOP]).matches,
        entityId: optInButtonEntity.id,
        checkBoxIds: confirmedCheckboxesIds,
        captcha: recaptchaToken,
        popupId,
        surveysResults,
      })

      if (popupId) {
        closePopup()
      }

      if (
        optInButtonEntity.redirectionType === redirectionTypes.customUrl &&
        optInButtonEntity.urlRedirect
      ) {
        redirectTo(optInButtonEntity.urlRedirect)
      } else if (
        optInButtonEntity.redirectionType === redirectionTypes.nextStep &&
        data.redirect
      ) {
        redirectTo(data.redirect)
      }

      dispatch(optInSuccess())
      window.parent.postMessage(
        {
          type: `funnel_step_${funnelStepId}_form_submit_success`,
        },
        '*',
      )
    } catch (error) {
      if (error instanceof exceptions.BadRequest) {
        setErrors(error.response.data.errors.common)
        dispatch(
          optInFail({
            fields: error.response.data.errors.fields,
          }),
        )
        window.dispatchEvent(new Event('form_updated'))
      } else if (error instanceof exceptions.NetworkError) {
        setErrors([t('core.errors.no_connection')])
      } else if (error instanceof exceptions.NotFound) {
        setErrors([t('page Not Found')])
      } else {
        console.log(`--error--`, error)
        rollbar.error(`Opt-in error`, error as Error)
      }
    }
    setLoading(false)
  }

  return [isLoading, errors, submit]
}

export default useOptInSubmit
