import styled from 'styled-components'

export const BookingSummaryUi = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: 0.5px solid rgba(112, 112, 112, 0.46);
  border-radius: 8px;
  padding: 40px;
`
